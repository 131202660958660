import { watch } from 'vue';
import {
    objectNode,
    type ObjectNode, updateObject,
} from '@/planner/3d/object';
import { ArrowHelper, type ColorRepresentation, type Object3D, type Vector3 } from 'three';
import { stop, stopAll, type StopHandle } from '@/util';
import { vector3, zero3 } from '@/geometry/vector3';
import { watchImmediate } from '@vueuse/core';

export type AxisNode = ObjectNode<'axis'> & {
    position: Vector3;
    direction: Vector3;
    length: number;
    color: ColorRepresentation;
    headLength: number;
    headWidth: number;
};

export function axisNode(properties?: Partial<AxisNode>): AxisNode {
    return {
        ...objectNode('axis', properties),
        position: properties?.position ?? zero3(),
        direction: properties?.direction ?? vector3({ y: 1 }),
        length: properties?.length ?? 10.0,
        color: properties?.color ?? 'white',
        headLength: properties?.headLength ?? 3.0,
        headWidth: properties?.headWidth ?? 2.0,
    };
}

export function updateAxis(node: AxisNode, parent: Object3D): StopHandle {
    let update: StopHandle | null = null;

    return stopAll(
        watchImmediate(
            () => [node.headLength, node.headWidth],
            () => {
                stop(update);
                const arrow = new ArrowHelper(
                    node.direction,
                    node.position,
                    node.length,
                    node.color,
                    node.headLength,
                    node.headWidth,
                );
                parent.add(arrow);
                update = stopAll(
                    updateObject(node, arrow),
                    watch(() => node.position, (position) => arrow.position.copy(position), { deep: true }),
                    watch(() => node.direction, (direction) => arrow.setDirection(direction), { deep: true }),
                    watch(() => node.length, (length) => arrow.setLength(length)),
                    watch(() => node.color, (color) => arrow.setColor(color), { deep: true }),
                    () => {
                        parent.remove(arrow);
                        arrow.dispose();
                    }
                );
            }),
        () => stop(update),
    );
}
