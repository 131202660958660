import { type AxiosResponse, HttpStatusCode } from 'axios';
import { client } from '@/api/http';
import { type ApiLinks } from '@/api/links';
import type { Url } from '@/formus/types';
import { errorDetail } from '@/planner/api/errorDetail';
import {
    type ConvertedRepresentation,
    convertRepresentation,
} from '@/planner/api/convertRepresentation';

/**
 *  New = 'new'
 *  The plan is brand new in the database. This state should be considered a temporary state
 *  until the business logic has started to generate the plan assets.
 *
 *  Processing = 'processing'
 *  The plan business logic ('on_create()' logic) is running. This will take minutes to run
 *  and will perform various tasks based on the type of project including:
 *   - creating resected models
 *   - creating a PDF report
 *   - creating aligned models ready for download
 *
 *  Completed = 'completed'
 *  The plan has been successfully generated.
 *
 *  Error = 'error'
 *  There was a technical or clinical issue that prevented a plan from being created and/or
 *  published. The reason for the error is explicitly **not** reported here. This mechanism
 *  doesn't look to address the problem of capturing one or more errors and/or warnings about
 *  the plan creation process.
 */
export type PlanState = 'new' | 'uploading' | 'completed' | 'error';

export type PlanStateUri = `https://schema.formuslabs.com/state/plan/${PlanState}`;

export type PlanProperties = {
    name: string;

    /**
     * When the study was created.
     *
     * This is an ISO-9660 datetime string with better than second precision.
     */
    created: string;

    /**
     * When the study was last modified.
     *
     * This is an ISO-9660 datetime string with better than second precision.
     */
    updated: string;

    /**
     * A URI representing the state of the study.
     */
    state: PlanStateUri;
};

const planLinkMap = {
    self: 'self',
    template: 'up',
} as const;

/** Identifies a study on the API */
export type ApiPlanId = { case: number; plan: number };

/**
 * A study is a resource that is created in the context of a case. It is the primary resource
 * that represents a collection of DICOM files.
 */
export type ApiPlan = ConvertedRepresentation<PlanProperties, typeof planLinkMap> & {
    id: ApiPlanId;
};

export async function getPlan(planId: ApiPlanId | Url): Promise<ApiPlan> {
    let id, url;
    if (typeof planId === 'object') {
        id = planId;
        url = `project/${id.case}/plan/${id.plan}`;
    } else {
        url = planId;
        id = planIdFromUrl(url);
    }

    const { status, data }: AxiosResponse<PlanProperties & ApiLinks> = await client.get(url);
    if (status === HttpStatusCode.Ok) {
        return {
            id,
            ...convertRepresentation(data, planLinkMap),
        };
    }

    throw Error(`Failed to load plan from ${url}` + errorDetail(data));
}

export function planIdFromUrl(url: Url): ApiPlanId {
    const match = url.match(/project\/([0-9]*)\/plan\/([0-9]*)/);
    if (match) {
        return {
            case: Number(match[1]),
            plan: Number(match[2]),
        };
    }
    throw Error(`Failed to extract plan-id from url '${url}'`);
}
