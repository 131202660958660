import { Vector3 } from 'three';
import type { FemoralFeatures, NativeMeasurements, PlannerState } from '@/planner/plannerState';
import { type ApiCase, getCase } from '@/planner/api/case';
import { type ApiStudy, getStudy, studyIdFromUrl } from '@/planner/api/study';
import { assert, assertDefined, taggedLogger } from '@/util';
import {
    type ApiBoneId,
    type FindModelOptions,
    findStudyModel,
    getStudyModels,
} from '@/planner/api/studyModels';
import { type ApiStudyLandmarks, getLandmarks, landmarksIdFromUrl } from '@/api/study/studyLandmarks';
import { findLandmark } from '@/formus/anatomy/landmarks';
import { areCollinear, orthogonalUnit, vector3 } from '@/geometry/vector3';
import type { BaseMeshNode } from '@/planner/3d/baseMesh';
import {
    type ApiStudyMeasurements,
    getStudyMeasurements,
    studyMeasurementsIdFromUrl,
} from '@/api/study/studyMeasurements';
import {
    type AxisMeasurement, findAngleMeasurement, findAxisMeasurement, findDistanceMeasurement,
} from '@/formus/anatomy/measurements';
import {
    type ApiCompleteSpecification,
    getCompleteSpecification,
    specificationIdFromUrl,
} from '@/planner/api/specification';
import { getComponentCatalog } from '@/api/catalog/getComponentCatalog';
import { formatBasis, formatDegrees, formatFloat } from '@/geometry/formatMath';
import { getFittedComponents } from '@/api/fittedComponents/getFittedComponents';
import { pollTemplate } from '@/api/template/pollTemplate';
import { nativeAcetabularAngles } from '@/formus/anatomy/pelvis/nativeAcetabularAngles';
import { anatomicAngles } from '@/formus/anatomy/pelvis/acetabularAngles';
import { toRadians } from '@/formus/anatomy/pelvis/anteversionInclination';
import type { BodySide } from '@/formus/anatomy/side';
import type { Template } from '@/formus/template/template';
import LPS, { type LpsVectors } from '@/formus/anatomy/LPS';
import { createManualTemplate } from '@/planner/createManualTemplate';
import { templateId } from '@/api/template/templateUrl';
import { type ApiPlan, getPlan, planIdFromUrl } from '@/planner/api/plan';
import { logValidation } from '@/planner/logValidation';
import { indent } from '@/util/indent';
import { makeSpinoPelvicLandmarks } from '@/components/spinopelvic/calculations';
import { useSpinopelvic } from '@/stores/spinopelvic/store';
import { computeBearingUrl } from './componentUrls';

const log = taggedLogger('case-load');

export async function loadCase(state: PlannerState, caseId: number): Promise<void> {
    const { case_, landmarks, measurements, specification, study, template, plan } =
        await loadCaseData(caseId);

    // TODO: This is a hack to get the spinopelvic measurements.
    await useSpinopelvic().load(caseId);

    state.case = case_.self;
    state.caseVersion = case_.version;
    state.operationalSide = case_.side;
    state.automatedTemplateUrl = case_.automatedTemplate;
    state.manualTemplateUrl = case_.manualTemplate;
    state.manualTemplateCanonicalUrl = template.canonical;
    state.manualTemplateLastApprovedUrl = plan?.template ?? null;

    state.sceneCentre = calculateSceneCentre(landmarks);
    state.nativeMeasurements = makeNativeMeasurements(case_.side, specification, measurements);
    state.femoralFeatures = findFemoralFeatures(case_.side, measurements);
    state.hipSpineLandmarks = makeSpinoPelvicLandmarks(landmarks);
    state.alignmentMode = specification.alignmentMode;
    state.preferredSystem = specification.preferredSystem;

    log.info('Assigning anatomical model sources');
    await assignBoneSources(state, study);

    log.info('Loading components catalog');
    state.catalog = await getComponentCatalog();

    await initStateFromTemplate(state, template);

    log.info('Finished loading case');
}

/** Reload the manual-template and initialize the planner-state from it */
export async function reloadTemplate(state: PlannerState): Promise<void> {
    assert(state.manualTemplateUrl, 'Cannot reload template: no manual template url');

    log.info('Reloading surgical-template %d', templateId(state.manualTemplateUrl).template);
    const template = await pollTemplate(
        state.manualTemplateUrl,
        (template) => !!template.components,
    );
    await initStateFromTemplate(state, template);
}

/** Initialize the planner-state from a template */
async function initStateFromTemplate(state: PlannerState, template: Template): Promise<void> {
    log.info('Loading fitted components');
    assert(template.components !== null);
    state.fittedComponents = await getFittedComponents(template.components);

    log.info('Assigning template');
    state.targetAdjustments = {
        legLength: template.targetLegLengthChange ?? 0,
        offset: template.targetOffsetChange ?? 0,
    };
    state.template = {
        cupUrl: template.cup,
        linerUrl: template.liner,
        bearingUrl: null,
        stemUrl: template.stem,
        headUrl: template.head,
        cupRotation: anatomicAngles(toRadians(template.cupRotation)),
        cupOffset: template.cupOffset,
        dualMobility: template.dualMobility,
        stemTransform: template.stemTransform,
    };
    state.template.bearingUrl = template.dualMobility ? computeBearingUrl(state) : null;
}

/** Api-data that we load up-front */
type ApiData = {
    case_: ApiCase;
    specification: ApiCompleteSpecification;
    template: Template;
    plan: ApiPlan | null;
    study: ApiStudy;
    measurements: ApiStudyMeasurements;
    landmarks: ApiStudyLandmarks;
};

/** Up-front load of data */
async function loadCaseData(caseId: number): Promise<ApiData> {
    log.info('Loading case %d', caseId);
    let case_ = await getCase(caseId);

    if (!case_.manualTemplate) {
        case_ = await createManualTemplate(case_);
    }

    log.info(
        'Loading surgical-specification %d',
        specificationIdFromUrl(case_.specification).specification,
    );
    const specification = await getCompleteSpecification(case_.specification);

    log.info('Loading surgical-template %d', templateId(case_.manualTemplate).template);

    // Fetch the surgical-template until the components-url is available
    const template = await pollTemplate(case_.manualTemplate, (template) => !!template.components);

    let plan = null;
    if (template.plan) {
        log.info('Loading study %d', planIdFromUrl(template.plan).plan);
        plan = await getPlan(template.plan);
    }

    log.info('Loading study %d', studyIdFromUrl(template.study).study);
    const study = await getStudy(template.study);

    log.info(
        'Loading study-measurements %d',
        studyMeasurementsIdFromUrl(study.measurements).measurements,
    );
    const measurements = await getStudyMeasurements(study.measurements);

    log.info('Loading landmarks %d', landmarksIdFromUrl(study.landmarks).landmarks);
    const landmarks = await getLandmarks(study.landmarks);

    return {
        case_,
        specification,
        study,
        measurements,
        landmarks,
        template,
        plan,
    };
}

async function assignBoneSources(state: PlannerState, study: ApiStudy): Promise<void> {
    const boneModels = await getStudyModels(study.models);

    const assignBoneSource = (
        node: BaseMeshNode,
        boneId: ApiBoneId,
        options?: FindModelOptions,
    ) => {
        const source = findStudyModel(boneModels, boneId, options);
        node.geometrySource = source !== null ? source.id : null;
    };

    assignBoneSource(state.scene.operativeFemur, 'operative-femur');
    assignBoneSource(state.scene.operativeFemurInternal, 'operative-femur-internal');
    assignBoneSource(state.scene.operativeHemipelvis, 'operative-hemipelvis');
    assignBoneSource(state.scene.sacrum, 'sacrum');
    assignBoneSource(state.scene.contralateralHemipelvis, 'contralateral-hemipelvis');
    assignBoneSource(state.scene.contralateralFemur, 'contralateral-femur');
    assignBoneSource(state.scene.metal, 'metal', { optional: true });
}

/**
 * Calculate the centre-point for a hip case. This is a point in the centre of the pelvis between
 * the left and right asis.
 *
 * NOTE: also known as the scene 'origin'
 */
function calculateSceneCentre({ groups }: ApiStudyLandmarks): Vector3 {
    const leftItem = findLandmark(groups, 'pelvis', 'lasis');
    const rightItem = findLandmark(groups, 'pelvis', 'rasis');
    return vector3(leftItem.coordinates).add(rightItem.coordinates).divideScalar(2);
}

/**
 * Create an LPS basis aligned to a femoral coordinate system:
 * - superior direction along the **proximal** shaft-axis
 * - left/right medial direction in the same plane as the neck-axis but orthogonal to the superior direction
 * - posterior direction orthogonal to the superior and medial directions
 *
 * This basis is used to position the stem.
 */
function calculateStemTranslationBasis(
    side: BodySide,
    proximalShaftAxis: AxisMeasurement,
    neckAxis: AxisMeasurement,
): LpsVectors {
    const superior = vector3(proximalShaftAxis.value).normalize();
    const medial = vector3(neckAxis.value).projectOnPlane(superior).normalize();
    const left = side === 'right' ? medial : medial.clone().negate();
    const posterior = orthogonalUnit(superior, left);
    const result = { left, posterior, superior };

    logValidation(
        'Stem-translation-basis:\n' +
        indent(2, formatBasis(result, { precision: 5 })),
    );

    return { left, posterior, superior };
}

/**
 * Create an LPS basis aligned to a femoral coordinate system:
 * - superior direction corresponding to the femoral-shaft-axis.
 * - posterior direction orthogonal to the superior direction in the CT saggital plane
 *
 * This is intended to be functionally equivalent to the shaft-plane used to calculate
 * stem-anteversion on the server-side.
 */
function calculateFemoralShaftBasis(shaftAxis: AxisMeasurement): LpsVectors {
    const superior = vector3(shaftAxis.value).normalize();

    let approxPosterior: Vector3;
    if (areCollinear(superior, LPS.Posterior)) {
        log.warn('Femoral shaft axis is exactly in posterior direction');
        approxPosterior = LPS.Inferior;
    } else {
        approxPosterior = LPS.Posterior;
    }

    const left = orthogonalUnit(approxPosterior, superior);
    const posterior = orthogonalUnit(superior, left);
    const result = { left, posterior, superior };

    logValidation(
        'Femoral-shaft-basis:\n' +
        indent(2, formatBasis(result, { precision: 5 })),
    );

    return { left, posterior, superior };
}

/**
 * Extract femoral features from a set of study-measurements
 *
 * Returns null if the necessary measurements cannot be found, which may be the result of an old cases.
 */
function findFemoralFeatures(side: BodySide, { groups }: ApiStudyMeasurements): FemoralFeatures | null {
    try {
        const shaftAxis = findAxisMeasurement(groups, { bone: 'femur', side }, 'hip_femur_shaft_axis');
        const proximalShaftAxis = findAxisMeasurement(groups, { bone: 'femur', side }, 'hip_femur_proximal_shaft_axis');
        const neckAxis = findAxisMeasurement(groups, { bone: 'femur', side }, 'hip_femur_neck_axis');
        const anteversion = findAngleMeasurement(groups, { bone: 'femur', side }, 'hip_femur_anteversion_angle');

        const [anteversionCondylarAxis, anteversionNeckAxis] = assertDefined(anteversion.axes, 'anteversion-axes');

        return {
            shaftAxis,
            proximalShaftAxis,
            neckAxis,
            anteversionCondylar: assertDefined(anteversionCondylarAxis.value, 'anteversion-condylar-axis'),
            anteversionNeck: assertDefined(anteversionNeckAxis.value, 'anteversion-neck-axis'),
            stemTranslationBasis: calculateStemTranslationBasis(side, proximalShaftAxis, neckAxis),
            shaftBasis: calculateFemoralShaftBasis(shaftAxis),
        };
    } catch (error) {
        if (error instanceof Error) {
            log.warning(
                'Error getting femoral features needed for femoral alignment ' +
                '(this may be an old case missing this data):\n%s',
                error.message,
            );
            return null;
        } else {
            throw error;
        }
    }
}

function makeNativeMeasurements(
    side: BodySide,
    specification: ApiCompleteSpecification,
    { groups }: ApiStudyMeasurements,
): NativeMeasurements {
    const { anteversion, inclination } = nativeAcetabularAngles(
        specification.alignmentMode,
        side,
        groups,
    );
    const result = {
        hipLengthDifference: findDistanceMeasurement(
            groups,
            'pelvis',
            'hip_pelvis_leg_length_difference',
        ).value,
        femoralAnteversion: findAngleMeasurement(
            groups,
            { bone: 'femur', side },
            'hip_femur_anteversion_angle',
        ).value,
        acetabularAnteversion: anteversion,
        acetabularInclination: inclination,
    };

    logValidation(
        [
            'Native measurements:',
            `  hip-length-difference: ${formatFloat(result.hipLengthDifference)} mm`,
            `  femoral-anteversion: ${formatDegrees(result.femoralAnteversion)}`,
            `  acetabular-anteversion: ${formatDegrees(result.acetabularAnteversion)}`,
            `  acetabular-inclination: ${formatDegrees(result.acetabularInclination)}`,
        ].join('\n'),
    );

    return result;
}
