/**
 * Utilities to assist writing code that manipulate a mesh represented in a {@link BufferGeometry}.
 *
 * Every BufferGeometry implicitly defines a set of N vertices indexed with a *vertexId* ranging from 0 to N-1.
 * - Essentially a vertex *is* its vertexId: it would be it's primary key in a database.
 * - Data associated with the vertex (e.g. position, color or normal) are given in separate *attributes*
 *      (see {@link BufferAttribute}). For instance, the position of a vertex will be found in the 'position'
 *      attribute, which effectively contains an array of N Vector3s with the nth Vector3 giving the position of
 *      vertex n.
 * - We expect our geometry to always have a Vector3 position attribute but may also have more attributes e.g. color,
 *      normal etc.
 *
 * Every BufferGeometry also implicity defines a set of M faces, indexed with a *faceId* ranging from 0.. M-1. We assume
 * faces are always triangular and so always have exactly 3 vertices. As with vertices, a face essentially *is* this
 * faceId. There are two variants of the way a BufferGeometry defines faces: either *indexed* or *non-indexed*.
 * - Indexed geometry has an 'index' property, which for M faces holds an array of 3*M vertexIds. Each sequence of 3
 *      vertexIds define a triangular face, so an index of [0, 2, 3, 2, 3, 5] defines 2 faces with faceIds 0 and 1
 *      between vertices with vertexIds 0, 2, 3 and 2, 3, 5 respectively.
 * - Non-indexed geometry will not have a 'index' property. Each sequence of 3 consecutive vertexIds implicitly defines
 *      a face. This means the geometry has M = N/3 faces, where N is the number of vertices, and in this
 *      case we would expect the number of vertices N to be a multiple of 3.
 */

import {
    BufferAttribute,
    BufferGeometry,
    InterleavedBufferAttribute,
    type Matrix4,
    Sphere,
    Triangle,
    Uint16BufferAttribute,
    Vector3,
} from 'three';
import type { NumberArray3 } from '@/geometry/apiVector';
import { assert, isFiniteNumber, logger } from '@/util';
import type { Face3Positions } from '@/geometry/face';
import type { Raw } from 'vue';

const log = logger('geometry');

/**
 * The set of 3 vertexIds of the vertices in a face.
 */
export type Face3Indices = NumberArray3;

/**
 * Predicate that checks whether a face should be part of a subset-geometry
 */
export type FacePredicate = (face: Face3Positions) => boolean;

/**
 * The number of vertices in a triangular face, defined for clarity.
 */
export const VERTICES_PER_TRIANGLE = 3 as const;

/**
 * Get the {@link BufferAttribute} that contains the position of a vertex.
 * We assume we always have this attribute and so can use it to calculate other properties like vertexCount.
 */
export function vertexPositionBuffer(
    geometry: BufferGeometry,
): BufferAttribute | InterleavedBufferAttribute {
    return geometry.getAttribute('position');
}

/**
 * Get the number of vertices in the given {@link BufferGeometry}.
 */
export function vertexCount(geometry: BufferGeometry): number {
    return vertexPositionBuffer(geometry).count;
}

/**
 * Get the position of the vertex with a particular vertexId.
 */
export function vertexPosition(geometry: BufferGeometry, vertexId: number): Vector3 {
    const vertexPositions = vertexPositionBuffer(geometry);
    return new Vector3().fromBufferAttribute(vertexPositions, vertexId);
}

/**
 * Get the position of all the vertices in the given {@link BufferGeometry}.
 * The resulting array can be indexed by vertexId to get the position for a vertex.
 */
export function vertexPositions(geometry: BufferGeometry): Vector3[] {
    const vertexPositions = vertexPositionBuffer(geometry);
    const result = new Array<Vector3>(vertexPositions.count);
    for (let vertexId = 0; vertexId < vertexPositions.count; ++vertexId) {
        result[vertexId] = new Vector3().fromBufferAttribute(vertexPositions, vertexId);
    }
    return result;
}

/**
 * Get the number of faces in the given {@link BufferGeometry}.
 */
export function faceCount(geometry: BufferGeometry): number {
    const index = geometry.getIndex();
    if (index !== null) {
        return index.count / VERTICES_PER_TRIANGLE;
    } else {
        return vertexCount(geometry) / VERTICES_PER_TRIANGLE;
    }
}

/**
 * Get the set of 3 vertexIds in the face with the given faceId.
 */
export function singleFaceIndices(geometry: BufferGeometry, faceId: number): Face3Indices {
    const index = geometry.getIndex();
    const faceOffset = faceId * VERTICES_PER_TRIANGLE;
    if (index === null) {
        return [faceOffset, faceOffset + 1, faceOffset + 2];
    } else {
        return [index.array[faceOffset], index.array[faceOffset + 1], index.array[faceOffset + 2]];
    }
}

/**
 * Create a new {@link BufferGeometry} that has only a subset of the faces of another geometry.
 * One way to create the new geometry is to share position attribute-buffers between the original and the new
 * geometries, and just create a new index for the new one. This is the most efficient method but creates a somewhat
 * hidden coupling between the geometry objects and so is not the default.
 * @param geometry The original geometry, from which to select a subset of faces.
 * @param includeFace A predicate to identify which faces to keep, based on the positions of the vertices of the
 *  face.
 * @param clonePositionBuffer Whether to create a new position buffer for the new geometry. If this is false the
 *  new geometry will share its position buffer with the original geometry.
 */
export function extractFaces(
    geometry: BufferGeometry,
    includeFace: FacePredicate,
    clonePositionBuffer = true,
): BufferGeometry {
    if (geometry.index === null) {
        // TODO extractFaces for non-indexed geometry
        throw Error();
    } else {
        const sourcePositions = vertexPositions(geometry);
        const sourceFaces = faceIndices(geometry);
        const resultFaces: number[] = [];

        for (const [v0, v1, v2] of sourceFaces) {
            if (includeFace([sourcePositions[v0], sourcePositions[v1], sourcePositions[v2]])) {
                resultFaces.push(v0, v1, v2);
            }
        }

        const result = new BufferGeometry();
        result.setIndex(new Uint16BufferAttribute(resultFaces, 1));

        const sourcePositionBuffer = geometry.getAttribute('position');
        result.setAttribute(
            'position',
            clonePositionBuffer ? sourcePositionBuffer.clone() : sourcePositionBuffer,
        );

        return result;
    }
}

/** Calculate the area of a triangle. */
export function areaOfTriangle(pointA: Vector3, pointB: Vector3, pointC: Vector3): number {
    return new Triangle(pointA, pointB, pointC).getArea();
}

/** Calculates the surface area of a geometry with triangular faces. */
export function calculateArea(geometry: BufferGeometry): number {
    const start = new Date().getTime();
    let totalArea = 0;
    const positions = vertexPositions(geometry);
    for (const [v0, v1, v2] of faceIndices(geometry)) {
        const area = areaOfTriangle(positions[v0], positions[v1], positions[v2]);
        if (isFiniteNumber(area)) {
            totalArea += area;
        } else {
            log.debug(
                'Area: %d, with v1: %o, v2: %o, v3: %o',
                area,
                positions[v0],
                positions[v1],
                positions[v2],
            );
        }
    }

    log.debug(
        'Area calculation took: %s seconds. Total area: %d',
        (new Date().getTime() - start) / 1000,
        totalArea,
    );
    return totalArea;
}

/**
 * Get the bounding sphere of a geometry
 */
export function boundingSphere(geometry: Raw<BufferGeometry>): Sphere {
    // Compute the bounding sphere of the geometry, since the Geometry.boundingSphere
    // attribute is null by default.
    //
    // see https://threejs.org/docs/index.html#api/en/core/Geometry.boundingSphere
    //
    geometry.computeBoundingSphere();
    const result = geometry.boundingSphere;
    assert(result);
    return result;
}

/**
 * Get the position of the 3 vertices in the face with a given faceId.
 */
export function getFaceVertexPositionsById(
    geometry: BufferGeometry,
    faceId: number,
): Face3Positions {
    const vertexPositions = vertexPositionBuffer(geometry);
    const faceIndices = singleFaceIndices(geometry, faceId);
    return getFaceVertexFromBufferAttribute(vertexPositions, faceIndices);
}

/**
 * Finds the positions of the three vertices on a face {@link Face3Positions} by finding the 3
 * indices in a buffer attribute.
 *
 * Notes: Assumes the buffer attribute count is 3.
 *
 * @param bufferAttribute A buffer attribute. (Currently used for 'position')
 * @param faceIndices The face indices.
 *
 * @returns {@link Face3Positions} by finding the 3 face indices in a buffer attribute.
 */
export function getFaceVertexFromBufferAttribute(
    bufferAttribute: BufferAttribute | InterleavedBufferAttribute,
    faceIndices: Face3Indices,
): Face3Positions {
    const [v0, v1, v2] = faceIndices;
    const vertex0 = new Vector3().fromBufferAttribute(bufferAttribute, v0);
    const vertex1 = new Vector3().fromBufferAttribute(bufferAttribute, v1);
    const vertex2 = new Vector3().fromBufferAttribute(bufferAttribute, v2);

    if (vertex0) {
        if (vertex1) {
            if (vertex2) {
                return [vertex0, vertex1, vertex2];
            }
            throw new Error('No vertex 2');
        }
        throw new Error('No vertex 1');
    }
    throw new Error('No vertex 0');
}

/**
 * Get the vertex-indices in a face for all the vertices in a {@link BufferGeometry}.
 * The resulting array can be indexed by faceId to get the vertices in a face.
 */
export function faceIndices(geometry: BufferGeometry): Face3Indices[] {
    const index = geometry.getIndex();
    if (index === null) {
        const vertexPositions = vertexPositionBuffer(geometry);
        const faceCount = vertexPositions.count / VERTICES_PER_TRIANGLE;
        const result = new Array<Face3Indices>(faceCount);
        let faceOffset = 0;
        for (let faceId = 0; faceId < faceCount; ++faceId) {
            result[faceId] = result[faceId] = [faceOffset, faceOffset + 1, faceOffset + 2];
            faceOffset += VERTICES_PER_TRIANGLE;
        }
        return result;
    } else {
        const faceCount = index.count / VERTICES_PER_TRIANGLE;
        const result = new Array<Face3Indices>(faceCount);
        const array = index.array;
        for (let faceId = 0; faceId < faceCount; ++faceId) {
            const faceOffset = 3 * faceId;
            result[faceId] = [array[faceOffset], array[faceOffset + 1], array[faceOffset + 2]];
        }
        return result;
    }
}

export function applyMatrix4ToGeometry(geometry: BufferGeometry, matrix: Matrix4): BufferGeometry {
    return geometry.clone().applyMatrix4(matrix);
}
