import { stopAll, type StopHandle } from '@/util';
import type { PlannerScene } from '@/planner/scene/plannerScene';
import { updateMesh } from '@/planner/scene/mesh';
import {
    type LoadGeometry,
    loadGeometryParallel,
    makeLoadGeometryInOrder,
} from '@/planner/3d/geometryLoading';
import { updateGroup } from '@/planner/3d/group';
import { updateDoubleSidedMesh } from '@/planner/scene/doubleSidedMesh';
import { updateAxis } from '@/planner/3d/axis';
import { updateCatStackImage } from '@/planner/scene/catStackImage';
import { updateAxes } from '@/planner/3d/axes';
import { updateGrid } from '@/planner/3d/grid';
import type { PlannerSceneState } from '@/planner/scene/sceneState';
import { watchImmediate } from '@vueuse/core';
import LPS from '@/formus/anatomy/LPS';
import { useDeveloperSettings } from '@/planner/developerSettings';
import type { PlannerStore } from '@/planner/plannerStore';
import { setCameraPlacement } from '@/planner/scene/cameraPlacement';

/**
 * Update the planner-scene from the planner-state
 */
export function updatePlannerScene(store: PlannerStore, scene: PlannerScene): StopHandle {
    const loadGeometry = makeLoadGeometry();
    const settings = useDeveloperSettings();

    return stopAll(
        updateScene(store.scene, scene),
        watchImmediate(
            () => store.plannerMode,
            () => setCameraPlacement(store, store.plannerMode),
        ),
        watchImmediate(
            () => store.sceneCentre,
            (centre) => {
                store.scene.sceneCentre.transform.setPosition(centre);
                store.scene.sceneGrid.transform.setPosition(centre);
                store.scene.camera.target.copy(centre);
                store.scene.camera.position = centre.clone().add(LPS.Anterior.multiplyScalar(450));
            },
        ),
        watchImmediate(
            () => settings.animateCameras && !store.isLoading,
            (value) => (store.scene.camera.animate = value ?? false),
        ),
        watchImmediate(
            () => settings.show3dFeatures,
            (show) => {
                [store.scene.sceneCentre, store.scene.sceneGrid, store.scene.ctOrigin].forEach(
                    (node) => (node.visible = !!show),
                );
            },
        ),
        updateDoubleSidedMesh(
            store,
            store.scene.operativeFemur,
            scene.operativeFemur,
            loadGeometry,
        ),
        updateDoubleSidedMesh(
            store,
            store.scene.operativeFemurInternal,
            scene.operativeFemurInternal,
            loadGeometry,
        ),
        updateMesh(store, store.scene.contralateralFemur, scene.contralateralFemur, loadGeometry),
        updateMesh(store, store.scene.operativeHemipelvis, scene.operativeHemipelvis, loadGeometry),
        updateMesh(
            store,
            store.scene.contralateralHemipelvis,
            scene.contralateralHemipelvis,
            loadGeometry,
        ),
        updateMesh(store, store.scene.sacrum, scene.sacrum, loadGeometry),
        updateMesh(store, store.scene.metal, scene.metal, loadGeometry),
        updateMesh(store, store.scene.cup, scene.cup, loadGeometry),
        updateMesh(store, store.scene.cupCoverage, scene.cupCoverage, loadGeometry),
        updateMesh(store, store.scene.cupCollisionSurface, scene.cupCollisionSurface, loadGeometry),
        updateMesh(store, store.scene.liner, scene.liner, loadGeometry),
        updateMesh(store, store.scene.bearing, scene.bearing, loadGeometry),
        updateMesh(store, store.scene.stem, scene.stem, loadGeometry),
        updateMesh(store, store.scene.head, scene.head, loadGeometry),
        updateCatStackImage(store, store.scene.axialCatStack, scene.axialCatStack),
        updateCatStackImage(store, store.scene.coronalCatStack, scene.coronalCatStack),
    );
}

/**
 * Update the planner-scene from the scene-state
 *
 * The intent of this function is to be a simple mapping from the scene-node-graph (state.scene) to the
 * 3d object graph. When the parent-child relations can be given in the node graph we should not need this
 * mapping any more and can use a function.
 *
 * IMPORTANT: Do not functionality that depends on the full planner-state! Instead, add nodes
 * that provide the state of the 3D-objects in the scene (3JS objects) and update functions for those objects.
 */
function updateScene(state: PlannerSceneState, scene: PlannerScene): StopHandle {
    return stopAll(
        updateAxes(state.ctOrigin, scene.ctOrigin),
        updateAxes(state.sceneCentre, scene.sceneCentre),
        updateGrid(state.sceneGrid, scene.sceneGrid),
        updateGroup(state.femoralGroup, scene.femoralGroup),
        updateAxis(state.femoralShaftAxis, scene.femoralShaftAxis),
        updateAxis(state.femoralProximalShaftAxis, scene.femoralProximalShaftAxis),
        updateAxis(state.femoralNeckAxis, scene.femoralNeckAxis),
        updateAxis(state.femoralAnteversionNeckAxis, scene.femoralAnteversionNeckAxis),
        updateAxis(state.femoralAnteversionCondylarAxis, scene.femoralAnteversionNeckAxis),
        updateGroup(state.cupGroup, scene.cupGroup),
        updateGroup(state.stemGroup, scene.stemGroup),
        updateAxis(state.stemNeckAxis, scene.stemGroup),
        updateAxis(state.stemShaftAxis, scene.stemGroup),
        updateAxis(state.stemPaAxis, scene.stemGroup),
    );
}

/**
 * We should be able to load the scene-geometry in parallel, but currently it seems
 * that when we do the data comes back out of order
 * */
const LOAD_GEOMETRY_IN_PARALLEL = false as const;

function makeLoadGeometry(): LoadGeometry {
    return LOAD_GEOMETRY_IN_PARALLEL ? loadGeometryParallel : makeLoadGeometryInOrder();
}
