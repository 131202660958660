import type { PlannerMode } from '@/planner/plannerState';

/** Identifies the buttons at the top of the planner-view that toggle the visibility of
 * objects in the 3d scene */
export type VisibilityToggleId =
    | 'femur'
    | 'pelvis'
    | 'implant'
    | 'xray'
    | 'neck-cross-section'
    | 'stem-cross-section'
    | 'cup-cross-section';

/** The state of the visibility toggle buttons */
export type VisibilityToggleState = 'on' | 'off' | 'hidden';

/**
 * A set of states, one each for the buttons at the top of the planner-view that toggle the
 * visibility of objects in the 3d scene
 */
export type VisibilityToggles = {
    [Id in VisibilityToggleId]: VisibilityToggleState;
};

/**
 * Create the initial state of the visibility toggles for the given planner-mode
 */
export function initialVisibilityToggles(mode: PlannerMode): VisibilityToggles {
    return {
        ...DEFAULT_HIDDEN,
        ...INITIAL_VISIBILITY_TOGGLES[mode],
    };
}

const DEFAULT_HIDDEN: VisibilityToggles = {
    femur: 'hidden',
    pelvis: 'hidden',
    implant: 'hidden',
    xray: 'hidden',
    'neck-cross-section': 'hidden',
    'cup-cross-section': 'hidden',
    'stem-cross-section': 'hidden',
} as const;

const INITIAL_VISIBILITY_TOGGLES: {
    [Mode in PlannerMode]: Partial<VisibilityToggles>;
} = {
    default: {
        femur: 'on',
        pelvis: 'on',
        implant: 'on',
        xray: 'off',
    },
    disabled: {
        femur: 'on',
        pelvis: 'on',
        implant: 'on',
        xray: 'off',
    },
    stem: {
        femur: 'on',
        pelvis: 'off',
        implant: 'on',
        xray: 'off',
        'neck-cross-section': 'off',
        'stem-cross-section': 'off',
    },
    cup: {
        femur: 'off',
        pelvis: 'on',
        implant: 'on',
        xray: 'on',
        'cup-cross-section': 'off',
    },
    combined: {
        femur: 'on',
        pelvis: 'on',
        implant: 'on',
        xray: 'off',
    },
} as const;
