import type { CatalogComponentProperties } from '@/formus/catalog/common';
import { assert } from '@/util';

export const stemSystems = ['avenir-complete', 'taperloc-complete'] as const;
export type StemSystem = (typeof stemSystems)[number];

export function isStemSystem(value: string): value is StemSystem {
    return stemSystems.includes(value as StemSystem);
}

const taperlocStemSizes = [
    4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 22, 24,
] as const;
export type TaperlocStemSize = (typeof taperlocStemSizes)[number];

const avenirStemSizes = [0, 1, 2, 3, 4, 5, 6, 6.5, 7, 7.5, 8, 8.5, 9] as const;
export type AvenirStemSize = (typeof taperlocStemSizes)[number];
export const stemSizes = [...taperlocStemSizes, ...avenirStemSizes] as const;
export type StemSize = AvenirStemSize | TaperlocStemSize;
export type StemSizeString = `${StemSize}`;

export function isStemSize(value: number): value is StemSize {
    return stemSizes.includes(value as StemSize);
}

export function isStemSizeString(value: string): value is StemSizeString {
    return isStemSize(parseInt(value));
}

export function stemSizeFromString(value: string): StemSize {
    return parseInt(value) as StemSize;
}

export const stemProfiles = [
    'Reduced Distal',
    'Full Profile',
    'Microplasty',
    'Collarless',
    'Collared',
] as const;
export type StemProfile = (typeof stemProfiles)[number];

export function isStemProfile(value: string): value is StemProfile {
    return stemProfiles.includes(value as StemProfile);
}

/** Display strings for stem profiles */
const STEM_PROFILE_NAME: { [K in StemProfile]: string } = {
    ['Full Profile']: 'Full',
    ['Reduced Distal']: 'Reduced',
    ['Microplasty']: 'Microplasty',
    ['Collarless']: 'Collarless',
    ['Collared']: 'Collared',
};

/** Get the display name for a string profile */
export function formatStemProfile(profile: StemProfile): string {
    return STEM_PROFILE_NAME[profile];
}

export const stemOffsets = ['High Offset', 'Standard Offset', 'XR123', 'Coxa Vara'] as const;
export type StemOffset = (typeof stemOffsets)[number];

export function isStemOffset(value: string): value is StemOffset {
    return stemOffsets.includes(value as StemOffset);
}

export type StemType = `${StemProfile}[${StemOffset}]`;

export function isStemType(value: string): value is StemType {
    const matches = value.match(/(.*)\[(.*)]/);
    return !!matches && isStemProfile(matches[1]) && isStemOffset(matches[2]);
}

/** Create a stem-type as a combination of profile and offset */
export function stemType(info: StemTypeInfo): StemType;
export function stemType(profile: StemProfile, offset: StemOffset): StemType;
export function stemType(arg0: StemProfile | StemTypeInfo, offset?: StemOffset): StemType {
    if (typeof arg0 === 'string') {
        assert(offset);
        return `${arg0}[${offset}]`;
    } else {
        return `${arg0.profile}[${arg0.offset}]`;
    }
}

export type StemTypeInfo = {
    profile: StemProfile;
    offset: StemOffset;
}

/** Extract the stem profile and offset from a stem type, which is just a combination of the two */
export function stemTypeInfo(type: StemType): StemTypeInfo {
    const matches = type.match(/(.*)\[(.*)]/);
    if (matches) {
        const profile = matches[1];
        const offset = matches[2];
        if (isStemProfile(profile) && isStemOffset(offset)) {
            return { profile, offset };
        }
    }

    throw Error(`Failed to extract stem-type info from '${type}'`);
}


/** The properties of a type of stem, independent of its use in any particular surgical plan. */
export type CatalogStem = CatalogComponentProperties & {
    /** The stem-system: either Taperloc or Avenir */
    system: StemSystem;

    /** The stem-size */
    size: StemSizeString;

    /** The stem-type, a combination of the stem-profile and the stem-offset */
    type: StemType;

    /** The stem-profile: the shape of the main femoral part of the stem */
    profile: StemProfile;

    /** The stem-offset: the angle of the neck, and therefore head, relative to the femoral part of the stem */
    offset: StemOffset;
};

/** Create label/name for a stem combining the stem profile, size, and offset e.g. 'Reduced Distal 5 High Offset' */
export const formatStemLabel = (stem: CatalogStem): string => {
    const { profile, offset } = stemTypeInfo(stem.type);
    return `${profile} ${stem.size} ${offset}`;
};
