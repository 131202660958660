import type { Object3D } from 'three';
import { Matrix4 } from 'three';
import type { StopHandle } from '@/util';
import { watchImmediate } from '@vueuse/core';

export type TransformNode = {
    transform: Matrix4;
};

export function transformNode(properties?: Partial<TransformNode>): TransformNode {
    return {
        transform: properties?.transform?.clone() ?? new Matrix4(),
    };
}

export function updateTransform(node: TransformNode, object: Object3D): StopHandle {
    object.matrixAutoUpdate = false;
    return watchImmediate(
        () => node.transform,
        (value) => object.matrix.copy(value),
        { deep: true },
    );
}
