<template>
    <div class="approve-plan pb-3">
        <app-button
            expand
            borderless
            colour="green"
            prepend-icon="mdi-check"
            :loading="sync.isSaving"
            :disabled="store.plannerMode === 'disabled' || sync.hasUpdate"
            @click="store.approvePlan"
        >
            Approve
        </app-button>
        <app-hint class="hint" v-if="showWarning">
            <app-text inline>Changes have been made since Plan approved.</app-text>
            <v-tooltip
                max-width="450"
                activator="parent"
                location="bottom"
                open-on-hover
                content-class="manual-plan-already-approved-warning"
            >
                <v-container
                    outline
                    class="rounded-sm pa-0 ma-0"
                >
                    <div class="svg-container pa-0">
                        <svg viewBox="0 0 20 10">
                            <path d="M10,0,20,10H0Z" />
                        </svg>
                    </div>

                    <p class="custom-grey-body--text font-weight-bold">
                        Changes have been made since a Manual Plan was approved
                    </p>
                    <p>
                        If you wish these changes to be represented in the downloadable manual Plan
                        for this Case, click the &rsquo;Approve&rsquo; button once you&rsquo;re
                        happy with your changes.
                    </p>
                </v-container>
            </v-tooltip>
        </app-hint>
    </div>
</template>

<script setup lang="ts">
    import AppText from '@/components/AppText.vue';
    import AppButton from '@/components/AppButton.vue';
    import AppHint from '@/components/AppHint.vue';
    import { usePlannerStore } from '@/planner/plannerStore';
    import { useTemplateSyncStore } from '@/planner/template/templateSyncStore';
    import { computed, ref, watch } from 'vue';

    const store = usePlannerStore();
    const sync = useTemplateSyncStore();

    const currentChangesNotApprovedYet = ref(false);

    watch(
        () => sync.hasUpdate,
        (newValue, oldValue) => {
            // after a change has been made, shows the warning
            if (oldValue && !newValue) {
                currentChangesNotApprovedYet.value = true;
            }
        },
    );

    watch(
        () => store.currentOperation,
        (newValue, oldValue) => {
            // after an app
            if (oldValue === 'approve-plan' && newValue === null) {
                currentChangesNotApprovedYet.value = false;
            }
        },
    );

    /**
     * Check if there are changes that have not been approved yet.
     * This check refers to data loaded when the page loads.
     */
    const oldChangesNotApprovedYet = computed(() => {
        return (
            store.manualTemplateLastApprovedUrl !== null &&
            store.manualTemplateLastApprovedUrl !== store.manualTemplateCanonicalUrl
        );
    });

    /**
     * The warning is displayed, if we detect that there are changes that have not been approved yet.
     * These changes can be due to multiple reasons, such as changes previously made and detected on load,
     * or changes edited by the user in the current interaction.
     */
    const showWarning = computed(() => {
        return currentChangesNotApprovedYet.value || oldChangesNotApprovedYet.value;
    });

    const colour = `var(--planner-blue-vibrant)`;
</script>

<style scoped lang="scss">
    .approve-plan {
        padding: 0 10px;
    }

    .hint {
        padding-top: 8px;
        padding-bottom: 12px;
        display: flex;
        justify-content: left;
    }

    .manual-plan-already-approved-warning {
        padding: 20px;
        background-color: #20c4f4;
        opacity: initial;
        z-index: 20;
        box-shadow: none;
        border-radius: 0;
        width: 400px;

        .svg-container {
            height: 10px;

            svg {
                fill: v-bind('colour');
                width: 1.25rem;
                height: auto;
                position: absolute;
                top: -8px;
                left: 20px;
                bottom: 100%;
                right: 0.625rem;
            }
        }
    }
</style>
