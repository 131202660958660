import { assert, taggedLogger } from '@/util';
import type { Url } from '@/formus/types';
import type { PlannerState } from '@/planner/plannerState';
import { templateId } from '@/api/template/templateUrl';
import { pollTemplate } from '@/api/template/pollTemplate';
import type { AxiosRequestConfig, AxiosResponse } from 'axios';
import { AxiosError, HttpStatusCode } from 'axios';
import { client } from '@/api/http';
import { getIdFromUrl } from '@/lib/getIdFromUrl';
import type { TemplateId } from '@/formus/template/template';

const log = taggedLogger('approve-plan');

/**
 * Approves the current planner state, creating a plan from it.
 */
export async function approvePlan(
    state: PlannerState,
    webComponentVersion: string,
): Promise<string | null> {
    assert(state.manualTemplateUrl && state.template);

    const templateIds = templateId(state.manualTemplateUrl);
    log.info('Waiting surgical-template %d', templateIds.template);
    const template = await pollTemplate(
        state.manualTemplateUrl,
        (template) => template.recordState === 'completed',
    );

    assert(state.manualTemplateUrl === template.self);
    assert(!!template.canonical);

    return await _createPlan(templateIds, {
        name: 'user-approved-plan',
        web_component_version: webComponentVersion,
        surgical_template_history: template.canonical,
    });
}

type PlanCreatedDataRepresentation = {
    /** An arbitrary name */
    readonly name: string;

    readonly web_component_version: string;

    /** the uri of the specific version being approved */
    readonly surgical_template_history: Url;
};

function _planCollectionUrl(caseId: number, templateId: number): Url {
    return `/project/${caseId}/surgical/template/${templateId}/plan`;
}

async function _createPlan(
    templateId: TemplateId,
    createData: PlanCreatedDataRepresentation,
    config?: AxiosRequestConfig,
): Promise<string | null> {
    const { status, data, headers }: AxiosResponse = await client.post(
        _planCollectionUrl(templateId.case, templateId.template),
        createData,
        config,
    );
    if (status === HttpStatusCode.Created) {
        const idFromUrl = getIdFromUrl(headers.location);
        return idFromUrl !== '' ? idFromUrl : null;
    }

    throw new AxiosError(`Failed to create plan: ${data?.detail}`);
}
