import { Vector3 } from 'three';

/**
 * The set of 3 positions for each vertex in a face
 */
export type Face3Positions = [Vector3, Vector3, Vector3];

/**
 * @returns the face centroid
 * @see {@link https://brilliant.org/wiki/triangles-centroid/}
 */
export function getCentroid(positions: Face3Positions): Vector3 {
    const [v0, v1, v2] = positions;
    const faceCentroid = new Vector3(0, 0, 0);
    faceCentroid.set(0, 0, 0);
    faceCentroid.add(v0);
    faceCentroid.add(v1);
    faceCentroid.add(v2);
    faceCentroid.divideScalar(3);
    return faceCentroid;
}

