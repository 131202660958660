import type { NavigationGuard, RouterScrollBehavior } from 'vue-router';
import { createRouter, createWebHistory } from 'vue-router';
import LoginView from '@/views/LoginView.vue';
import PreferencesView from '@/views/PreferencesView.vue';
import CaseSettingsView from '@/views/CaseSettingsView.vue';
import ScansView from '@/views/ScansView.vue';
import PlansView from '@/plans/PlansView.vue';
import PlannerView from '@/views/PlannerView.vue';
import PrivacyView from '@/views/PrivacyView.vue';
import TermsView from '@/views/TermsView.vue';
import AboutView from '@/views/AboutView.vue';
import ForgotPasswordView from '@/auth/ForgotPasswordView.vue';
import ResetPasswordView from '@/auth/ResetPasswordView.vue';
import CaseSearchView from '@/views/CaseSearchView.vue';
import { cachedAuthTokens } from '@/api/cache';
import AdminView from '@/views/admin/AdminView.vue';
import { useUserStore } from '@/stores/user/store';

export enum ROUTES {
    HOME = 'HOME',
    LOGIN = 'LOGIN',
    PREFERENCES = 'PREFERENCES',
    NEW_CASE = 'NEW_CASE',
    EDIT_CASE = 'EDIT_CASE',
    CT_SCAN = 'CT_SCAN',
    PLANNER = 'PLANNER',
    PLANS = 'PLANS',
    ABOUT = 'ABOUT',
    TERMS = 'TERMS',
    PRIVACY = 'PRIVACY',
    FORGOT_PASSWORD = 'FORGOT_PASSWORD',
    RESET_PASSWORD = 'RESET_PASSWORD',
    ADMIN = 'ADMIN',
}

export const routes = [
    /**
     * These routes don't require the user to be logged in.
     */
    { path: '/login', name: ROUTES.LOGIN, component: LoginView },
    { path: '/forgot-password', name: ROUTES.FORGOT_PASSWORD, component: ForgotPasswordView },
    { path: '/reset-password', name: ROUTES.RESET_PASSWORD, component: ResetPasswordView },

    /**
     * Must be logged in to access the following routes.
     *
     * User will be redirected to login page if they are not logged in.
     */
    {
        path: '/',
        name: ROUTES.HOME,
        component: CaseSearchView,
        meta: { requiresAuth: true },
    },
    {
        path: '/preferences',
        name: ROUTES.PREFERENCES,
        component: PreferencesView,
        meta: { requiresAuth: true },
    },
    {
        path: '/case/new',
        name: ROUTES.NEW_CASE,
        component: CaseSettingsView,
        meta: { requiresAuth: true },
    },
    {
        path: '/case/:id/edit',
        name: ROUTES.EDIT_CASE,
        component: CaseSettingsView,
        meta: { requiresAuth: true, caseSpecific: true },
    },

    /**
     * These routes are case specific, meaning the page content refers to a particular case.
     *
     * Routes that are case specific have the case navigation in the application header.
     */
    {
        path: '/case/:id/ct-scan',
        name: ROUTES.CT_SCAN,
        component: ScansView,
        meta: { requiresAuth: true, caseSpecific: true },
    },
    {
        path: '/case/:id/planner',
        name: ROUTES.PLANNER,
        component: PlannerView,
        meta: { requiresAuth: true, caseSpecific: true },
    },
    {
        path: '/case/:id/plans',
        name: ROUTES.PLANS,
        component: PlansView,
        meta: { requiresAuth: true, caseSpecific: true },
    },

    /**
     * Static pages accessible from the sidebar.
     */
    {
        path: '/about',
        name: ROUTES.ABOUT,
        component: AboutView,
        meta: { requiresAuth: true },
    },
    {
        path: '/terms-of-use',
        name: ROUTES.TERMS,
        component: TermsView,
        meta: { requiresAuth: true },
    },
    {
        path: '/privacy-policy',
        name: ROUTES.PRIVACY,
        component: PrivacyView,
        meta: { requiresAuth: true },
    },

    {
        path: '/admin/case/:id/',
        name: ROUTES.ADMIN,
        component: AdminView,
        beforeEnter: async (to: any, from: any, next: any) => {
            const store = useUserStore();
            await store.load();

            if (store.isAdmin) {
                next();
            }

            return false;
        },
        meta: { requiresAuth: true, caseSpecific: true },
    },
];

export function plannerRoute(caseId: number) {
    return {
        name: ROUTES.PLANNER,
        params: {
            id: caseId,
        },
    };
}

export function adminCaseRoute(caseId: number) {
    return {
        name: ROUTES.ADMIN,
        params: {
            id: caseId,
        },
    };
}

const scrollBehavior: RouterScrollBehavior = (to, from, savedPosition) => {
    if (savedPosition) {
        return savedPosition;
    } else {
        return { top: 0 };
    }
};

export const router = createRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    routes,
    scrollBehavior,
});

/**
 * Implements software requirement: H1SR-68
 *
 * @link https://formuslabs.youtrack.cloud/issue/H1SR-68/User-can-authenticate-with-their-email-as-a-username-and-their-chosen-password
 *
 * @see user cannot perform any other functionality in the software while unauthenticated
 */

export const requireAuthentication: NavigationGuard = async (to: any) => {
    // its fine if the user has a valid refresh token,
    // we can use that to automatically obtain a new access token if needed.
    const tokens = await cachedAuthTokens();
    if (to.meta.requiresAuth && !tokens) return { name: ROUTES.LOGIN };
};
router.beforeEach(requireAuthentication);

export default router;
