<template>
    <v-layout>
        <app-sidebar>
            <router-link :to="{ name: ROUTES.HOME }">
                <app-link icon="mdi-arrow-left" color="red" alone bold>Back to your Cases</app-link>
            </router-link>
        </app-sidebar>

        <app-main-view>
            <app-form-title> About </app-form-title>
            <app-card size="large" narrow class="app-about-page">
                <app-heading> The Future of Orthopaedic Planning...Today! </app-heading>
                <app-text>
                    The world’s first and finest AI-powered 3D planner. The Formus system is a
                    curator of personalized planning that delivers unprecedented insights to advance
                    patient care.
                </app-text>
                <app-text>
                    Founded from within the world-renowned Auckland Bioengineering Institute, the
                    Formus platform employs cutting edge machine learning and computational
                    biomechanics to not only create a data lake but to derive insights from this
                    data to improve the lives of patients worldwide.
                </app-text>

                <app-heading withTopSpacing>Trademarks</app-heading>
                <app-text>
                    Formuslabs, the Formuslabs logo, and Deepseg are registered trademarks or
                    trademarks of Formus Labs, Ltd. All other brand names, product names or
                    trademarks belong to their respective holders.
                </app-text>

                <v-divider class="app-about-page-divider"></v-divider>

                <div class="app-about-details-group" data-testid="md-consult-ifu">
                    <div class="app-about-details-group-left">
                        <app-text class="app-company-name">Formus Hip ™</app-text>
                    </div>
                    <div class="about=about-details-group-right">
                        <img
                            src="@/assets/about/medical-device-black.png"
                            alt="medical device"
                            style="margin-right: 10px"
                        />
                        <img src="@/assets/about/consult-ifu-black.png" alt="IFU" />
                    </div>
                </div>

                <div class="app-about-details-group" data-testid="manufacturer">
                    <div class="app-about-details-group-left">
                        <img src="@/assets/about/manufacturer-black.png" alt="manufacturer" />
                    </div>
                    <div class="app-about-details-group-right">
                        <app-text>
                            Formus Labs Limited <br />
                            Suite 5, Floor 3, 30 St Benedicts Street, Eden Terrace, Auckland 1010,
                            New Zealand
                        </app-text>
                    </div>
                </div>

                <div class="app-about-details-group" data-testid="batch-code">
                    <div class="app-about-details-group-left">
                        <img src="@/assets/about/batch-code-black.png" alt="batch code" />
                    </div>
                    <div class="app-about-details-group-right">
                        <app-text v-if="!versionStore.isLoading" inline style="height: 30px">{{
                            versionStore.versionNumber
                        }}</app-text>
                    </div>
                </div>

                <div class="app-about-details-group" data-testid="manufacturing-date">
                    <div class="app-about-details-group-left">
                        <img
                            src="@/assets/about/date-of-manufacture-black.png"
                            alt="manufacturing date"
                        />
                    </div>
                    <div class="app-about-details-group-right">
                        <app-text v-if="!versionStore.isLoading" inline style="height: 30px">{{
                            versionStore.releaseDate
                        }}</app-text>
                    </div>
                </div>

                <div class="app-about-details-group" data-testid="udi">
                    <div class="app-about-details-group-left">
                        <img
                            src="@/assets/about/unique-device-identifier-black.png"
                            alt="unique device identifier"
                        />
                    </div>
                    <div class="app-about-details-group-right">
                        <app-text inline style="height: 30px">{{
                            versionStore.uniqueDeviceId
                        }}</app-text>
                    </div>
                </div>
            </app-card>
        </app-main-view>
    </v-layout>
</template>

<script setup lang="ts">
    import AppSidebar from '@/components/layout/AppSidebar.vue';
    import AppLink from '@/components/AppLink.vue';
    import AppCard from '@/components/AppCard.vue';
    import AppMainView from '@/components/layout/AppMainView.vue';
    import AppFormTitle from '@/components/forms/AppFormTitle.vue';
    import { ROUTES } from '@/router';
    import AppHeading from '@/components/AppHeading.vue';
    import AppText from '@/components/AppText.vue';
    import { useVersion } from '@/stores/version';

    const versionStore = useVersion();
    await versionStore.load();
</script>

<style scoped>
    .app-about-page-divider {
        padding: 10px 0;
    }
    .app-about-page img {
        max-width: 40px;
        max-height: 40px;
    }
    .app-company-name {
        padding-top: 8px;
        color: var(--planner-black);
    }
    .app-about-details-group {
        display: flex;
        margin-bottom: 10px;
    }
    .app-about-details-group-left {
        margin-right: 20px;
    }

    .app-about-details-group-right {
        display: flex;
        align-items: center;
    }
</style>
