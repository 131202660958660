import { AxesHelper, type Object3D } from 'three';
import { stopAll, type StopHandle } from '@/util';
import { watchImmediate } from '@vueuse/core';
import { watchEffect } from 'vue';

export type ObjectNode<T extends string = string> = {
    type: T;
    name: string | null;
    visible: boolean;
    showAxes: boolean;
};

export function objectNode<T extends string>(type: T, properties?: Partial<ObjectNode>): ObjectNode<T> {
    return {
        type,
        name: properties?.name ?? null,
        visible: properties?.visible ?? true,
        showAxes: properties?.showAxes ?? false,
    };
}

export function updateObject(node: ObjectNode, object: Object3D): StopHandle {
    return stopAll(
        updateObjectName(node, object),
        updateObjectVisibility(node, object),
        updateObjectAxes(node, object),
    );
}

export function updateObjectName(node: ObjectNode, object: Object3D): StopHandle {
    return watchImmediate(
        () => node.name,
        (name) => (object.name = name ?? ''),
    );
}

const AXES_SIZE = 20;

export function updateObjectAxes(node: ObjectNode, object: Object3D): StopHandle {
    let axes: AxesHelper | null = null;
    return watchEffect(
        () => {
            if (node.showAxes) {
                if (axes === null) {
                    axes = new AxesHelper(AXES_SIZE);
                }
                object.add(axes);
            } else {
                if (axes !== null) {
                    object.remove(axes);
                }
            }
        },
    );
}

export function updateObjectVisibility(node: ObjectNode, object: Object3D): StopHandle {
    return watchImmediate(
        () => node.visible,
        (value) => (object.visible = value),
    );
}

