import numeral from 'numeral';

/**
 * Filter floats to 1 decimal place
 */
export function formatNumber(value: number | undefined, precision = 2): string {
    if (Number.isFinite(value)) {
        return numeral(value).format('0.' + '0'.repeat(precision));
    } else {
        return '--';
    }
}
