import { ArrowHelper, Group, Mesh, type Scene } from 'three';
import { addObject } from '@/planner/3d/object3d';

export type DoubleSidedMesh = {
    frontSide: Mesh;
    backSide: Mesh;
};

export type FemoralAssembly = {
    /**
     * Group object that is the parent of the femur and femoral inner-cortical surface. Its origin
     * corresponds to centre-of-rotation of the head of the stem *before any stem transformation*
     *
     * Its attachment and translation depends on whether the assembly is in the retracted or native layout:
     *
     * - In **native** layout it is attached to the scene and transformed into its original 'native' position.
     * - In **retracted** layout it is attached to the stem-group, and translated so the head is
     *   socketed in the cup. It's rotation in this layout preserves its relative transformation to the femur
     *   while allowing the femur to retain its native orientation.
     *
     * The rotation of the group depends on whether data is available on the api to create a
     * femoral anatomical-coordinate-system (ACS)
     * If data is available on the API it will be aligned to a
     * and the 'isAligned' property will be true.
     */
    femoralGroup: Group;

    femoralShaftAxis: ArrowHelper;
    femoralProximalShaftAxis: ArrowHelper;
    femoralNeckAxis: ArrowHelper;
    femoralAnteversionNeckAxis: ArrowHelper;
    femoralAnteversionCondylarAxis: ArrowHelper;

    /** The outer surface of the femur. Always attached to the femoralGroup */
    operativeFemur: DoubleSidedMesh;

    /** The inner-cortical surface of the femur. Always attached to the femoralGroup */
    operativeFemurInternal: DoubleSidedMesh;
}

export function femoralAssembly(scene: Scene): FemoralAssembly {
    const femoralGroup = addObject(scene, new Group());
    return {
        femoralGroup,
        femoralShaftAxis: addObject(femoralGroup, new ArrowHelper()),
        femoralProximalShaftAxis: addObject(femoralGroup, new ArrowHelper()),
        femoralNeckAxis: addObject(femoralGroup, new ArrowHelper()),
        femoralAnteversionNeckAxis: addObject(femoralGroup, new ArrowHelper()),
        femoralAnteversionCondylarAxis: addObject(femoralGroup, new ArrowHelper()),
        operativeFemur: {
            frontSide: addObject(femoralGroup, new Mesh()),
            backSide: addObject(femoralGroup, new Mesh()),
        },
        operativeFemurInternal: {
            frontSide: addObject(femoralGroup, new Mesh()),
            backSide: addObject(femoralGroup, new Mesh()),
        },
    };
}
