<template>
    <div class="app-planner-stem-input-group">
        <app-radio-group hide-details inline v-model="mode">
            <v-radio label="Recommended" value="recommended" class="app-radio-label"></v-radio>
            <v-radio label="Other" value="other" class="app-radio-label"></v-radio>
        </app-radio-group>
    </div>

    <div v-if="mode === 'recommended'">
        <v-select
            label="Current Stem"
            v-model="store.selectedStem"
            :items="store.recommendedStems"
            item-title="name"
            item-value="stemUrl"
            variant="underlined"
            hide-details
        />
    </div>
    <div v-else-if="mode === 'other'">
        <v-select
            label="Type"
            v-model="store.selectedType"
            :items="store.selectableTypes"
            item-title="name"
            item-value="type"
            variant="underlined"
            hide-details
        />
        <br />
        <v-select
            label="Size"
            v-model="store.selectedSize"
            :items="store.selectableSizes"
            item-title="size"
            item-value="size"
            variant="underlined"
        >
            <template v-slot:item="{ props, item }">
                <v-list-item v-bind="props">
                    <template v-slot:prepend>
                        <v-icon v-if="item.raw.suitable" color="green">mdi-check-bold</v-icon>
                        <v-icon v-else color="red">mdi-alert-outline</v-icon>
                    </template>
                    {{ props.size }}
                </v-list-item>
            </template>
        </v-select>
    </div>

    <app-hint class="app-stem-selection-reset-hint">
        <app-text inline>Changing Stem selection will reset manual stem placement</app-text>
        <v-tooltip activator="parent" location="bottom" width="300">
            <p>Changing your Stem selection will reset manual positioning adjustments</p>
            <br />
            <p>
                Each Stem has an automatic placement generated during case processing. Changing the
                size or type of Stem will place the new Stem at its automatic placement.
            </p>
        </v-tooltip>
    </app-hint>
</template>

<style scoped>
    .app-planner-stem-input-group {
        margin-bottom: 10px;
    }
    .app-stem-selection-reset-hint {
        margin-top: 20px;
    }
</style>

<script setup lang="ts">
    import { ref } from 'vue';
    import AppText from '@/components/AppText.vue';
    import AppHint from '@/components/AppHint.vue';
    import AppRadioGroup from '@/components/inputs/AppRadioGroup.vue';
    import { useStemOverlayStore } from '@/planner/stemOverlayStore';
    import { watchImmediate } from '@vueuse/core';

    const store = useStemOverlayStore();

    const mode = ref<'recommended' | 'other' | null>(null);

    // When the selected stem is changed the mode should be updated according to whether
    // it is recommended or not
    watchImmediate(
        () => store.isSelectedStemRecommended,
        (value) => {
            mode.value = value === null
                ? null
                : (value ? 'recommended' : 'other');
        }
    )

    watchImmediate(mode, (m) => {
        if (m === 'other') {
            // When selection mode to is changed to 'other' initialize the selectable type and
            // size from the current selected stem
            store.initializeTypeAndSize();
        } else {
            // When mode is recommended change the stem to a recommended one
            if (!store.isSelectedStemRecommended) {
                store.selectedStem = store.recommendedStems[0].stemUrl;
            }
        }
    });

</script>
