import { defineStore } from 'pinia';
import { computed, reactive, toRefs } from 'vue';
import {
    type Adjustments,
    type PlannerMode,
    plannerState,
    type PlannerState,
} from '@/planner/plannerState';
import { watchImmediate } from '@vueuse/core';
import { initialVisibilityToggles } from '@/planner/visibilityToggles';
import { loadCase, reloadTemplate } from '@/planner/loading';
import { assert, taggedLogger } from '@/util';
import { useAppErrorStore } from '@/stores/appErrorStore';
import type { Url } from '@/formus/types';
import { computeFittedStem, type FittedStem } from '@/planner/fittedStem';
import { getTemplate } from '@/api/template/getTemplate';
import { anatomicAngles } from '@/formus/anatomy/pelvis/acetabularAngles';
import { toRadians } from '@/formus/anatomy/pelvis/anteversionInclination';
import { executeOperation } from '@/planner/executeOperation';

import { templateUpdate, useTemplateSyncStore } from '@/planner/template/templateSyncStore';
import { formatLength } from '@/lib/format/formatLength';
import { putTemplate } from '@/api/template/putTemplate';
import { useVersion } from '@/stores/version';
import { approvePlan } from '@/planner/approvePlan';
import { router, ROUTES } from '@/router';
import { caseIdFromUrl } from '@/planner/api/case';
import { useBackgroundDataStore } from '@/planner/backgroundDataStore';
import { calculateLegLengthAndOffset } from '@/planner/adjustments';
import { positionalPart } from '@/geometry/matrix';
import { computeFittedCup, type FittedCup } from '@/planner/fittedCup';
import { computeBearingUrl, computeHeadUrl, computeLinerUrl } from '@/planner/componentUrls';

const log = taggedLogger('planner');

/** The type of the store that represents the 3d-planning-view */
export type PlannerStore = PlannerState & {
    isLoading: boolean;
    setMode: (mode: PlannerMode) => void;
    loadCase: (caseId: number) => void;
    adjustments: Adjustments | null;
    setTargetAdjustments: (adjustments: Adjustments) => Promise<void>;
    approvePlan: () => Promise<void>;
    fittedStem: FittedStem | null;
    fittedCup: FittedCup | null;
    setStem: (url: Url) => void;
    resetStem: () => void;
    setHead: (url: Url) => void;
    setCup: (url: Url) => void;
    resetCup: () => void;
    setDualMobility: (value: boolean) => void;
};

/** The store that represents the 3D-planning-view */
export const usePlannerStore: () => PlannerStore = defineStore('planner', () => {
    const appError = useAppErrorStore();
    const backgroundDataStore = useBackgroundDataStore();
    const templateSync = useTemplateSyncStore();
    const state = reactive<PlannerState>(plannerState());

    watchImmediate(
        () => state.currentOperation,
        (op) => {
            if (op === 'error') {
                appError.hasError = true;
            }
        },
    );

    watchImmediate(
        () => state.visibility['xray'],
        (value) => (state.renderingMode = value === 'on' ? 'xray' : 'normal'),
    );

    function startBackgroundLoading() {
        assert(state.case);
        backgroundDataStore.load(state, state.case);
    }

    function startSync() {
        assert(state.manualTemplateUrl && state.template);
        templateSync.startSync(state.manualTemplateUrl, state.template);
    }

    const fittedCup = computed<FittedCup | null>(() => computeFittedCup(state));

    const fittedStem = computed<FittedStem | null>(() => computeFittedStem(state));

    const isLoading = computed(() => state.currentOperation === 'load-case');

    return {
        ...toRefs(state),
        isLoading,
        setMode: (mode: PlannerMode) => {
            log.info('Planner mode set to %s', mode);
            state.plannerMode = mode;
            state.visibility = initialVisibilityToggles(mode);
        },
        loadCase: async (caseId: number) => {
            backgroundDataStore.stop();
            templateSync.stopSync();

            state.plannerMode = 'disabled';
            await executeOperation(state, 'load-case', () => loadCase(state, caseId));
            state.plannerMode = 'default';

            state.caseId = caseId;

            startBackgroundLoading();
            startSync();
        },
        adjustments: computed<Adjustments | null>(() => {
            if (
                !fittedCup.value ||
                !fittedStem.value ||
                !state.template ||
                !state.operationalSide ||
                isLoading.value
            ) {
                return null;
            }
            return calculateLegLengthAndOffset(
                state.operationalSide,
                fittedCup.value.basis,
                positionalPart(state.scene.cupGroup.transform),
                positionalPart(state.template.stemTransform).applyMatrix4(
                    fittedStem.value.transform,
                ),
            );
        }),
        setTargetAdjustments: async (adjustments: Adjustments): Promise<void> => {
            log.info(
                'Setting target leg-length %s and offset %s',
                formatLength(adjustments.legLength),
                formatLength(adjustments.offset),
            );
            templateSync.stopSync();
            state.plannerMode = 'disabled';
            await executeOperation(state, 'set-targets', async () => {
                assert(state.manualTemplateUrl && state.template);
                await putTemplate(
                    state.manualTemplateUrl,
                    templateUpdate(state.template, adjustments),
                );
                await reloadTemplate(state);
            });
            state.plannerMode = 'stem';
            startSync();
        },
        approvePlan: async (): Promise<void> => {
            log.info('Approving plan %s');
            templateSync.stopSync();
            state.plannerMode = 'disabled';
            await executeOperation(state, 'approve-plan', async () => {
                const productVersion = useVersion().webComponentVersion;
                const planId = await approvePlan(state, productVersion);
                if (planId) {
                    log.info('Plan approved: %s. Attempt to navigate to plans page.', planId);
                    assert(state.case);
                    const failure = await router.push({
                        name: ROUTES.PLANS,
                        params: {
                            id: caseIdFromUrl(state.case),
                        },
                    });

                    if (!failure) {
                        log.info('Navigated to plans. Nothing to do.');
                    } else {
                        log.error('Failed to navigate to plans');
                        state.plannerMode = 'disabled';
                        startSync();
                    }
                } else {
                    log.error('Failed to approve plan');
                    state.plannerMode = 'disabled';
                    startSync();
                }
            });
        },
        fittedStem,
        setStem: (url: Url) => {
            assert(state.template);
            state.template.stemUrl = url;
            state.template.stemTransform.identity();
        },
        resetStem: () =>
            executeOperation(state, 'reset-stem', async () => {
                assert(state.template && state.automatedTemplateUrl);
                const autoTemplate = await getTemplate(state.automatedTemplateUrl);
                state.template.stemUrl = autoTemplate.stem;
                state.template.headUrl = autoTemplate.head;
                state.template.stemTransform.identity();
            }),
        setHead: (url: Url) => {
            assert(state.template);
            state.template.headUrl = url;
        },
        fittedCup,
        setCup: (url: Url) => {
            assert(state.template && state.catalog);
            const catalogCup = state.catalog.cups.get(url);
            if (!catalogCup) {
                throw Error(`Invalid cup url: ${url}`);
            }
            state.template.cupUrl = url;
            state.template.linerUrl = computeLinerUrl(state);
            state.template.bearingUrl = computeBearingUrl(state);
            state.template.headUrl = computeHeadUrl(state);

            // TODO Need to do head here eventually as well?
            // const head = findHead(state.catalog, liner.headSize, 0);
        },
        resetCup: () =>
            executeOperation(state, 'reset-cup', async () => {
                assert(state.automatedTemplateUrl && state.template);
                const autoTemplate = await getTemplate(state.automatedTemplateUrl);
                state.template.cupUrl = autoTemplate.cup;
                state.template.cupRotation = anatomicAngles(toRadians(autoTemplate.cupRotation));
                state.template.dualMobility = false;

                state.template.linerUrl = autoTemplate.liner;
                state.template.bearingUrl = null;
                // Keep the head offset while resetting the cup
                state.template.headUrl = computeHeadUrl(state);
            }),
        setDualMobility: (value: boolean) => {
            assert(state.template);
            state.template.dualMobility = value;
            state.template.linerUrl = computeLinerUrl(state);
            state.template.headUrl = computeHeadUrl(state);
            state.template.bearingUrl = computeBearingUrl(state);
        },
    };
});
