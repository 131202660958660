import { objectNode, type ObjectNode, updateObject } from '@/planner/3d/object';
import { transformNode, type TransformNode, updateTransform } from '@/planner/3d/transform';
import type { GridHelper } from 'three';
import { stopAll, type StopHandle } from '@/util';

export type GridNode = ObjectNode<'grid'> & TransformNode;

export function gridNode(properties?: Partial<GridNode>): GridNode {
    return {
        ...objectNode('grid', properties),
        ...transformNode(properties)
    }
}

export function updateGrid(node: GridNode, grid: GridHelper): StopHandle {
    return stopAll(
        updateObject(node, grid),
        updateTransform(node, grid),
    )
}
