import { isFiniteNumber } from '@/util';

export function formatInteger(value: number | null | undefined): string {
    return isFiniteNumber(value)
        ? Math.trunc(value).toFixed()
        : '--';
}

export function formatSignedInteger(value: number | null | undefined): string {
    if (isFiniteNumber(value)) {
        return value >= 1
            ? `+${Math.trunc(value)}`
            : Math.trunc(value).toFixed();
    } else {
        return '--';
    }
}
