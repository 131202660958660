import { meshNode, type MeshNode } from '@/planner/scene/mesh';
import { groupNode, type GroupNode } from '@/planner/3d/group';
import { doubleSidedMeshNode, type DoubleSidedMeshNode } from '@/planner/scene/doubleSidedMesh';
import { axisNode, type AxisNode } from '@/planner/3d/axis';
import { type ColorRepresentation, Matrix4 } from 'three';
import { catStackImageNode, type CatStackImageNode } from '@/planner/scene/catStackImage';
import { axesNode, type AxesNode } from '@/planner/3d/axes';
import { gridNode, type GridNode } from '@/planner/3d/grid';
import LPS from '@/formus/anatomy/LPS';
import type { PerspectiveCameraNode } from '@/planner/3d/perspectiveCamera';
import { perspectiveCameraNode } from '@/planner/3d/perspectiveCamera';

export type PlannerSceneState = {
    camera: PerspectiveCameraNode;

    ctOrigin: AxesNode;

    sceneCentre: AxesNode;
    sceneGrid: GridNode;

    femoralGroup: GroupNode;
    operativeFemur: DoubleSidedMeshNode;
    operativeFemurInternal: DoubleSidedMeshNode;
    femoralShaftAxis: AxisNode;
    femoralProximalShaftAxis: AxisNode;
    femoralNeckAxis: AxisNode;
    femoralAnteversionNeckAxis: AxisNode;
    femoralAnteversionCondylarAxis: AxisNode;

    contralateralFemur: MeshNode;
    operativeHemipelvis: MeshNode;
    contralateralHemipelvis: MeshNode
    sacrum: MeshNode
    metal: MeshNode

    cupGroup: GroupNode;
    cup: MeshNode;
    cupCollisionSurface: MeshNode;
    cupCoverage: MeshNode;
    liner: MeshNode;

    stemGroup: GroupNode;
    stem: MeshNode;
    head: MeshNode;
    bearing: MeshNode;
    stemNeckAxis: AxisNode;
    stemShaftAxis: AxisNode;
    stemPaAxis: AxisNode;

    axialCatStack: CatStackImageNode;
    coronalCatStack: CatStackImageNode;
};

export function sceneState(): PlannerSceneState {
    return {
        camera: perspectiveCameraNode(),

        ctOrigin: _axesNode('ct-origin'),

        sceneCentre: _axesNode('scene-centre'),
        sceneGrid: _gridNode('scene-grid'),

        femoralGroup: groupNode({ name: 'femur-group' }),
        operativeFemur: doubleSidedMeshNode('operative-femur'),
        operativeFemurInternal: doubleSidedMeshNode('operative-femur-internal'),
        femoralShaftAxis: _axisNode('femoral-shaft-axis', '#e400ff'),
        femoralProximalShaftAxis: _axisNode('femoral-shaft-axis', '#7147ff'),
        femoralNeckAxis: _axisNode('femoral-neck-axis', '#cbff47'),
        femoralAnteversionNeckAxis: _axisNode('femoral-anteversion-neck-vector', '#fbc81c'),
        femoralAnteversionCondylarAxis: _axisNode('femoral-anteversion-condylar-vector', '#fd8625'),

        contralateralFemur: meshNode('contralateral-femur'),
        operativeHemipelvis: meshNode('operative-hemipelvis'),
        contralateralHemipelvis: meshNode('contralateral-hemipelvis'),
        sacrum: meshNode('sacrum'),
        metal: meshNode('metal'),

        cupGroup: groupNode({ name: 'cup-group' }),
        cup: meshNode('cup'),
        cupCollisionSurface: meshNode('cup-collision-surface'),
        cupCoverage: meshNode('cup-coverage'),
        liner: meshNode('liner'),

        stemGroup: groupNode({ name: 'stem-group' }),
        stem: meshNode('stem'),
        head: meshNode('head'),
        bearing: meshNode('bearing'),
        stemNeckAxis: _axisNode('stem-neck-axis', '#ff0346'),
        stemShaftAxis: _axisNode('stem-shaft-axis', '#00ff59'),
        stemPaAxis: _axisNode('stem-pa-axis', '#00eaff'),

        axialCatStack: catStackImageNode({ name: 'axial' }),
        coronalCatStack: catStackImageNode({ name: 'coronal' }),
    };
}

function _axesNode(name: string): AxesNode {
    return axesNode({
        name,
        visible: true,
    });
}

function _gridNode(name: string): GridNode {
    return gridNode({
        name,
        visible: true,
        transform: new Matrix4().makeRotationAxis(LPS.Left, Math.PI / 2),
    });
}

function _axisNode(name: string, color: ColorRepresentation): AxisNode {
    return axisNode({
        name,
        color,
        length: 20,
        visible: false,
    });
}

