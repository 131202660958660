import { type AxiosRequestConfig, type AxiosResponse, HttpStatusCode } from 'axios';
import { client } from '@/api/http';
import { errorDetail } from '@/planner/api/errorDetail';
import type { ApiLinks } from '@/api/links';
import { type ApiTemplateStateProperties, templateStateProperties } from '@/api/template/templateStateProperties';
import type { Template, TemplateId } from '@/formus/template/template';
import { convertLinks } from '@/api/convertLinks';
import type { Url } from '@/formus/types';
import { templateIdAndUrl } from '@/api/template/templateUrl';
import { type ApiTemplateProperties, templateProperties } from '@/api/template/templateProperties';

const templateLinkMap = {
    self: 'self',
    canonical: 'canonical',
    study: 'study',
    components: { rel: 'components', optional: true },
    plan: { rel: 'plan', title: 'current', optional: true },
    cup: 'cup',
    liner: 'liner',
    stem: 'stem',
    head: 'head',
} as const;

type _ApiTemplateData = ApiTemplateStateProperties & ApiTemplateProperties & ApiLinks;

export async function getTemplate(
    templateId: TemplateId | Url,
    config?: AxiosRequestConfig,
): Promise<Template> {
    const [id, url] = templateIdAndUrl(templateId);
    const { status, data }: AxiosResponse<_ApiTemplateData> = await client.get(url, config);
    if (status === HttpStatusCode.Ok) {
        return {
            id,
            ...templateStateProperties(data),
            ...templateProperties(data),
            ...convertLinks(data, templateLinkMap),
        };
    }

    throw Error('Failed to get surgical-template' + errorDetail(data));
}
