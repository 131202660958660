import { initialVisibilityToggles, type VisibilityToggles } from '@/planner/visibilityToggles';
import { sceneState, type PlannerSceneState } from '@/planner/scene/sceneState';
import type { Vector3 } from 'three';
import { zero3 } from '@/geometry/vector3';
import type { ComponentCatalog } from '@/formus/catalog/catalog';
import type { ApiFittedComponents } from '@/api/fittedComponents/getFittedComponents';
import type { BodySide } from '@/formus/anatomy/side';
import type { Url } from '@/formus/types';
import { type LpsVectors } from '@/formus/anatomy/LPS';
import type { ManualTemplateState } from '@/planner/template/manualTemplateState';
import type { AxisMeasurement } from '@/formus/anatomy/measurements';
import type { NumberArray3 } from '@/geometry/apiVector';
import type { CatstackAxis } from '@/planner/cat-stack/types';
import type { ApiCaseCatStack } from '@/api/cat-stacks/types';
import type { AlignmentMode } from '@/formus/anatomy/pelvis/alignment';
import type { SpinoPelvicLandmarks } from '@/components/spinopelvic/calculations';
import type { PreferredSystem } from '@/planner/api/specification';

/**
 * Different modes for rendering the objects in the 3D scene
 *
 * - 'normal': Standard shaded and lit appearance
 * - 'xray': Surfaces are transparent dependent on the angle they are viewed, becoming more opaque
 *   as they become tangential to the view direction.
 */
export type RenderingMode = 'normal' | 'xray';

export const plannerModes = ['default', 'cup', 'stem', 'combined', 'disabled'] as const;

export type PlannerMode = (typeof plannerModes)[number];

/** Blocking planner operations, during which we disable the UI to allow the operation to complete */
export type PlannerOperationId =
    | 'load-case'
    | 'set-targets'
    | 'reset-cup'
    | 'reset-stem'
    | 'error'
    | 'aborted'
    | 'approve-plan';

/** Optimisation targets */
export type Adjustments = {
    /** The change in leg-length */
    legLength: number;

    /** The change in offset */
    offset: number;
};

export type CatstackSlider = {
    max: number;
    min: number;
    value: number;
};

export type CatstackSliderState = {
    axial: CatstackSlider;
    coronal: CatstackSlider;
};

export type ScanState = {
    catStack: ApiCaseCatStack;
    axis: CatstackAxis;
    slider: CatstackSlider;
};

export type Scans = {
    visible: boolean;
    axial: ScanState | null;
    coronal: ScanState | null;
};

/** Native measurements to be displayed in the overlay view */
export type NativeMeasurements = {
    hipLengthDifference: number | null;
    femoralAnteversion: number | null;
    acetabularAnteversion: number | null;
    acetabularInclination: number | null;
};

export type FemoralFeatures = {
    shaftAxis: AxisMeasurement;
    proximalShaftAxis: AxisMeasurement;
    neckAxis: AxisMeasurement;
    anteversionNeck: NumberArray3;
    anteversionCondylar: NumberArray3;
    stemTranslationBasis: LpsVectors;
    shaftBasis: LpsVectors;
};

export type PlannerState = {
    caseId: number | null;
    case: Url | null;
    caseVersion: string | null;
    automatedTemplateUrl: Url | null;
    manualTemplateUrl: Url | null;
    manualTemplateCanonicalUrl: Url | null;
    manualTemplateLastApprovedUrl: Url | null;
    template: ManualTemplateState | null;

    targetAdjustments: Adjustments | null;
    scans: Scans | null;
    operationalSide: BodySide | null;
    plannerMode: PlannerMode;
    renderingMode: RenderingMode;
    currentOperation: PlannerOperationId | null;
    visibility: VisibilityToggles;
    nativeMeasurements: NativeMeasurements;
    hipSpineLandmarks: SpinoPelvicLandmarks | null;
    alignmentMode: AlignmentMode | null;
    preferredSystem: PreferredSystem | null;
    femoralFeatures: FemoralFeatures | null;

    scene: PlannerSceneState;
    sceneCentre: Vector3;
    catalog: ComponentCatalog | null;
    fittedComponents: ApiFittedComponents | null;
};

/**
 * Create the initial planner-state
 */
export function plannerState(): PlannerState {
    return {
        caseId: null,
        case: null,
        caseVersion: null,
        automatedTemplateUrl: null,
        manualTemplateUrl: null,
        manualTemplateCanonicalUrl: null,
        manualTemplateLastApprovedUrl: null,
        template: null,
        operationalSide: null,
        plannerMode: 'disabled',
        renderingMode: 'normal',
        currentOperation: null,
        visibility: initialVisibilityToggles('default'),
        targetAdjustments: null,
        scans: null,
        nativeMeasurements: {
            hipLengthDifference: null,
            femoralAnteversion: null,
            acetabularAnteversion: null,
            acetabularInclination: null,
        },
        hipSpineLandmarks: null,
        femoralFeatures: null,
        scene: sceneState(),
        sceneCentre: zero3(),
        catalog: null,
        fittedComponents: null,
        alignmentMode: null,
        preferredSystem: null,
    };
}
