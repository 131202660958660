<template>
    <button @click="toggle">
        <img :src="imageSource" :alt="`${id} mode`" v-if="visibility !== 'hidden'" />
    </button>
</template>

<style scoped>
    img {
        margin: 0 10px;
    }
</style>

<script setup lang="ts">
    import { computed } from 'vue';
    import femurModeActive from '@/assets/planner/view-mode/femur-mode-active.svg';
    import femurModeInactive from '@/assets/planner/view-mode/femur-mode-inactive.svg';
    import implantModeActive from '@/assets/planner/view-mode/implant-mode-active.svg';
    import implantModeInactive from '@/assets/planner/view-mode/implant-mode-inactive.svg';
    import pelvisModeActive from '@/assets/planner/view-mode/pelvis-mode-active.svg';
    import pelvisModeInactive from '@/assets/planner/view-mode/pelvis-mode-inactive.svg';
    import xrayModeActive from '@/assets/planner/view-mode/xray-mode-active.svg';
    import xrayModeInactive from '@/assets/planner/view-mode/xray-mode-inactive.svg';
    import stemCrossSectionModeActive from '@/assets/planner/view-mode/stem-cross-section-mode-active.svg';
    import stemCrossSectionModeInactive from '@/assets/planner/view-mode/stem-cross-section-mode-inactive.svg';
    import cupCrossSectionModeActive from '@/assets/planner/view-mode/cup-cross-section-mode-active.svg';
    import cupCrossSectionModeInactive from '@/assets/planner/view-mode/cup-cross-section-mode-inactive.svg';
    import neckCrossSectionModeActive from '@/assets/planner/view-mode/neck-cross-section-mode-active.svg';
    import neckCrossSectionModeInactive from '@/assets/planner/view-mode/neck-cross-section-mode-inactive.svg';
    import type { VisibilityToggleId, VisibilityToggleState } from '@/planner/visibilityToggles';
    import { usePlannerStore } from '@/planner/plannerStore';

    const props = defineProps<{
        id: VisibilityToggleId;
    }>();

    const plannerStore = usePlannerStore();

    const imageSourceMap: {
        [T in VisibilityToggleId]: { [S in Exclude<VisibilityToggleState, 'hidden'>]: string };
    } = {
        femur: { on: femurModeActive, off: femurModeInactive },
        pelvis: { on: pelvisModeActive, off: pelvisModeInactive },
        xray: { on: xrayModeActive, off: xrayModeInactive },
        implant: { on: implantModeActive, off: implantModeInactive },
        'stem-cross-section': { on: stemCrossSectionModeActive, off: stemCrossSectionModeInactive },
        'cup-cross-section': { on: cupCrossSectionModeActive, off: cupCrossSectionModeInactive },
        'neck-cross-section': { on: neckCrossSectionModeActive, off: neckCrossSectionModeInactive },
    } as const;

    const visibility = computed({
        get: () => plannerStore.visibility[props.id],
        set: (value) => (plannerStore.visibility[props.id] = value),
    });

    const imageSource = computed(() =>
        visibility.value === 'hidden' ? undefined : imageSourceMap[props.id][visibility.value],
    );

    function toggle() {
        switch (visibility.value) {
            case 'on':
                visibility.value = 'off';
                break;
            case 'off':
            case 'hidden':
                visibility.value = 'on';
                break;
            default:
                throw Error(`Invalid visibility value: ${visibility.value}`);
        }
    }
</script>
