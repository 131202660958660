import type { DicomInfo } from '@/lib/dicom/DicomInfo';
import type { DicomGroups, DicomSeries } from '@/lib/dicom/DicomSeries';
import DicomUtils from '@/lib/dicom/DicomUtil';
import { logger } from '@/util';

const log = logger('validateDicoms');

/**
 * A tagging type to specify the series have been already mutated with the 'isExcluded' flag and messages (error, warnings)
 * have been added, but no decision about if it is ok for upload has been made.
 */

export interface DicomValidatedResultOutput {
    series: DicomSeries;
    id: string;
}

export interface ProcessDicomsResult {
    /**
     * The dicom groups being analyzed, and processed. This is exposed just for context and continuation
     * of the workflow.
     */
    input: DicomGroups;

    /** The candidate for upload will be present if the state is not in error */
    uploadCandidate: DicomValidatedResultOutput | null;

    isValid: boolean;
}

export async function validateDicoms(input: DicomInfo[]): Promise<ProcessDicomsResult> {
    try {
        const validatedGroups: Record<string, DicomSeries> =
            await DicomUtils.makeValidatedDicomSeries(input);
        const dicomSeries = Object.entries(validatedGroups);

        const candidates = dicomSeries.filter(
            ([, group]: [string, DicomSeries]) => !group.isExcluded,
        );

        if (candidates.length === 1) {
            const [id, group] = candidates[0];
            return {
                input: validatedGroups,
                uploadCandidate: { id, series: group },
                isValid: true,
            };
        } else {
            log.error('Finding one series failed. Got %d, %o', candidates.length, candidates);
            return { input: validatedGroups, uploadCandidate: null, isValid: false };
        }
    } catch (error: unknown) {
        log.error('File upload failed: %s', error instanceof Error ? error.message : error);
    }

    return { input: {}, uploadCandidate: null, isValid: false };
}
