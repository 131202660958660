
import { type AxiosResponse, HttpStatusCode } from 'axios';
import { client } from '@/api/http';
import { type ApiLinks } from '@/api/links';
import type { Url } from '@/formus/types';
import { errorDetail } from '@/planner/api/errorDetail';
import type { ApiStudyId } from '@/planner/api/study';
import type { OperativeBoneId } from '@/formus/anatomy/bone';
import { type ConvertedRepresentation, convertRepresentation } from '@/planner/api/convertRepresentation';

export type ApiStudyModel = {
    id: Url;
    title: string;
};

type StudyModelsProperties = {
    items: ApiStudyModel[];
};

const studyModelsLinkMap = {
    self: 'self',
    study: 'up',
} as const;

export type ApiStudyModelsId = ApiStudyId & { search?: string };

/**
 * A study is a resource that is created in the context of a case. It is the primary resource
 * that represents a collection of DICOM files.
 */
export type ApiStudyModels = ConvertedRepresentation<
    StudyModelsProperties,
    typeof studyModelsLinkMap
>;

export type ApiBoneId = OperativeBoneId | 'operative-femur-internal' | 'metal';

export type BoneMeshKind =
    | 'segmentation'
    | 'atlas-pc-fit'
    | 'atlas-rbf-fit'
    | 'segmentation-with-bone-quality'
    | 'segmentation-with-normals';

export async function getStudyModels(modelsId: ApiStudyModelsId | Url): Promise<ApiStudyModels> {
    let url;
    if (typeof modelsId === 'object') {
        url =
            `project/${modelsId.case}/study/${modelsId.study}/model` +
            (modelsId.search ? `?s=${modelsId.search}` : '');
    } else {
        url = modelsId;
    }
    const { status, data }: AxiosResponse<StudyModelsProperties & ApiLinks> = await client.get(url);
    if (status === HttpStatusCode.Ok) {
        return convertRepresentation(data, studyModelsLinkMap);
    }

    throw Error(`Failed to load study-models from ${url}` + errorDetail(data));
}

export type FindModelOptions = {
    kind?: BoneMeshKind,
    optional?: boolean,
};

export function findStudyModel(
    model: ApiStudyModels,
    bone: ApiBoneId,
    options?: FindModelOptions): ApiStudyModel;
export function findStudyModel(
    model: ApiStudyModels,
    bone: ApiBoneId,
    options: FindModelOptions & { optional: true }): ApiStudyModel | null;

export function findStudyModel(
    model: ApiStudyModels,
    bone: ApiBoneId,
    options?: {
        kind?: BoneMeshKind,
        optional?: boolean,
    }
): ApiStudyModel | null {
    const kind = options?.kind ?? 'segmentation';
    const optional = options?.optional ?? false;
    const title = `${bone} - ${kind}`;
    const matching = model.items.filter((m) => m.title == title);

    if (matching.length == 1) {
        return matching[0];
    }

    if (matching.length == 0) {
        if (optional) {
            return null;
        } else {
            throw Error(
                [
                    `No models matched '${title}' - available titles are:`,
                    ...model.items.map((m) => `'${m.title}'`),
                ].join('  \n'),
            );
        }
    } else {
        throw Error(
            [
                `Multiple models matched '${title}' - matching titles are:`,
                ...matching.map((m) => `'${m.title}'`),
            ].join('  \n'),
        );
    }
}
