import { DUAL_MOBILITY_HEAD_SIZE, findBearing, findCatalogLiner, findHead } from "@/formus/catalog/catalog";
import type { PlannerState } from "@/planner/plannerState";
import type { Url } from '@/formus/types';
import { assert } from '@/util';
import type { CatalogHead } from "@/formus/catalog/head";


export function computeLinerUrl(state: PlannerState): Url {
    assert(state.catalog && state.template);
    const cupUrl = state.template.cupUrl;
    const catalogCup = state.catalog.cups.get(state.template.cupUrl);
    if (!catalogCup) {
        throw Error(`Invalid cup url: ${cupUrl}`);
    }
    if (state.template.dualMobility) {
        return findCatalogLiner(
            state.catalog,
            catalogCup.linerSize,
            DUAL_MOBILITY_HEAD_SIZE,
            'dual_mobility').self;
    } else {
        return findCatalogLiner(
            state.catalog,
            catalogCup.linerSize,
            catalogCup.recommendedHeadSize).self;
    }
}

export function computeBearingUrl(state: PlannerState): Url | null {
    assert(state.template && state.catalog);
    if (state.template.dualMobility) {
        const liner = state.catalog.liners.get(state.template.linerUrl);
        assert(liner);
        return findBearing(state.catalog, liner.size, DUAL_MOBILITY_HEAD_SIZE).self;
    } else {
        return null;
    }
}

export function computeHeadUrl(state: PlannerState): Url {
    return computeHead(state).self;
}

export function computeHead(state: PlannerState): CatalogHead {
    assert(state.template && state.catalog);
    const originalHead = state.catalog.heads.get(state.template.headUrl);
    assert(originalHead);
    if (state.template.dualMobility) {
        return findHead(state.catalog, originalHead.system, DUAL_MOBILITY_HEAD_SIZE, originalHead.offset);
    } else {
        const catalogCup = state.catalog.cups.get(state.template.cupUrl);
        assert (catalogCup);
        return findHead(state.catalog, originalHead.system, catalogCup.recommendedHeadSize, originalHead.offset);
    }
}
