import type { PlannerState } from '@/planner/plannerState';
import type { DoubleSidedMesh } from '@/planner/scene/femoralAssembly';
import { type LoadGeometry, updateGeometryFromSource } from '@/planner/3d/geometryLoading';
import { stopAll, type StopHandle } from '@/util';
import { normalMeshColor, xrayMeshColor } from '@/planner/scene/meshColor';
import { makeStandardMaterial } from '@/planner/3d/materials/standardMaterial';
import { BackSide, FrontSide } from 'three';
import {
    baseMeshNode,
    type BaseMeshNode,
    updateBaseMesh,
    updateMeshGeometry,
    updateRenderOrder,
} from '@/planner/3d/baseMesh';
import { updateMeshMaterial } from '@/planner/scene/meshMaterial';
import { watchImmediate } from '@vueuse/core';
import { isMeshVisible } from '@/planner/scene/meshVisibility';
import type { PlannerObjectId } from '@/planner/scene/plannerObjectId';
import { renderOrder } from '@/planner/scene/renderOrder';
import { updateTransform } from '@/planner/3d/transform';

export type DoubleSidedMeshNode = BaseMeshNode<'double-sided-mesh'> & {
    id: PlannerObjectId;
};

export function doubleSidedMeshNode(meshId: PlannerObjectId, options?: Partial<BaseMeshNode>): DoubleSidedMeshNode {
    return {
        id: meshId,
        ...baseMeshNode('double-sided-mesh', {
            name: meshId,
            renderOrder: renderOrder[meshId],
            ...options,
        }),
    };
}

export function updateDoubleSidedMesh(
    state: PlannerState,
    node: DoubleSidedMeshNode,
    meshes: DoubleSidedMesh,
    loadGeometry: LoadGeometry,
): StopHandle {
    const color = normalMeshColor(node.id);
    const xrayColor = xrayMeshColor(node.id);

    meshes.backSide.material = makeStandardMaterial({
        name: node.name ? `${node.id}-back-side` : undefined,
        color,
        side: BackSide,
        roughness: 1,
    });

    return stopAll(
        updateBaseMesh(node, meshes.frontSide),
        updateGeometryFromSource(node, loadGeometry),
        updateMeshMaterial(state, meshes.frontSide, {
            name: node.name ?? undefined,
            normalParams: {
                color,
                opacity: 0.5,
                transparent: true,
                side: FrontSide,
                roughness: 1,
            },
            xrayParams: {
                color: xrayColor,
            },
        }),
        watchImmediate(
            () => isMeshVisible(state, node.id),
            (visible) => (node.visible = visible),
        ),
        watchImmediate(
            () => node.name,
            (name) => (meshes.backSide.name = `${name}-back-side` ?? ''),
        ),
        updateTransform(node, meshes.backSide),
        updateRenderOrder(node, meshes.backSide),
        updateMeshGeometry(node, meshes.backSide),
        watchImmediate(
            () => isMeshVisible(state, node.id) && state.renderingMode !== 'xray',
            (value) => (meshes.backSide.visible = value),
        ),
    );
}
