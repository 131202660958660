import { Mesh } from 'three';
import { watchImmediate } from '@vueuse/core';
import { baseMeshNode, type BaseMeshNode, updateBaseMesh } from '@/planner/3d/baseMesh';
import type { PlannerState } from '@/planner/plannerState';
import { stopAll, type StopHandle } from '@/util';
import { renderOrder } from '@/planner/scene/renderOrder';
import { updateMeshMaterial } from '@/planner/scene/meshMaterial';
import { type LoadGeometry, updateGeometryFromSource } from '@/planner/3d/geometryLoading';
import { normalMeshColor, xrayMeshColor } from '@/planner/scene/meshColor';
import { isMeshVisible } from '@/planner/scene/meshVisibility';
import type { PlannerObjectId } from '@/planner/scene/plannerObjectId';

export type MeshNode = BaseMeshNode<'mesh'> & {
    id: PlannerObjectId;
};

export function meshNode(meshId: PlannerObjectId, options?: Partial<BaseMeshNode>): MeshNode {
    return {
        id: meshId,
        ...baseMeshNode('mesh', {
            name: meshId,
            renderOrder: renderOrder[meshId],
            ...options,
        }),
    };
}

export function updateMesh(
    state: PlannerState,
    node: MeshNode,
    mesh: Mesh,
    loadGeometry: LoadGeometry,
): StopHandle {
    return stopAll(
        updateBaseMesh(node, mesh),
        updateGeometryFromSource(node, loadGeometry),
        watchImmediate(
            () => isMeshVisible(state, node.id),
            (visible) => (mesh.visible = visible),
        ),
        updateMeshMaterial(state, mesh, {
            name: node.name ?? undefined,
            normalParams: {
                type: 'standard',
                color: normalMeshColor(node.id),
                roughness: 1,
            },
            xrayParams:
                node.id === 'cup-coverage'
                    ? {
                          type: 'solid',
                          opacity: 0.7,
                          transparent: true,
                          color: xrayMeshColor('cup-coverage'),
                      }
                    : {
                          type: 'xray',
                          color: xrayMeshColor(node.id),
                      },
        }),
    );
}
