import { findFittedCup, findFittedStem } from '@/planner/api/fittedComponents';
import type { Matrix4, Vector3 } from 'three';
import { matrixFromApi, type MatrixArray16 } from '@/geometry/apiMatrix';
import { positionalPart } from '@/geometry/matrix';
import { matrix4FromBasis } from '@/geometry/basis';
import { lpsIdentityVectors } from '@/formus/anatomy/LPS';
import type { PlannerState } from '@/planner/plannerState';
import { logger } from '@/util';
import type { NumberArray3 } from '@/geometry/apiVector';
import { vector3 } from '@/geometry/vector3';
import { logValidation } from '@/planner/logValidation';
import { indent } from '@/util/indent';
import { formatMatrixBasis } from '@/geometry/formatMath';
import { computeHeadUrl } from '@/planner/componentUrls';
import { useAppErrorStore } from '@/stores/appErrorStore';

const log = logger();

/** An axis the stem is rotated around for manual stem positioning */
export type FittedStemAxis = {
    position: Vector3;
    direction: Vector3;
}

export type FittedStemAxes = {
    /** The neck axis used for extension-flexion rotation, in stem-group space */
    neckAxis: FittedStemAxis;

    /** The shaft axis used for retroversion-anteversion rotation, in stem-group space */
    shaftAxis: FittedStemAxis;

    /** The posterior-anterior axis used for varus-valgus rotation, in stem-group space */
    paAxis: FittedStemAxis;
};

/**
 * A representation of the fitted stem that is derived from fitted-stem data on the API.
 *
 * It gives the fitted-head transform, as well as various features of the stem expressed in
 * stem-group space, and is used for calculating stem placement.
 */
export type FittedStem = {
    /**
     * The fitted-head-transform, in CT/world space. It is *positioned* at the fitted-head
     * centre but *aligned* to the femoral coordinate system.
     *
     * In a 'retracted' arrangement when the stem and femur have not been translated to
     * dock the head into the cup:
     * - It is the transform of the femoral-group
     * - The transform of the stem-group is found by multiplying it to the manual-stem-transform
     * - If there is no manual-stem-transform it is the same as the stem-group transform
     * */
    transform: Matrix4;

    /** The stem transform, in stem-group space */
    stemLocal: Matrix4;

    /** The head transform, in stem-group space */
    headLocal: Matrix4;

    /** The bearing transform, in stem-group space */
    bearingLocal: Matrix4 | null;

    /**
     * Axes of the stem represented in stem-space
     *
     * Old cases on the API may not have the axes needed for manual stem positioning, in
     * which case this value will be null
     * */
    axes: FittedStemAxes | null;
};

export function computeFittedStem(state: PlannerState): FittedStem | null {
    try {
        if (!state.fittedComponents || !state.catalog || !state.template) {
            return null;
        }
        const apiStem = findFittedStem(
            state.fittedComponents,
            state.catalog,
            state.template.stemUrl,
            computeHeadUrl(state),
        );
        const headTransform = matrixFromApi(apiStem.head.tmatrix);
        const stemTransform = matrixFromApi(apiStem.tmatrix);
        const headPosition = positionalPart(headTransform);
        const transform = matrix4FromBasis(
            state.femoralFeatures?.stemTranslationBasis ?? lpsIdentityVectors(),
            headPosition
        );

        const apiCup = findFittedCup(
            state.fittedComponents,
            state.catalog,
            state.template.cupUrl,
            state.template.linerUrl,
            state.template.bearingUrl
        );

        const bearingTransform = (apiCup.bearing && state.template.dualMobility) ? computeBearingTransform(apiStem.neck_axis, apiCup.bearing.head_centre, apiCup.bearing.tmatrix, apiStem.head.tmatrix) : null;

        logValidation(
            'Fitted-stem-transform:\n' +
            indent(2, formatMatrixBasis(transform, { precision: 5 }))
        );

        // Matrix used to transform world/CT space to stem-group local space
        const toStemGroup = transform.clone().invert();
        const axisToStemGroup = (position: NumberArray3, direction: NumberArray3): FittedStemAxis => {
            return {
                position: vector3(position).applyMatrix4(toStemGroup),
                direction: vector3(direction).transformDirection(toStemGroup),
            };
        };

        let axes: FittedStemAxes | null;
        if (apiStem.medial_pivot_point) {
            axes = {
                neckAxis: axisToStemGroup(apiStem.medial_pivot_point, apiStem.neck_axis),
                shaftAxis: axisToStemGroup(apiStem.shaft_axis_point, apiStem.shaft_axis_direction),
                paAxis: axisToStemGroup(apiStem.medial_pivot_point, apiStem.pa_axis),
            };
        } else {
            log.warn('Stem representation is missing stem-transformation data (old case?)');
            axes = null;
        }

        return {
            transform,
            // When the stem-group is at the fitted-head transform the stem and
            // head meshes should be at their fitted transforms.
            // This lets us calculate their relative transformations.
            stemLocal: toStemGroup.clone().multiply(stemTransform),
            headLocal: toStemGroup.clone().multiply(headTransform),
            bearingLocal: bearingTransform ? toStemGroup.clone().multiply(bearingTransform) : null,
            axes,
        };
    } catch (error) {
        useAppErrorStore().handleError(error);
        return null;
    }
}

/**
 * This function calculates the transform for the bearing so that
 * the bearing is aligned with the head.
 * The bearing transform from the API would align the bearing with the liner.
 * So it has to be recalculated. We could've just used the head transform from
 * the API. However, for the head, the head origin and the head center are at the
 * same point. While for the bearing, the bearing origin is at the bottom hole center.
 * So it has to be adjusted to account for the offset between the bearing origin and
 * the bearing head center along the neck axis direction.
 * @param apiStemNeckAxis stem neck axis passed by the API
 * @param apiBearingHeadCenter bearing head center passed by the API
 * @param apiBearingTmatrix bearing transform matrix passed by the API. This transform aligns the bearing with the liner. The bearing origin in the transform is at the bottom center instead of the sphere center.
 * @param apiHeadTmatrix head transform matrix passed by the API. The head origin is at the head center.
 * @returns bearing transform matrix that aligns the bearing with the head
 */
function computeBearingTransform(apiStemNeckAxis: NumberArray3, apiBearingHeadCenter: NumberArray3, apiBearingTmatrix: MatrixArray16, apiHeadTmatrix: MatrixArray16): Matrix4 {
    const apiBearingTransform = matrixFromApi(apiBearingTmatrix);
    // get the offset between the bearing head center and the bearing origin
    const centerOriginOffset = vector3(apiBearingHeadCenter).sub(positionalPart(apiBearingTransform)).length();
    const apiHeadTransform = matrixFromApi(apiHeadTmatrix);
    const headOrigin = positionalPart(apiHeadTransform);
    // adjust the bearingPosition by the offset
    const bearingOrigin = headOrigin.add(vector3(apiStemNeckAxis).multiplyScalar(-centerOriginOffset));
    return apiHeadTransform.clone().setPosition(bearingOrigin);
}
