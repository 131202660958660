<template>
    <div class="planner-overlay">
        <v-sheet class="side-panel-container">
            <planner-status class="planner-status" />

            <v-overlay contained scrim="white" opacity="0.5"
                       v-model="disabled" :aria-disabled="true" disabled />

            <v-expansion-panels
                variant="accordion"
                class="app-expansion-panels"
                v-model="expandedPanel"
            >
                <v-expansion-panel value="stem">
                    <expansion-panel-title title="Stem" :description="stemPanelDescription" image="stem" />
                    <v-expansion-panel-text>
                        <stem-panel />
                    </v-expansion-panel-text>
                </v-expansion-panel>

                <v-expansion-panel value="cup">
                    <expansion-panel-title title="Cup" :description="cupPanelDescription" image="cup" />
                    <v-expansion-panel-text>
                        <cup-panel />
                    </v-expansion-panel-text>
                </v-expansion-panel>

                <v-expansion-panel value="combined">
                    <expansion-panel-title title="Combined" image="hip" />
                    <v-expansion-panel-text>
                        <combined-offsets />
                    </v-expansion-panel-text>
                </v-expansion-panel>
            </v-expansion-panels>
            <app-divider />
            <approve-plan />
            <div v-if="developerSettings.shouldShowDeveloperTools">
                <app-divider />
                <v-expansion-panels class="app-expansion-panels">
                    <v-expansion-panel>
                        <expansion-panel-title title="Developer Tools" />
                        <v-expansion-panel-text>
                            <developer-tools />
                        </v-expansion-panel-text>
                    </v-expansion-panel>
                </v-expansion-panels>
            </div>
        </v-sheet>

        <div class="top-middle-container">
            <visibility-toggle-bar />
        </div>

        <div class="top-right-container">
            <adjustments-measurements />
        </div>

        <div class="bottom-left-container">
            <span class="app-version">--version goes here--</span>
        </div>

        <div class="bottom-right-container">
            <snap-to-view />
            <catstack-controls />
        </div>

        <slot />
    </div>
</template>

<script setup lang="ts">
    import { computed } from 'vue';
    import { usePlannerStore } from './plannerStore';
    import ExpansionPanelTitle from './components/ExpansionPanelTitle.vue';
    import StemPanel from '@/planner/components/StemPanel.vue';
    import CupPanel from './components/CupPanel.vue';
    import CombinedOffsets from './components/CombinedPanel.vue';
    import ApprovePlan from './components/ApprovePlan.vue';
    import CatstackControls from './components/CatstackControls.vue';
    import VisibilityToggleBar from '@/planner/components/VisibilityToggleBar.vue';
    import AdjustmentsMeasurements from '@/planner/components/AdjustmentsMeasurements.vue';
    import { assert } from '@/util';
    import { useCupOverlayStore } from '@/planner/cupOverlayStore';
    import { useStemOverlayStore } from '@/planner/stemOverlayStore';
    import { formatSignedInteger } from '@/lib/format/formatInteger';
    import PlannerStatus from '@/planner/components/PlannerStatus.vue';
    import DeveloperTools from '@/planner/components/DeveloperTools.vue';
    import { useDeveloperSettings } from '@/planner/developerSettings';
    import SnapToView from '@/planner/components/SnapToView.vue';

    const store = usePlannerStore();
    const cupStore = useCupOverlayStore();
    const stemStore = useStemOverlayStore();
    const developerSettings = useDeveloperSettings()

    const disabled = computed(() => store.currentOperation !== null);

    const expandedPanel = computed<'cup' | 'stem' | 'combined' | null>({
        get: () => {
            switch (store.plannerMode) {
                case 'default':
                case 'disabled':
                    return null;
                default:
                    return store.plannerMode;
            }
        },
        set: (value) => {
            assert(store.plannerMode !== 'disabled', 'planner-store is disabled');
            store.setMode(value ?? 'default');
        },
    });

    const stemPanelDescription = computed<string>(() => {
        if (!stemStore.info) {
            return '';
        }
        const { stemProfile, stemOffset, stemSize, headOffset } = stemStore.info;
        const shortStemProfile = stemProfile === 'Reduced Distal' ? 'Reduced' : stemProfile;
        return `${shortStemProfile} ${stemOffset} ${stemSize} / ${formatSignedInteger(headOffset)} Head`;
    });

    const cupPanelDescription = computed<string>(() =>
        cupStore.selectedCupSize ? `Size ${cupStore.selectedCupSize}` : '',
    );
</script>

<!--suppress CssUnusedSymbol -->
<style scoped>
    .planner-overlay {
        background: var(--planner-grey);
    }

    .side-panel-container {
        position: absolute;
        top: 0;
        left: 0;
        width: 400px;
        max-height: 100%;
        z-index: 20;
        overflow: auto;
        scrollbar-width: thin;
        scrollbar-gutter: stable both-edges;
    }

    .planner-status {
        position: absolute;
        right: 4px;
        top: 8px;
        z-index: 3000;
    }

    .app-expansion-panels :deep(.v-expansion-panel__shadow) {
        box-shadow: none;
    }

    .app-expansion-panels :deep(.v-expansion-panel-title__overlay) {
        opacity: 0;
    }

    .top-middle-container {
        position: absolute;
        top: 0;
        left: calc(50vw - 200px);
        right: calc(50vw - 200px);
        z-index: 10;
    }

    .top-right-container {
        position: absolute;
        top: 0;
        right: 0;
        margin: 20px;
        width: 400px;
        z-index: 10;
    }

    .bottom-left-container {
        position: absolute;
        bottom: 0;
        left: 0;
        margin: 20px;
        color: white;
        z-index: 10;
    }

    .bottom-right-container {
        position: absolute;
        bottom: 0;
        right: 0;
        margin: 20px;
        color: white;
        z-index: 10;
    }

    .app-version {
        font-size: 0.75rem;
        line-height: 1.25rem;
        font-weight: 500;
        color: var(--planner-grey);
    }
</style>
