import { Matrix4 } from 'three';
import type { BodySide } from '@/formus/anatomy/side';
import { positionalPart } from '@/geometry/matrix';
import { vector3 } from '@/geometry/vector3';
import type { FittedStemAxes, FittedStemAxis } from '@/planner/fittedStem';
import { degToRad, logger } from '@/util';
import { formatNumber } from '@/lib/format/formatNumber';

const log = logger();

/**
 * A representation of a **change** in the transform of a stem relative to a femur.
 *
 * **Note** the rotation numbers are interpreted relative to the current transform of the stem, as they
 * take place around various axes. They cannot be simply mapped to changes to the rotation of the
 * stem.
 * */
export type StemTransformChange = {
    /** posterior-anterior translation */
    translateAP: number
    /** superior-inferior translation */
    translateSI: number
    /** medial-lateral translation */
    translateML: number
    /** retroversion-anteversion rotation */
    rotateRA: number
    /** extension-flexion rotation */
    rotateEF: number
    /** valgus-varus rotation */
    rotateVV: number
}

export function applyStemTranslation(
    stemTransform: Matrix4,
    change: Partial<StemTransformChange>,
    operationalSide: BodySide,
): void {
    const [lateral, anterior, superior] =
        [change.translateML ?? 0, change.translateAP ?? 0, change.translateSI ?? 0];

    // In a left-side case the medial-to-lateral direction is to the left
    const left = operationalSide === 'left' ? lateral : -lateral;

    stemTransform.setPosition(
        positionalPart(stemTransform).add(vector3(left, -anterior, superior)),
    );
}

export function applyStemRotation(
    stemTransform: Matrix4,
    change: Partial<StemTransformChange>,
    operationalSide: BodySide,
    fittedStemAxes: FittedStemAxes,
): void {
    const [ef, ra, vv] = [change.rotateEF ?? 0, change.rotateRA ?? 0, change.rotateVV ?? 0];

    // The rotation angles depend on whether this is a left or right-side case
    const [neckShaftRotation, stemShaftRotation, paRotation] =
        operationalSide === 'left' ?
            [-ef, ra, -vv] :
            [ef, -ra, vv];
    applyRotation(stemTransform, neckShaftRotation, fittedStemAxes.neckAxis);
    applyRotation(stemTransform, stemShaftRotation, fittedStemAxes.shaftAxis);
    applyRotation(stemTransform, paRotation, fittedStemAxes.paAxis);
}

function applyRotation(
    stemTransform: Matrix4,
    angleDegrees: number,
    axis: FittedStemAxis,
): void {
    if (angleDegrees == 0) {
        // If the angle is zero there is no rotation to do
        return;
    }
    const translation = new Matrix4().makeTranslation(axis.position);
    const rotation = new Matrix4().makeRotationAxis(axis.direction, degToRad(angleDegrees));
    stemTransform
        .multiply(translation)
        .multiply(rotation)
        .multiply(translation.invert());
}

export function logStemTransformChange(change: Partial<StemTransformChange>) {
    const logTranslation = (key: keyof StemTransformChange, negativeName: string, positiveName: string) => {
        const value = change[key];
        if (value) {
            log.info(
                'Translating stem in the %s direction by %s mm',
                value > 0 ? positiveName : negativeName,
                formatNumber(Math.abs(value)),
            );
        }
    };
    const logRotation = (key: keyof StemTransformChange, negativeName: string, positiveName: string) => {
        const value = change[key];
        if (value) {
            log.info(
                'Rotating stem in the %s direction by %s\u00b0',
                value > 0 ? positiveName : negativeName,
                formatNumber(Math.abs(value)),
            );
        }
    };
    logTranslation('translateAP', 'anterior', 'posterior');
    logTranslation('translateSI', 'superior', 'inferior');
    logTranslation('translateML', 'medial', 'lateral');
    logRotation('rotateRA', 'retroversion', 'anteversion');
    logRotation('rotateEF', 'extension', 'flexion');
    logRotation('rotateVV', 'valgus', 'varus');
}
