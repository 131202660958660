<template>
    <header>
        <div class="app-logo-manufacturer" v-if="!auth.isLoggedIn">
            <router-link :to="{ name: ROUTES.HOME }">
                <img alt="Formuslabs Logo" src="@/assets/images/formus-full-logo.svg" />
            </router-link>
        </div>
        <div class="app-logo-manufacturer" v-if="auth.isLoggedIn">
            <router-link :to="{ name: ROUTES.HOME }">
                <img alt="Formuslabs Emblem" src="../../assets/images/formus-emblem.svg" />
            </router-link>
            <v-divider vertical color="white"></v-divider>
            <img alt="ZB Emblem" src="../../assets/images/zimmer-emblem.svg" />
        </div>

        <AppHeaderCase v-if="caseSyncStore.isCaseSpecific" />

        <nav v-if="auth.isLoggedIn">
            <CaseNotifications v-if="caseSyncStore.isCaseSpecific" />

            <a href="https://support.formuslabs.com/hc/en-us" target="_blank">
                <app-menu-item text="Help" icon="mdi-help-circle-outline" />
            </a>

            <router-link :to="{ name: ROUTES.PREFERENCES }">
                <app-menu-item text="Your Settings" icon="mdi-account" />
            </router-link>

            <router-link @click="auth.logout" :to="{ name: ROUTES.LOGIN }">
                <app-menu-item text="Logout" icon="mdi-logout" />
            </router-link>
        </nav>
    </header>
</template>

<script setup lang="ts">
    /**
     * Implements software requirement: H1SR-80, H1SR-85
     *
     * @link https://formuslabs.youtrack.cloud/issue/H1SR-80/The-Home-Button-Formus-Logo-directs-the-user-to-the-dashboard
     * @link https://formuslabs.youtrack.cloud/issue/H1SR-85/Revoke-user-authentication-when-user-clicks-the-logout-button
     */
    import AppMenuItem from '@/components/AppMenuItem.vue';
    import { ROUTES } from '@/router';
    import { useAuth } from '@/stores/auth';
    import { onBeforeUnmount } from 'vue';
    import AppHeaderCase from '@/components/layout/AppHeaderCase.vue';
    import { useCaseSyncStore } from '@/stores/navigation/useCaseSyncStore';
    import CaseNotifications from '@/components/header/notifications/CaseNotifications.vue';

    const auth = useAuth();

    const caseSyncStore = useCaseSyncStore();

    onBeforeUnmount(() => {
        caseSyncStore.stop();
    });
</script>

<style scoped>
    header {
        background-color: var(--planner-blue-rich);
        box-shadow: 0 10px 0 #00000005;
        display: flex;
        left: 0px;
        width: calc(100% + 0px);
        align-items: center;
        justify-content: space-between;
        max-height: 80px;
        padding: 1.25rem 2.5rem;
    }

    nav {
        display: flex;
    }

    .app-logo-manufacturer {
        display: flex;
        justify-content: start;
        align-items: start;
    }

    .app-logo-manufacturer > * {
        margin: 20px 10px;
    }

    .app-logo-manufacturer :deep(.v-divider--vertical) {
        opacity: 1;
    }

    .app-logo-manufacturer img {
        position: relative;
        top: 4px;
    }
</style>
