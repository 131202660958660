import type { ColorRepresentation } from 'three';

import type { PlannerObjectId } from '@/planner/scene/plannerObjectId';

/** Bone-color; used for bones. */
const BONE_COLOR = '#b4aa91';
/** Bone-color when in xray-mode; used for bones. */
const XRAY_BONE_COLOR = '#ecdfc2';
/** Purple color used on metal 'bones' */
const METAL_COLOR = '#8872a1';
/** Purple color used on metal 'bones' when in x-ray mode*/
const XRAY_METAL_COLOR = '#d4bcff';
/** Femoral inner-cortical-surface color */
const INNER_CORTICAL_SURFACE_COLOR = '#cdcdcd';
/** Femoral inner-cortical-surface color when in x-ray mode */
const XRAY_INNER_CORTICAL_SURFACE_COLOR = '#ffffff';
/** Default implant color. */
const IMPLANT_COLOR = '#ffffff';
/** Default implant color when in xray-mode. */
const XRAY_IMPLANT_COLOR = '#ffffff';
/** Coverage-mask color. */
const COVERAGE_COLOR = '#cf4747';
/** Coverage-mask color. */
const XRAY_COVERAGE_COLOR = '#f81e1e';
/** Stem color when in xray-mode. */
const STEM_COLOR = '#7cabf5';
/** Stem color when in xray-mode. */
const XRAY_STEM_COLOR = '#7cabf5';
/** Bearing color when in xray-mode. */
const BEARING_COLOR = '#c0c0c0';
/** Bearing color when in xray-mode. */
const XRAY_BEARING_COLOR = '#c0c0c0';

export function normalMeshColor(id: PlannerObjectId): ColorRepresentation {
    switch (id) {
        case 'operative-femur-internal':
            return INNER_CORTICAL_SURFACE_COLOR;
        case 'metal':
            return METAL_COLOR;
        case 'stem':
            return STEM_COLOR;
        case 'bearing':
            return BEARING_COLOR;
        case 'cup':
        case 'liner':
        case 'head':
            return IMPLANT_COLOR;
        case 'cup-coverage':
        case 'cup-collision-surface':
            return COVERAGE_COLOR;
        default:
            return BONE_COLOR;
    }
}

export function xrayMeshColor(id: PlannerObjectId): ColorRepresentation {
    switch (id) {
        case 'operative-femur-internal':
            return XRAY_INNER_CORTICAL_SURFACE_COLOR;
        case 'metal':
            return XRAY_METAL_COLOR;
        case 'stem':
            return XRAY_STEM_COLOR;
        case 'bearing':
            return XRAY_BEARING_COLOR;
        case 'cup':
        case 'liner':
        case 'head':
            return XRAY_IMPLANT_COLOR;
        case 'cup-coverage':
        case 'cup-collision-surface':
            return XRAY_COVERAGE_COLOR;
        default:
            return XRAY_BONE_COLOR;
    }
}

