import type { PlannerState } from '@/planner/plannerState';
import { assert, stop, stopAll, type StopHandle, taggedLogger } from '@/util';
import { watchImmediate } from '@vueuse/core';
import { useCupOverlayStore } from '@/planner/cupOverlayStore';
import { type CollisionCoverageResults, FaceAreaMetric } from '@/planner/cupCoverage/types';
import { BufferGeometry, DoubleSide, Float32BufferAttribute, Mesh, MeshBasicMaterial } from 'three';
import { flatten, repeat } from 'ramda';
import { useCupCoverageStore } from '@/planner/cupCoverage/cupCoverageStore';
import { AbortCalculationError } from '@/planner/cupCoverage/abortCalculationError';

const log = taggedLogger('cup-coverage');

export function updateCupCoverage(state: PlannerState): StopHandle {
    let calculation: StopHandle | null = null;
    const overlayStore = useCupOverlayStore();
    let nextId = 1;

    const calculate: () => Promise<void> = async () => {
        const id = nextId++;
        log.info('Begin calculation %d', id);

        // Set the handle for stopping this calculation
        const aborter = new AbortController();
        calculation = () => aborter.abort(new AbortCalculationError());

        await calculateCoverage(id, state, aborter);
    };

    return stopAll(
        watchImmediate(
            () => [state.template?.cupUrl, state.template?.cupOffset, state.template?.cupRotation],
            async () => {
                // Stop the previous calculation
                stop(calculation);
                state.scene.cupCoverage.geometrySource = null;
                overlayStore.coverage = 'calculating';
                await calculate();
            },
            { deep: true },
        ),
        () => stop(calculation),
    );
}

async function calculateCoverage(
    id: number,
    state: PlannerState,
    aborter: AbortController,
): Promise<void> {
    if (!state.template) {
        return;
    }

    state.scene.cupCoverage.geometrySource = null;

    const catalogCup = state.catalog?.cups.get(state.template.cupUrl);
    assert(catalogCup);

    // const geometry = await loadGeometry(catalogCup.collisionMask)
    // state.scene.cupCollisionSurface.geometry = geometry
    // TODO: Jamie to fix loading geometry
    // TODO: Jamie to fix loading geometry
    // TODO: Jamie to fix loading geometry
    // TODO: Jamie to fix loading geometry
    // TODO: Jamie to fix loading geometry
    // TODO: Jamie to fix loading geometry
    state.scene.cupCollisionSurface.geometrySource = catalogCup.collisionMask;

    setTimeout(() => {
        useCupCoverageStore().calculate(aborter, state.scene);
    }, 5000);
}

/**
 * Make the coverage mesh for visualization purposes using the results of the coverage calculation.
 */
export function makeCoverageMesh(calculatedCoverage: CollisionCoverageResults): Mesh {
    const geometry = makeCoverageGeometry(calculatedCoverage.faceAreaMetrics);
    const material = new MeshBasicMaterial({
        // Defines whether vertex coloring is used
        vertexColors: true,
        transparent: true,
        side: DoubleSide,
    });
    return new Mesh(geometry, material);
}

// Color of a covered face
const INSIDE_FACE_COLOR = [1, 0, 0, 0.7];

/**
 * Make a non indexed buffer geometry using the results of the coverage calculation.
 *
 * Note:
 * 1. The Buffer Geometry is a non-indexed to make it setting the position/color buffer simpler.
 * 2. The implementation is not intended to be performant at this stage. If iterating the faces twice
 * to generate the position and color buffer has a penalty, it can be changed to an accumulation approach
 * instead of a `map`.
 *
 * @param faceAreaMetrics the metrics of the coverage calculation process.
 */
function makeCoverageGeometry(faceAreaMetrics: FaceAreaMetric[]): BufferGeometry {
    const geometry = new BufferGeometry()
        .setAttribute(
            'position',
            new Float32BufferAttribute(
                faceAreaMetrics.flatMap((metric): number[] =>
                    metric.intersection.inside ? metric.positions.flatMap((p) => p.toArray()) : [],
                ),
                3,
            ),
        )
        .setAttribute(
            'color',
            new Float32BufferAttribute(
                flatten(repeat(INSIDE_FACE_COLOR, faceAreaMetrics.length)),
                4,
            ),
        );
    geometry.computeBoundingSphere();
    geometry.computeVertexNormals();

    return geometry;
}
