import _ from 'lodash';
import { splitEvery } from 'ramda';
import { logger } from '@/util';

const log = logger('cup-coverage');

/**
 * A type to describe the metrics of running the utility
 */
export type DoInChunkRunMetric = {
    /** The total number of cycles the that the function run */
    numberOfCycles: number,
    /** The total length of the array to be iterated */
    totalLength: number,
}

export default class AsyncIterationUtil {
    /**
     * Allows to perform an operation in chunks so the CPU is released every certain time.
     *
     * Strategy:
     * 1. The `list` of items is split into chunks of size `N`.
     * 2. a. The `accumulate` function is invoked sequentially for each chunk.
     *    b. After each chunk is executed, a `setTimeout` forces to wait until
     *    the program call stack has cleared, allowing other operations to use the CPU.
     *
     * Note: The utility follows an "accumulation" approach given the uses of the current code.
     *       A 'map' style function could be written.
     *
     * @param list The list to be iterated.
     * @param chunkSize The size of the chunk to be evaluated on each iteration.
     *   After N items, the next iteration is scheduled on the next tick.
     * @param accumulate The callback where results can be accumulated.
     * @param result A factory function for the result value.
     *
     */
    public static async doInChunks<T, R>(
        list: T[],
        chunkSize: number,
        accumulate: (item: T) => void,
        result: () => R): Promise<[R, DoInChunkRunMetric]> {
        let totalCycles = 0;

        const internalDoInChunk = async (
            resolve: (value: [R, DoInChunkRunMetric]) => void, reject: (reason?: any) => void) => {
            try {
                const chunks = splitEvery(chunkSize, list);
                const chunkLength = chunks.length;

                // Using simple for/loop instead of array.forEach(), given the intent is to sequentially execute the
                // different promises, and forEach() will not serve that purpose
                // Gotchas:
                // forEach does not work: {@link https://stackoverflow.com/a/37576787}
                // sequentially await promises: {@link https://stackoverflow.com/a/46086037}
                for (const [chunkIndex, chunk] of chunks.entries()) {
                    ++totalCycles;
                    await AsyncIterationUtil.runAndDefer(() => {
                        const isLastChunk = chunkIndex === chunkLength - 1;
                        chunk.forEach(accumulate);

                        if (isLastChunk) {
                            resolve([result(), { numberOfCycles: totalCycles, totalLength: list.length }]);
                        } else {
                            // nothing to do, keep looping.
                        }
                    });
                }
            } catch (err) {
                log.debug('Error while iterating in chunks. Only %d cycles executed. %s', totalCycles, err);
                reject(err);
            }
        };

        return new Promise<[R, DoInChunkRunMetric]>((resolve, reject) => {
            internalDoInChunk(resolve, reject);
        });
    }

    /**
     * Run a callback, and delay resolving the promise until the current call stack has cleared.
     *
     * Note: The callback is intentionally run outside the _.defer().
     *       To handle errors, `_.defer` (a simple `setTimeout()` function),
     *       requires a `try-catch` inside the body of the function,
     *       and doing that will add coupling between this utility and the caller.
     * @see https://stackoverflow.com/a/41431714
     */
    private static runAndDefer(callback: () => void): Promise<void> {
        return new Promise((resolve) => {
            callback();

            // The callback is intentionally run outside the _.defer().
            _.defer(() => resolve());
        });
    }
}
