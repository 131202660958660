import { asyncTimeout, type StopHandle, taggedLogger, stop, stopAll, radToDeg } from '@/util';
import { computed, reactive, ref, watch } from 'vue';
import type { TemplateId } from '@/formus/template/template';
import type { Url } from '@/formus/types';
import { putTemplate, type TemplateUpdate } from '@/api/template/putTemplate';
import type { ManualTemplateState } from '@/planner/template/manualTemplateState';
import { defineStore } from 'pinia';
import { asyncWatchUntil } from '@/util/asyncWatchUntil';
import { useAppErrorStore } from '@/stores/appErrorStore';
import type { Adjustments } from '@/planner/plannerState';

const log = taggedLogger('template-sync');

/**
 * Category for a failure state for the synchroniser
 *
 * - *merge-error* indicates that a conflict has been detected between the UI-state and the template on the API.
 *   This is typically caused by an update by another tab or another user.
 * - *get-failed* indicates an attempt to GET the surgical-template from the server resulted in an error
 * - *get-timed-out* indicates an attempt to GET a complete surgical-template from the server timed out before the
 *   surgical-template was complete
 * - *put-failed* indicates an attempt to PUT the surgical-template to the server resulted in an error
 */
export type TemplateSyncErrorType = 'merge-error' | 'get-failed' | 'get-timed-out' | 'put-failed'

/** Failure-state for the synchroniser */
export type TemplateSyncError = {
    /** The type of error that occurred */
    type: TemplateSyncErrorType

    /** The thrown error that caused the failure */
    error?: unknown
}

/**
 * A pending update for the surgical-template
 */
export type TemplateSyncUpdate = {
    /** Identifier for the update: used for logging */
    updateId: number;

    /** The time the update is scheduled for */
    scheduledTime: number;

    /** The template that should be applied */
    template: TemplateUpdate;
}

/** Time in milliseconds to debounce an update */
const UPDATE_TIMEOUT = 3000;

export const useTemplateSyncStore = defineStore('template-sync', () => {
    let syncing: StopHandle | null = null;
    const appError = useAppErrorStore();

    const _queuedUpdate = ref<TemplateSyncUpdate | null>(null);

    const _state = reactive<TemplateSyncState>({
        nextUpdateId: 1,
        isSaving: false,
        forceUpdate: false,
        error: null,
    });

    async function run(templateId: TemplateId | Url, signal: AbortSignal): Promise<void> {
        while (!signal.aborted) {
            try {
                const now = Date.now();

                if (_queuedUpdate.value) {
                    const { updateId, template, scheduledTime } = _queuedUpdate.value;
                    // There is a queued update, so check if it should be applied
                    if (now >= scheduledTime || _state.forceUpdate) {
                        // The scheduled-time for the update has elapsed, so apply it
                        _queuedUpdate.value = null;
                        log.info('Putting update %d', updateId);
                        _state.isSaving = true;
                        await putTemplate(templateId, template, { signal });
                        log.info('Put update %d', updateId);
                        _state.isSaving = false;
                    } else {
                        // Wait for the scheduled update time
                        const waitTime = scheduledTime - now;
                        log.info('Waiting %d ms to put queued update %d...', waitTime, updateId);
                        await waitForTimeoutOrChange(waitTime, () => _state.forceUpdate, signal);
                    }
                } else {
                    log.info('No queued updates, waiting...');
                    await asyncWatchUntil(() => _queuedUpdate.value !== null, { signal: signal });
                }
            } catch (error) {
                if (signal.aborted) {
                    return;
                } else {
                    throw error;
                }
            }
        }
    }

    return {
        _state,
        _queuedUpdate,
        /**
         * True if there is an update that is queued or currently being saved
         */
        hasUpdate: computed(() => _queuedUpdate.value !== null || _state.isSaving),
        isSaving: computed(() => _state.isSaving),
        hasError: computed(() => _state.error !== null),
        startSync: (templateId: TemplateId | Url, template: ManualTemplateState): void => {
            stop(syncing);
            const aborter = new AbortController();

            syncing = stopAll(
                // Watch the manual template for changes and queue an update when it changes
                watch(
                    template,
                    (template) => {
                        const updateId = _state.nextUpdateId++;
                        _queuedUpdate.value = {
                            updateId,
                            scheduledTime: Date.now() + UPDATE_TIMEOUT,
                            template: templateUpdate(template),
                        };
                        log.info('Queueing update %d', updateId);
                    },
                    { deep: true },
                ),
                // When we stop also signal the abort
                () => aborter.abort(),
            );
            appError.catchErrors(() => run(templateId, aborter.signal));
        },
        stopSync: () => stop(syncing),
    };
});

/**
 * State of the synchroniser, as used by the store
 */
export type TemplateSyncState = {
    /** The identifier that will be used for the next update */
    nextUpdateId: number;

    /** Indicates that we are either pushing an update or re-fetching directly afterwards */
    isSaving: boolean;

    /** Indicates that we should push a queued update as-soon-as-possible */
    forceUpdate: boolean;

    error: TemplateSyncError | null;
}

/**
 * Create a template-update from a manual-template-state and, optionally, a new set of targets.
 *
 * Note that if new targets are used in an update pushed to the API the fitted-components will change and
 * need to be reloaded.
 */
export function templateUpdate(template: ManualTemplateState, targets?: Adjustments): TemplateUpdate {
    return {
        cup: template.cupUrl,
        liner: template.linerUrl,
        stem: template.stemUrl,
        head: template.headUrl,
        targetLegLengthChange: targets?.legLength ?? null,
        targetOffsetChange: targets?.offset ?? null,
        cupOffset: { ...template.cupOffset },
        cupRotation: {
            anteversion: radToDeg(template.cupRotation.anteversion),
            inclination: radToDeg(template.cupRotation.inclination),
        },
        dualMobility: template.dualMobility,
        stemTransform: template.stemTransform.clone(),
    };
}

async function waitForTimeoutOrChange(
    delay: number,
    change: () => boolean,
    signal: AbortSignal,
): Promise<void> {
    const changed = asyncWatchUntil(change, { signal });
    const timeout = asyncTimeout(delay, signal);
    await Promise.race([changed, timeout]);
}

