export enum StemSystemEnum {
    AvenirComplete = 'Avenir Complete',
    TaperlocComplete = 'Taperloc Complete',
}

export const stemSystems = [
    {
        key: 'avenir-complete',
        label: StemSystemEnum.AvenirComplete,
    },
    {
        key: 'taperloc-complete',
        label: StemSystemEnum.TaperlocComplete,
    },
]
