<template>
    <div class="app-input-group">
        <group-label>Stem Translation</group-label>
        <plus-minus-input minus="Medial"
                          plus="Lateral"
                          @minus-clicked="onClick(
                              'stem_translated',
                              'Medial',
                              () => store.transformStem({ translateML: -1 })
                              )"
                          @plus-clicked="onClick(
                              'stem_translated',
                              'Lateral',
                              () => store.transformStem({ translateML: +1 })
                              )"
        />
        <plus-minus-input minus="Posterior"
                          plus="Anterior"
                          @minus-clicked="onClick(
                              'stem_translated',
                              'Posterior',
                              () => store.transformStem({ translateAP: +1 })
                              )"
                          @plus-clicked="onClick(
                              'stem_translated',
                              'Anterior',
                              () => store.transformStem({ translateAP: -1 })
                              )"
        />
        <plus-minus-input minus="Inferior"
                          plus="Superior"
                          @minus-clicked="onClick(
                              'stem_translated',
                              'Inferior',
                              () => store.transformStem({ translateSI: +1 })
                              )"
                          @plus-clicked="onClick(
                              'stem_translated',
                              'Superior',
                              () => store.transformStem({ translateSI: -1 })
                              )"
        />
    </div>

    <div class="app-input-group">
        <group-label>Stem Rotation</group-label>
        <plus-minus-input minus="Retroversion"
                          plus="Anteversion"
                          @minus-clicked="onClick(
                              'stem_rotated',
                              'Retroversion',
                              () => store.transformStem({ rotateRA: -1 })
                              )"
                          @plus-clicked="onClick(
                              'stem_rotated',
                              'Anteversion',
                              () => store.transformStem({ rotateRA: +1 })
                              )"
        />
        <plus-minus-input minus="Extension"
                          plus="Flexion"
                          @minus-clicked="onClick(
                              'stem_rotated',
                              'Extension',
                              () => store.transformStem({ rotateEF: -1 })
                              )"
                          @plus-clicked="onClick(
                              'stem_rotated',
                              'Flexion',
                              () => store.transformStem({ rotateEF: +1 })
                              )"
        />
        <plus-minus-input minus="Valgus"
                          plus="Varus"
                          @minus-clicked="onClick(
                              'stem_rotated',
                              'Valgus',
                              () => store.transformStem({ rotateVV: -1 })
                              );"
                          @plus-clicked="onClick(
                              'stem_rotated',
                              'Varus',
                              () => store.transformStem({ rotateVV: +1 })
                              )"
        />
    </div>
</template>

<style scoped>
    .app-input-group {
        margin-bottom: 20px;
    }
</style>

<script lang="ts" setup>
    import GroupLabel from '@/planner/components/GroupLabel.vue';
    import PlusMinusInput from '@/planner/components/PlusMinusInput.vue';
    import { useStemOverlayStore } from '@/planner/stemOverlayStore';
    import { trackEvent } from '@/plugins/amplitude';
    import { caseEventProperties } from '@/plugins/amplitudeEvent';
    import { usePlannerStore } from '@/planner/plannerStore';

    const store = useStemOverlayStore()
    const planner = usePlannerStore();

    const onClick = async (event: "stem_rotated" | "stem_translated", label: string, callback: Function) => {
        const eventProperties: {[key: string]: any} = {
            ...caseEventProperties({
                projectId: planner.caseId?.toString(),
            })
        };
        if (event === "stem_rotated") {
            eventProperties.rotation_direction = label;
        } else if (event === "stem_translated") {
            eventProperties.translation_direction = label;
        }
        await trackEvent(event, eventProperties);
        callback();
    }
</script>
