import { Mesh } from 'three';
import type { PlannerState } from '@/planner/plannerState';
import { stopAll, type StopHandle } from '@/util';
import { watchImmediate } from '@vueuse/core';
import { makeMaterial, type MaterialParams } from '@/planner/3d/materials/makeMaterial';

/**
 * State for a material to be applied to mesh objects in the scene
 */
export type MeshMaterialParams = {
    /** Optional name to identify materials */
    name?: string;

    /** Parameters for the 'normal' material mode */
    normalParams?: Partial<MaterialParams>;

    /** Parameters for the 'xray' material mode */
    xrayParams?: Partial<MaterialParams>;
};

export function updateMeshMaterial(
    state: PlannerState,
    mesh: Mesh,
    params: MeshMaterialParams,
): StopHandle {
    const normalMaterial = makeMaterial({
        type: 'standard',
        ...params.normalParams,
    });
    const xrayMaterial = makeMaterial({
        type: 'xray',
        ...params.xrayParams,
    });

    return stopAll(
        watchImmediate(
            () => params.name,
            (name) => {
                if (name) {
                    normalMaterial.name = `${name}-normal`;
                    xrayMaterial.name = `${name}-xray`;
                } else {
                    normalMaterial.name = 'normal';
                    xrayMaterial.name = 'xray';
                }
            },
        ),
        watchImmediate(
            () => state.renderingMode,
            (mode) => (mesh.material = mode === 'normal' ? normalMaterial : xrayMaterial),
        ),
    );
}
