import { watchEffect } from 'vue';
import { assert, type LogFunction, stopAll, type StopHandle } from '@/util';
import { useDebounceFn, watchImmediate } from '@vueuse/core';
import type { BodySide } from '@/formus/anatomy/side';
import type { AcetabularAngles } from '@/formus/anatomy/pelvis/acetabularAngles';
import { Matrix4, type Vector3 } from 'three';
import { cross } from '@/geometry/vector3';
import { isRigidMatrix, relativeTransform } from '@/geometry/matrix';
import { formatFloat, formatRadians, formatRadiansAsDegrees, formatVector } from '@/geometry/formatMath';
import type { AnatomicalOffset } from '@/formus/anatomy/pelvis/anatomicalOffset';
import { updateCupCoverage } from '@/planner/cupCoverage/updateCupCoverage';
import type { PlannerStore } from '@/planner/plannerStore';
import { logValidation } from '@/planner/logValidation';
import { cupWorldOffsetFromApi, type FittedCup } from '@/planner/fittedCup';
import { computeLinerUrl } from '@/planner/componentUrls';

export function updateCup(store: PlannerStore): StopHandle {
    return stopAll(
        watchImmediate(
            () => store.template?.cupUrl ?? null,
            (url) => (store.scene.cup.geometrySource = url),
        ),
        watchImmediate(
            () => store.template && store.catalog ? computeLinerUrl(store) : null,
            (url) => (store.scene.liner.geometrySource = url),
        ),
        watchEffect(() => updateCupComponents(store)),
        watchEffect(() => updateCupTransform(store)),
        updateCupCoverage(store),
    );
}

function updateCupComponents(store: PlannerStore): void {
    if (!store.fittedCup) {
        return;
    }
    const cupTransform = store.fittedCup.cupTransform;
    const linerTransform = store.fittedCup.linerTransform;
    const fittedHJC = store.fittedCup.fittedHjc;

    // We use the implied fitted cup-group transform here in order to calculate the transform
    // of the cup and liner meshes relative to the cup-group. The final cup-group transform will
    // be calculated and based on the cup position and angle values.
    const cupGroupTransform = cupTransform.clone().setPosition(fittedHJC);
    store.scene.cupGroup.transform.copy(cupGroupTransform);
    store.scene.cup.transform.copy(relativeTransform(cupGroupTransform, cupTransform));
    store.scene.cupCoverage.transform.copy(relativeTransform(cupGroupTransform, cupTransform));
    store.scene.cupCollisionSurface.transform.copy(relativeTransform(cupGroupTransform, cupTransform));
    store.scene.liner.transform.copy(relativeTransform(cupGroupTransform, linerTransform));
}

function updateCupTransform(store: PlannerStore): StopHandle {
    const logRotation = useDebounceFn(logValidation, 500, { maxWait: 1000 });
    const logPosition = useDebounceFn(logValidation, 500, { maxWait: 1000 });

    return watchEffect(() => {
        if (!store.fittedCup || !store.template || !store.operationalSide) {
            return;
        }
        const rotation = calculateCupRotation(
            store.operationalSide, store.fittedCup, store.template.cupRotation, logRotation);
        const position = calculateCupPosition(store.fittedCup, store.template.cupOffset, logPosition);

        // Set the position of the cupGroup
        store.scene.cupGroup.transform.copy(rotation).setPosition(position);
    });
}

function calculateCupPosition(
    fittedCup: FittedCup,
    offset: AnatomicalOffset,
    log: LogFunction,
): Vector3 {
    const nativeHjc = fittedCup.basis.position.clone();
    const cupPosition = cupWorldOffsetFromApi(fittedCup, offset).add(nativeHjc);
    log([
        'Cup position:',
        `  native hjc: ${formatVector(nativeHjc)}`,
        `  offsets: si: ${formatFloat(offset.si)}  ap: ${formatFloat(offset.ap)}  ml: ${formatFloat(offset.ml)}`,
        '  anatomical basis:',
        `    si: ${formatVector(fittedCup.basis.inferior)}`,
        `    ap: ${formatVector(fittedCup.basis.posterior)}`,
        `    ml: ${formatVector(fittedCup.basis.lateral)}`,
        `  cup-position: ${formatVector(cupPosition)}`,
    ].join('\n'));

    return cupPosition;
}

function calculateCupRotation(
    side: BodySide,
    fittedCup: FittedCup,
    angles: AcetabularAngles<'anatomic'>,
    log: LogFunction,
): Matrix4 {
    let { anteversion, inclination } = angles;
    if (side === 'right') {
        anteversion = -anteversion;
        inclination = -inclination;
    }

    const { posterior, inferior } = fittedCup.basis;
    const anterior = posterior.clone().negate();

    // In the compute code the transformation is implicit in the calculation of the normal, x and y vectors of
    // the 'cup plane', so those variable names are retained here.
    const normal = inferior.clone()
        .applyAxisAngle(anterior, inclination)
        .applyAxisAngle(inferior, anteversion)
        .normalize()
        .negate();
    const x = cross(anterior, normal).normalize();
    const y = cross(normal, x).normalize();

    // This is the mapping from a 'plane' to a transform matrix that is applied in this python function:
    // acid.lib.atlas.acetabularcup.acetabular_cup.AcetabularCupAtlas.transform_2_plane
    const rotation = new Matrix4().makeBasis(x.negate(), normal, y);
    assert(isRigidMatrix(rotation), 'Rotation is not a rigid transform');

    log([
        'Cup rotation:',
        `  anteversion: ${formatRadians(anteversion)} (${formatRadiansAsDegrees(anteversion)})`,
        `  inclination: ${formatRadians(inclination)} (${formatRadiansAsDegrees(inclination)})`,
        '  anatomical basis:',
        `    si: ${formatVector(inferior)}`,
        `    ap: ${formatVector(posterior)}`,
        `  cup-normal: ${formatVector(normal)}`,
        `  cup-x: ${formatVector(x)}`,
        `  cup-y: ${formatVector(y)}`,
    ].join('\n'));

    return rotation;
}
