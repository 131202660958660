import type { ApiLinks } from '@/api/links';
import type { Url } from '@/formus/types';
import { type AxiosResponse, HttpStatusCode } from 'axios';
import { client } from '@/api/http';
import { errorDetail } from '@/planner/api/errorDetail';
import type { CupAnteversionMode, CupFitMethod, StemSelector } from '@/formus/specification';
import type { AlignmentMode } from '@/formus/anatomy/pelvis/alignment';
import { getPreferences } from '@/planner/api/preferences';
import { type ConvertedRepresentation, convertRepresentation } from '@/planner/api/convertRepresentation';
import { alignmentMode, type ApiAlignmentMode } from '@/planner/api/alignmentMode';

export type PreferredSystem = 'taperloc-complete' | 'avenir-complete';

export type SpecificationProperties = {
    created: string;
    updated: string;
    stem: {
        selector: StemSelector[];
    };
    cup: {
        fit_method: CupFitMethod;
        abduction_angle: number;
        anteversion_mode: CupAnteversionMode | null;
        anteversion_angle: number | null;
    };
    target: {
        leg_length_change: number;
        offset_change: number;
    };
    alignment_mode: AlignmentMode | null;
    preferred_system: PreferredSystem;
};

const specificationLinkMap = {
    self: 'self',
    case: 'up',
    canonical: 'canonical',
    previous: { rel: 'previous', optional: true },
    preferences: 'preferences',
    history: 'history',
} as const;

/** Identifies a surgical-specification on the API */
export type ApiSpecificationId = { case: number; specification: number };

export type ApiSpecification = ConvertedRepresentation<
    SpecificationProperties,
    typeof specificationLinkMap
> & {
    id: ApiSpecificationId;
};

type ApiSpecificationResponse = SpecificationProperties & ApiLinks & { alignment_mode: ApiAlignmentMode };

export async function getSpecification(
    specId: ApiSpecificationId | Url,
): Promise<ApiSpecification> {
    let id, url;
    if (typeof specId === 'object') {
        id = specId;
        url = `project/${id.case}/hip/surgical/specification/${id.specification}`;
    } else {
        url = specId;
        id = specificationIdFromUrl(url);
    }

    const { status, data }: AxiosResponse<ApiSpecificationResponse> = await client.get(url);
    if (status === HttpStatusCode.Ok) {
        return {
            id,
            ...convertRepresentation(data, specificationLinkMap),
            alignmentMode: data.alignment_mode ? alignmentMode(data.alignment_mode) : null,
        };
    }

    throw Error(`Failed to load study from ${url}` + errorDetail(data));
}

export function specificationIdFromUrl(url: Url): ApiSpecificationId {
    const match = url.match(/project\/([0-9]*)\/hip\/surgical\/specification\/([0-9]*)/);
    if (match) {
        return {
            case: Number(match[1]),
            specification: Number(match[2]),
        };
    }
    throw Error(`Failed to extract specification-id from url '${url}'`);
}

export type FullSpecificationProperties = SpecificationProperties & {
    cup: {
        fit_method: CupFitMethod;
        abduction_angle: number;
        anteversion_mode: CupAnteversionMode;
        anteversion_angle: number;
    };
    target: {
        leg_length_change: number;
        offset_change: number;
    };
    alignment_mode: AlignmentMode;
};

/**
 * A surgical-specification that has any 'default' (null) properties filled from the associated surgical-preferences
 */
export type ApiCompleteSpecification = ConvertedRepresentation<
    FullSpecificationProperties,
    typeof specificationLinkMap
> & { id: ApiSpecificationId };

export async function getCompleteSpecification(
    specId: ApiSpecificationId | Url,
): Promise<ApiCompleteSpecification> {
    const specification = await getSpecification(specId);
    const preferences = await getPreferences(specification.preferences);
    return {
        ...specification,
        cup: {
            fit_method: specification.cup.fit_method ?? preferences.cup.fit_method,
            abduction_angle: specification.cup.abduction_angle ?? preferences.cup.abduction_angle,
            anteversion_mode:
                specification.cup.anteversion_mode ?? preferences.cup.anteversion_mode,
            anteversion_angle:
                specification.cup.anteversion_angle ?? preferences.cup.anteversion_angle,
        },
        alignmentMode: specification.alignmentMode ?? preferences.alignMode ?? 'CT',
        preferredSystem: preferences.preferredSystem,
    };
}
