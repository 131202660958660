import { Vector3 } from 'three';
import type { ApiBoundingBox, ApiCaseCatStack, ApiCaseCatStacks, XYZ, } from '@/api/cat-stacks/types';
import type {
    CatstackAxes,
    CatstackAxis,
    CatstackCuboidPoints,
    CatstackSidesCenterPoints,
} from '@/planner/cat-stack/types';
import type { CatstackSliderState } from '@/planner/plannerState';

/**
 * Create the catstack cuboid object, to be used as a reference for setting up each of the orthogonal planes
 */
export function makeCatstackRegionObject(
    axialImage: ApiCaseCatStack,
    boundingBox: ApiBoundingBox,
): CatstackCuboidPoints {
    // We use the axial slice, since CT scanners scan along the axial (Z) axis

    const ctBboxMin = boundingBox.min;
    const ctBboxMax = boundingBox.max;

    //
    //   Below is a representation of typical DICOM coordinate system
    //
    //
    //                            Anterior - Coronal (min Y)
    //               Z                     |
    //          O--------->                |  Right - Sagittal (min X)
    //         /|                          | /
    //      X / | Y                        |/
    //       /  |         Inferior --------/-------- Superior - Axial (max Z)
    //      /   |       Axial (min Z)     /|
    //                                   / |
    //                                  /  |
    //                          Left   /   Posterior - Coronal (max Y)
    //                  Sagittal (max X)
    //
    //
    //   Visual representation of the cuboid points derived from a CT scan
    //
    //        p0                         p4
    //          o-------------------------o
    //         /|                        /|
    //     p3 / |                    p7 / |
    //       o--|----------------------o  |
    //       |  o----------------------|--o p5
    //       | / p1                    | /
    //       |/                        |/
    //       o-------------------------o
    //     p2                         p6
    //
    // Orthogonal side to Points reference:
    //
    //   - Axial
    //       - Inferior: p0, p1, p2, p3
    //       - Superior: p4, p5, p6, p7
    //   - Coronal
    //       - Anterior: p0, p3, p7, p4
    //       - Posterior: p1, p2, p6, p5
    //   - Sagittal
    //       - Right: p0, p1, p5, p4
    //       - Left: p3, p2, p6, p7
    //
    return _makeCuboidPoints(axialImage, ctBboxMin, ctBboxMax);
}

/**
 *  Create an updated set of axes that match the scene world position and rotation of the segmented models
 */
export function setupCatstackAxes(cuboidPoints: CatstackCuboidPoints): CatstackAxes {
    const centerPoints = _setupCatstackSidesCenterPoints(cuboidPoints);

    // TODO: Castack Jamie check if there is some built in already
    const _direction = (fromPoint: Vector3, toPoint: Vector3): Vector3 => {
        return fromPoint.clone().sub(toPoint).normalize();
    };

    function _makeAxis(points: { p0: Vector3; p1: Vector3 }): CatstackAxis {
        return {
            direction: _direction(points.p0.clone(), points.p1.clone()),
            distance: points.p0.clone().distanceTo(points.p1.clone()),
            p0: points.p0.clone(),
            p1: points.p1.clone(),
        };
    }

    return {
        axial: _makeAxis(centerPoints.axial),
        coronal: _makeAxis(centerPoints.coronal),
        sagittal: _makeAxis(centerPoints.sagittal),
    };
}

export function makeSliders(data: ApiCaseCatStacks): CatstackSliderState {
    return {
        axial: {
            min: 0,
            max: data.axial.count - 1,
            value: 0, // initialise the axial slice to the most inferior
        },
        coronal: {
            min: 0,
            max: data.coronal.count - 1,
            value: data.coronal.count - 1, // initialise coronal slice at most posterior
        },
    };
}

/**
 * Make all the vertices for the cuboid geometry
 * // TODO: Catstack Jamie. Move to where it correspond
 */
function _getCenterPoint(min: Vector3, max: Vector3): Vector3 {
    const center = new Vector3();

    center.x = (max.x + min.x) / 2;
    center.y = (max.y + min.y) / 2;
    center.z = (max.z + min.z) / 2;

    return center;
}

/**
 * Setup the center points for each of the orthogonal view sides
 *
 * For each side (eg. axial) we have a p0 and p1 points.
 *
 * E.g.: using the axial view
 * p0 will be the center point of the Anterior side, and
 * p1 will be the center point of the Posterior side.
 *
 * We will later use these 2 points (p0 and p1) to create a directional axis for each view,
 * which the catstack plane will be moved along.
 */
export function _setupCatstackSidesCenterPoints(
    cuboidPoints: CatstackCuboidPoints,
): CatstackSidesCenterPoints {
    return {
        axial: {
            p0: _getCenterPoint(cuboidPoints.p0, cuboidPoints.p2),
            p1: _getCenterPoint(cuboidPoints.p4, cuboidPoints.p6),
        },
        coronal: {
            p0: _getCenterPoint(cuboidPoints.p0, cuboidPoints.p7),
            p1: _getCenterPoint(cuboidPoints.p1, cuboidPoints.p6),
        },
        sagittal: {
            p0: _getCenterPoint(cuboidPoints.p1, cuboidPoints.p4),
            p1: _getCenterPoint(cuboidPoints.p2, cuboidPoints.p7),
        },
    };
}

/**
 * Create face geometries using 3 vertices for each face
 */
/**
 * Make cuboid points
 */
function _makeCuboidPoints(axial: ApiCaseCatStack, min: XYZ, max: XYZ): CatstackCuboidPoints {
    const world = axial.world;
    return {
        // min X, min Y, min Z
        p0: new Vector3(min.x - world.width, min.y - world.height, min.z),
        // min X, max Y, min Z

        p1: new Vector3(min.x - world.width, min.y, min.z),
        // max X, max Y, min Z

        p2: new Vector3(min.x, min.y, min.z),
        // max X, min Y, min Z

        p3: new Vector3(min.x, min.y - world.height, min.z),

        // min X, min Y, max Z
        p4: new Vector3(max.x, max.y, max.z),

        // min X, max Y, max Z
        p5: new Vector3(max.x, max.y + world.height, max.z),

        // max X, max Y, max Z
        p6: new Vector3(max.x + world.width, max.y + world.height, max.z),

        // max X, min Y, max Z
        p7: new Vector3(max.x + world.width, max.y, max.z),
    };
}
