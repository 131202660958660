import { asyncTimeout, taggedLogger } from '@/util';
import type { Url } from '@/formus/types';
import type { AxiosRequestConfig } from 'axios';
import { getTemplate } from '@/api/template/getTemplate';
import type { Template, TemplateId } from '@/formus/template/template';

const log = taggedLogger('poll-template');

const DEFAULT_POLL_TIMEOUT_MILLISECONDS = 60 * 1000 * 5; /** 5 minutes */
const POLL_INTERVAL_MILLISECONDS = 1000 as const; /** Poll every second */
const LOG_ATTEMPT_AT_INFO = 5 as const;  /** Log every 5th attempt at info level */

export class TimeoutError extends Error {
    constructor(message?: string) {
        super(message);
        Object.setPrototypeOf(this, TimeoutError.prototype);
    }
}

/**
 * Repeatedly fetch a template until a condition is met or a timeout reached
 *
 * @throws TimeoutError if the timeout elapses.
 */
export async function pollTemplate(
    templateId: TemplateId | Url,
    condition: (template: Template) => boolean,
    options?: {
        signal?: AbortSignal,
        timeout?: number,
    },
): Promise<Template> {
    // Note: we were previously checking whether the case had a valid template link in this function
    // We may have to do that again

    const config: AxiosRequestConfig = { signal: options?.signal };

    // Calculate the time we need to time-out
    const timeout = options?.timeout ?? DEFAULT_POLL_TIMEOUT_MILLISECONDS;
    const timeoutTime = Date.now() + timeout;

    // Attempt to fetch the template
    const fetch = async (): Promise<Template | null> => {
        const template = await getTemplate(templateId, config);
        return condition(template) ? template : null;
    };

    for (let attempt = 1; ; ++attempt) {
        options?.signal?.throwIfAborted();

        // Attempt to fetch the user template
        const template = await fetch();
        if (template) {
            if (attempt > 1) {
                log.info('Surgical-template fetched after %d attempts', attempt);
            }
            return template;
        }
        options?.signal?.throwIfAborted();

        if (Date.now() >= timeoutTime) {
            throw new TimeoutError('Timed-out while attempting to fetch surgical template');
        }

        // Log every Nth attempt at info level
        const logLevel = attempt++ % LOG_ATTEMPT_AT_INFO === 0 ? 'info' : 'debug';
        log(logLevel, 'Attempt %s to fetch template...', attempt);

        await asyncTimeout(POLL_INTERVAL_MILLISECONDS, options?.signal);
    }
}

