import { DirectionalLight, HemisphereLight, Scene } from 'three';
import { lpsPoint } from '@/formus/anatomy/LPS';
import { addObject } from '@/planner/3d/object3d';

const DIRECTIONAL_LIGHT_COLOR = '#ffffff';
const HEMI_LIGHT_SKY = '#ffffff';
const HEMI_LIGHT_GROUND = '#777a70';
const HEMI_LIGHT_POSITION = lpsPoint({ superior: 2000 });

export type StandardLights = Readonly<{
    directional?: DirectionalLight;
    hemisphere: HemisphereLight;
}>;

export function addStandardLights(scene: Scene): StandardLights {
    return {
        directional: addObject(scene, () => {
            const light = new DirectionalLight(DIRECTIONAL_LIGHT_COLOR);
            light.intensity = 1;
            light.position.set(0, 0, 1000);
            light.updateMatrix();
            return light;
        }),
        hemisphere: addObject(scene, () => {
            const light = new HemisphereLight(HEMI_LIGHT_SKY, HEMI_LIGHT_GROUND, 5);
            light.position.copy(HEMI_LIGHT_POSITION);
            light.updateMatrix();
            return light;
        }),
    };
}
