import { stop, stopAll, type StopHandle, taggedLogger } from '@/util';
import { watchImmediate } from '@vueuse/core';
import { getPlyModel } from '@/planner/api/getPlyModel';
import { markRaw } from 'vue';
import type { BaseMeshNode } from '@/planner/3d/baseMesh';
import type { BufferGeometry } from 'three';
import type { Url } from '@/formus/types';

const log = taggedLogger('scene');

export type LoadGeometry = (
    source: Url,
    onLoaded: (geometry: BufferGeometry) => void,
) => StopHandle | null;

/**
 * Watch the geometrySource property of the given mesh-node, and load its geometry when
 * that property changes
 */
export function updateGeometryFromSource(
    node: BaseMeshNode,
    loadGeometry: LoadGeometry = loadGeometryParallel,
): StopHandle {
    let geometryLoading: StopHandle | null = null;
    return stopAll(
        watchImmediate(
            () => node.geometrySource,
            () => {
                stop(geometryLoading);
                if (node.geometrySource === null) {
                    node.geometry = null;
                    geometryLoading = null;
                } else {
                    log.debug('Loading %s geometry from %s', node.name, node.geometrySource);
                    geometryLoading = loadGeometry(node.geometrySource, (geometry) => {
                        log.info('Loaded %s geometry', node.name);
                        node.geometry = markRaw(geometry);
                    });
                }
            },
        ),
        () => stop(geometryLoading),
    );
}

/**
 * Load geometry from the mesh-node geometrySource URL, and then set it as the node's geometry.
 *
 * NOTE: This function will run in parallel with any other geometry loading, and has caused
 * problems in the past. See {@link makeLoadGeometryInOrder} as an alternative.
 */
export function loadGeometryParallel(
    source: Url,
    onLoaded: (geometry: BufferGeometry) => void,
): StopHandle | null {
    const abortLoading = new AbortController();
    getPlyModel(source, abortLoading.signal)
        .then(onLoaded)
        .catch((error) => {
            if (!abortLoading.signal.aborted) {
                log.error(`Failed to load geometry from '${source}': ${error}`);
            }
        });
    return () => abortLoading.abort();
}

/**
 * Define a function that will load geometry from a mesh-node geometrySource URL, and then set it
 * as the node's geometry.
 *
 * NOTE: The resulting function will execute the geometry loading in-order i.e. it will wait until
 * one GET is finished before it begins the next. We shouldn't have to do this, but if we don't
 * the mesh-data getting corrupted.
 */
export function makeLoadGeometryInOrder(): LoadGeometry {
    async function loadGeometry(
        previousLoad: Promise<void>,
        source: Url,
        onLoaded: (geometry: BufferGeometry) => void,
        signal: AbortSignal,
    ): Promise<void> {
        await previousLoad;
        try {
            const geometry = await getPlyModel(source, signal);
            onLoaded(geometry);
        } catch (error) {
            if (!signal.aborted) {
                log.error(`Failed to load geometry from '${source}': ${error}`);
            }
        }
    }

    let lastLoad: Promise<void> = Promise.resolve();

    return (
        source: Url,
        onLoaded: (geometry: BufferGeometry) => void,
    ): StopHandle => {
        const abortLoading = new AbortController();
        lastLoad = loadGeometry(lastLoad, source, onLoaded, abortLoading.signal);
        return () => abortLoading.abort();
    };
}
