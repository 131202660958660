import type { ApiCase } from '@/planner/api/case';
import { getCase } from '@/planner/api/case';
import { assert, taggedLogger } from '@/util';
import { getTemplate } from '@/api/template/getTemplate';
import { createTemplate } from '@/planner/api/createTemplate';

const log = taggedLogger('create-manual-template');

/**
 * This function should be called when a manual template does not exist for a case, likely the first time
 * the user accesses the 3D planning for a case.
 */
export async function createManualTemplate(case_: ApiCase): Promise<ApiCase> {
    assert(!case_.manualTemplate);
    log.info('Manual template does not exist for case %s', case_.id);

    const automatedTemplate = await getTemplate(case_.automatedTemplate);
    if (automatedTemplate.state !== 'completed') {
        throw Error(`Parent automated template ${automatedTemplate.self} is not completed`);
    }

    log.info('Manual template for case %s is going to be created...', case_.id);

    await createTemplate(case_.id, {
        surgical_template: automatedTemplate.self,
        type: 'manual',
    });

    log.info('Manual template for case %s has been created. Reload case.', case_.id);

    const updatedCase = await getCase(case_.id);

    assert(updatedCase.manualTemplate, 'Manual template should exist after creation');

    return updatedCase;
}
