import type { PlannerState } from '@/planner/plannerState';

import type { PlannerObjectId } from '@/planner/scene/plannerObjectId';

export function isMeshVisible(state: PlannerState, id: PlannerObjectId): boolean {
    switch (id) {
        case 'metal':
            return true;
        case 'cup-collision-surface':
            return false;
        case 'bearing':
            return true;
        case 'cup':
        case 'liner':
            return state.plannerMode !== 'stem'
                && state.visibility.implant === 'on';
        case 'stem':
        case 'head':
            return state.plannerMode !== 'cup'
                && state.visibility.implant === 'on';
        case 'cup-coverage':
            return state.plannerMode !== 'stem'
                && state.visibility.implant === 'on'
                && state.visibility.xray === 'on';
        case 'operative-femur':
        case 'operative-femur-internal':
        case 'contralateral-femur':
            return state.visibility.femur === 'on';
        case 'sacrum':
        case 'operative-hemipelvis':
        case 'contralateral-hemipelvis':
            return state.visibility.pelvis === 'on';
    }
}
