import { Mesh, Object3D, Scene } from 'three';
import { addStandardLights, type StandardLights } from '@/planner/scene/lights';
import { debugHelpers, type DebugHelpers } from '@/planner/scene/debugHelpers';
import type { CupAssembly } from '@/planner/scene/cupAssembly';
import { cupAssembly } from '@/planner/scene/cupAssembly';
import type { FemoralAssembly } from '@/planner/scene/femoralAssembly';
import { femoralAssembly } from '@/planner/scene/femoralAssembly';
import { addObject } from '@/planner/3d/object3d';
import { type StemAssembly, stemAssembly } from '@/planner/scene/stemAssembly';

/**
 * The three-js Scene, together with the objects in it
 */
export type PlannerScene = Readonly<DebugHelpers & FemoralAssembly & CupAssembly & StemAssembly & {
    scene: Scene;
    contralateralFemur: Mesh;
    operativeHemipelvis: Mesh;
    contralateralHemipelvis: Mesh;
    sacrum: Mesh;
    metal: Mesh;
    lights: StandardLights;
    axialCatStack: Object3D;
    coronalCatStack: Object3D;
}>;

export function plannerScene(): PlannerScene {
    const scene = new Scene();
    scene.up.set(0, 0, 1);

    return {
        scene,
        ...debugHelpers(scene),
        ...cupAssembly(scene),
        ...femoralAssembly(scene),
        ...stemAssembly(scene),
        contralateralFemur: addObject(scene, new Mesh()),
        operativeHemipelvis: addObject(scene, new Mesh()),
        contralateralHemipelvis: addObject(scene, new Mesh()),
        sacrum: addObject(scene, new Mesh()),
        metal: addObject(scene, new Mesh()),
        lights: addStandardLights(scene),
        axialCatStack: addObject(scene, new Object3D()),
        coronalCatStack: addObject(scene, new Object3D()),
    };
}
