import { client } from '@/api/http';
import AxiosBrowserCacheUrlMutation from '@/lib/AxiosBrowserCacheUrlMutation';
import { type AxiosRequestConfig, HttpStatusCode } from 'axios';
import DownloadUtil from '@/lib/DownloadUtil';
import anylogger from 'anylogger';

const log = anylogger('load-image');

/**
 * Load the image from http into a HTML IMG element. This is a multi-set process
 * involving:
 *  - http GET to arraybuffer
 *  - arraybuffer to blob conversion
 *  - read blob to data URL
 *  - load data URL into image
 *  - decode image
 *
 * This process has problems with load images over 1MB in size (of which all the
 * dicom sprite images are). Direct download to Blob fails on Firefox. Using a jpeg
 * file of less than 1MB works just fine.
 *
 * @see {@link https://github.com/eligrey/FileSaver.js/#supported-browsers}
 * @see {@link https://stackoverflow.com/questions/47802518/download-big-files}
 * @see {@link https://chromium.googlesource.com/chromium/src/+/224e43ce1ca4f6ca6f2cd8d677b8684f4b7c2351/storage/browser/blob/README.md}
 */
export default async function loadImage(
    fileUrl: string,
    config?: AxiosRequestConfig,
): Promise<HTMLImageElement | ImageBitmap> {
    const response = await client.get<ArrayBuffer>(
        fileUrl,
        AxiosBrowserCacheUrlMutation.makeMutationOption({
            headers: {
                // Let the service decide the best image representation
                accept: 'image/*;q=0.5',
            },
            // arraybuffer, blob, stream, text, json, document
            responseType: 'arraybuffer',
            // Disable response transformation for this request. Models should be transferred without
            // any mutation or modification.
            transformResponse: (data /*: any, headers?: any */) => {
                return data;
            },
            ...config,
        }),
    );
    if (response.status === HttpStatusCode.Ok && response.data) {
        const blob: Blob = new Blob([response.data], {
            type: DownloadUtil.getContentType(response.headers, 'image/jpeg'),
        });
        if (blob) {
            log.debug('Parsing image from blob of %d bytes of %s', blob.size, blob.type);
            return createImageBitmap(blob);
        } else {
            throw new Error(`Failed to get image blob for '${fileUrl}'`);
        }
    } else {
        throw new Error(`Failed to get image blob for '${fileUrl}'. Code '${response.status}'`);
    }
}

export async function loadImageAsUrl(
    fileUrl: string,
    config?: AxiosRequestConfig,
): Promise<string | undefined> {
    const response = await client.get<ArrayBuffer>(
        fileUrl,
        AxiosBrowserCacheUrlMutation.makeMutationOption({
            headers: {
                // Let the service decide the best image representation
                accept: 'image/*;q=0.5',
            },
            // arraybuffer, blob, stream, text, json, document
            responseType: 'arraybuffer',
            // Disable response transformation for this request. Models should be transferred without
            // any mutation or modification.
            transformResponse: (data /*: any, headers?: any */) => {
                return data;
            },
            ...config,
        }),
    );
    if (response.status === HttpStatusCode.Ok && response.data) {
        const blob: Blob = new Blob([response.data], {
            type: DownloadUtil.getContentType(response.headers, 'image/jpeg'),
        });
        if (blob) {
            log.debug('Parsing image from blob of %d bytes of %s', blob.size, blob.type);
            return _readBlobToDataUrl(blob);
        } else {
            throw new Error(`Failed to get image blob for '${fileUrl}'`);
        }
    } else {
        throw new Error(`Failed to get image blob for '${fileUrl}'. Code '${response.status}'`);
    }
}

function _readBlobToDataUrl(blob: Blob): Promise<string> {
    return new Promise<string>((resolve, reject) => {
        try {
            const reader = new FileReader();
            reader.onerror = (ev: ProgressEvent<FileReader>) => {
                if (ev.target) {
                    ev.target.abort();
                    reject(ev.target.error);
                } else {
                    reject(new Error(`Known error loading image from blob`));
                }
            };
            reader.onload = (ev: ProgressEvent<FileReader>) => {
                if (ev.target) {
                    resolve(reader.result as string);
                }
                reject(new Error(`Known target`));
            };
            reader.readAsDataURL(blob);
        } catch (err) {
            reject(err);
        }
    });
}
