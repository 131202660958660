<template>
    <div class="mt-5">
        <div class="d-flex justify-content-end">
            <v-spacer />
            <v-checkbox
                density="compact"
                :disabled="!isReady"
                label="Show CT Scans"
                class="d-inline-flex"
                @change="toggleVisibility"
            />
        </div>
        <div v-if="isVisible">
            <div class="range-holder">
                <label class="mt-2">CT Coronal:</label>
                <v-slider
                    step="1"
                    v-model="coronalSlider.value"
                    :min="coronalSlider.min"
                    :max="coronalSlider.max"
                    color="input-blue"
                    track-size="2.5"
                    track-color="formus-outline"
                    thumb-label
                    thumb-size="12"
                    hide-details
                />
            </div>
            <div class="range-holder mt-2">
                <label class="mt-2">CT Axial:</label>
                <v-slider
                    step="1"
                    v-model="axialSlider.value"
                    :min="axialSlider.min"
                    :max="axialSlider.max"
                    color="input-blue"
                    track-size="2.5"
                    track-color="formus-outline"
                    thumb-label
                    thumb-size="12"
                    hide-details
                />
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
    import { computed } from 'vue';
    import { usePlannerStore } from '@/planner/plannerStore';
    import { verify } from '@/lib/verify';

    const store = usePlannerStore();

    const isVisible = computed(() => {
        return store.scans?.visible ?? false;
    });

    const isReady = computed(() => {
        return store.scans ?? false;
    });

    const toggleVisibility = () => {
        if (!store.scans) {
            return;
        }

        store.scans.visible = !store.scans.visible;
    };

    const axialSlider = computed(() => {
        const axial = verify(store.scans?.axial, 'no scans');
        return axial.slider;
    });

    const coronalSlider = computed(() => {
        const coronal = verify(store.scans?.coronal, 'no scans');
        return coronal.slider;
    });
</script>

<style scoped lang="scss">
    :deep(label) {
        color: #fff;
        font-size: 12px;
    }

    .range-holder {
        display: grid;
        grid-template-columns: 1fr 192px;
        grid-gap: 20px;
    }
</style>
