import assert from 'assert';
import axios from 'axios';

export enum DataState {
    /** Most stores initialise data and use this state to flag they are loading data */
    Loading = 'loading',

    Validating = 'validating',
    Saving = 'saving',

    /**
     * This state can be sometimes used after a POST or PUT
     * to fetch again the resource/data created/updated
     */
    Syncing = 'syncing',

    Idle = 'idle',

    Error = 'error',
}

type StateError = {
    title: string;
    message: string;
};

export type StateHolder = {
    state: DataState;
    error: StateError | null;
};

export const makeDataState = (): StateHolder => {
    return {
        state: DataState.Loading,
        error: null,
    };
};

export const makeDataStateGetters = () => {
    return {
        isLoaded(): boolean {
            return !this.isLoading;
        },
        isError(state: StateHolder): boolean {
            return state.state === DataState.Error;
        },
        isLoading(state: StateHolder): boolean {
            return state.state === DataState.Loading;
        },
        isSaving(state: StateHolder): boolean {
            return state.state === DataState.Saving;
        },
        isValidating(state: StateHolder): boolean {
            return state.state === DataState.Validating;
        },
    };
};

export async function withStateLoading(
    storeState: StateHolder,
    fn: () => Promise<void>,
): Promise<void> {
    setInLoading(storeState);

    try {
        await fn();

        setInIdle(storeState);
    } catch (err) {
        assert.ok(err instanceof Error);
        console.log(err);
        setInError(storeState, { title: 'Something has gone wrong.', message: err.message });
    }
}

export async function withStateSaving(
    storeState: StateHolder,
    fn: () => Promise<void>,
): Promise<void> {
    setInSaving(storeState);

    try {
        await fn();

        setInIdle(storeState);
    } catch (err) {
        if (axios.isAxiosError(err)) {
            setInError(storeState, { title: 'Something has gone wrong.', message: err.message });
        } else {
            setInError(storeState, {
                title: 'We’re sorry, there was an error communicating with the server.',
                message:
                    'Please refresh and try again, or contact the Formus support team ' +
                    'via the help option at the top right of the page',
            });
        }
    }
}

export function setInLoading(storeState: StateHolder): void {
    storeState.error = null;
    storeState.state = DataState.Loading;
}

export function setInValidating(storeState: StateHolder): void {
    storeState.error = null;
    storeState.state = DataState.Validating;
}

export function setInSaving(storeState: StateHolder): void {
    storeState.error = null;
    storeState.state = DataState.Saving;
}

export function setInIdle(storeState: StateHolder): void {
    storeState.error = null;
    storeState.state = DataState.Idle;
}

export function setInSyncing(storeState: StateHolder): void {
    storeState.error = null;
    storeState.state = DataState.Syncing;
}

export function setInError(storeState: StateHolder, error: StateError | null): void {
    storeState.error = error;
    storeState.state = DataState.Error;
}
