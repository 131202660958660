<template>
    <div class="planner-status">
        <div v-if="state === 'processing'">
            <v-progress-circular indeterminate size="20" width="2" color="planner-green" />
            <span v-if="planner.currentOperation === 'load-case'"> Loading case ... </span>
            <span v-else-if="planner.currentOperation === 'reset-stem'">
                Resetting stem selection ...
            </span>
            <span v-else-if="planner.currentOperation === 'reset-cup'">
                Resetting cup selection ...
            </span>
            <span v-else-if="planner.currentOperation === 'set-targets'">
                Optimizing stems ...
            </span>
            <span v-else-if="planner.currentOperation === 'approve-plan'">
                Approving plan ...
            </span>
        </div>
        <div v-else-if="state === 'saving'">
            <v-icon size="20" icon="mdi-sync" />
            <span>Saving ...</span>
            <v-tooltip :activator="'parent'" right>
                Changes are being saved. This can take a few seconds.
            </v-tooltip>
        </div>
        <div v-else-if="showSaved">
            <v-icon size="20" icon="mdi-cloud-check" color="planner-green" />
            <span>Saved</span>
            <v-tooltip :activator="'parent'"> All changes are saved.</v-tooltip>
        </div>
    </div>
</template>

<script setup lang="ts">
    import { usePlannerStore } from '@/planner/plannerStore';
    import { ref, computed, watch } from 'vue';
    import { useTemplateSyncStore } from '@/planner/template/templateSyncStore';
    import { useDebounceFn } from '@vueuse/core';
    import { useSpinopelvic } from '@/stores/spinopelvic/store';

    const planner = usePlannerStore();
    const hipSpine = useSpinopelvic();
    const sync = useTemplateSyncStore();

    const state = computed<'processing' | 'saving' | null>(() => {
        if (planner.currentOperation) {
            return 'processing';
        } else {
            const isSavingHipSpine = hipSpine.isEnabled && hipSpine.isSaving;
            return sync.hasUpdate || isSavingHipSpine ? 'saving' : null;
        }
    });

    const showSaved = ref(false);
    const SAVED_FADE_OUT_MILLISECONDS = 5000;

    const hideSavedAfterTimeout = useDebounceFn(
        () => (showSaved.value = false),
        SAVED_FADE_OUT_MILLISECONDS,
    );

    watch(state, (newValue, oldValue) => {
        if (oldValue === 'saving' && newValue === null) {
            showSaved.value = true;
            hideSavedAfterTimeout();
        }
    });
</script>

<style scoped>
    .planner-status {
        font-size: 14px;
    }

    .planner-status > div {
        display: flex;
        align-items: center;
        gap: 8px;
    }
</style>
