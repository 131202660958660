import type { Url } from '@/formus/types';
import {
    type CatalogCup,
    type CupSize,
    isCupSize,
    isCupSizeString,
    isCupSystem,
    isCupType,
} from '@/formus/catalog/cup';
import { assertIs } from '@/util';
import { convertLinks } from '@/api/convertLinks';
import type { ApiCatalogComponentBase } from '@/api/catalog/base';
import { isLinerSize } from '@/formus/catalog/liner';
import type { HeadSize } from '@/formus/catalog/head';
import { isStemSystem } from '@/formus/catalog/stem';

export type ApiCatalogCup = ApiCatalogComponentBase & {
    /** The outer diameter of the cup */
    outer_diameter: number;

    /** The outer diameter of the cup */
    liner_size_: string;

    /** Link to the component-representation on the API */
    collisionMask: Url;
};

const cupLinkMap = {
    self: 'self',
    collisionMask: 'region',
} as const;

/**
 * Convert a cup representation from the api into a catalog-cup
 */
export function catalogCup(apiCup: ApiCatalogCup): CatalogCup {
    const { name, system, type, size, outer_diameter, liner_size_ } = apiCup;
    assertIs(isCupSystem, system);
    assertIs(isCupType, type);
    assertIs(isCupSizeString, size);
    assertIs(isCupSize, outer_diameter);
    assertIs(isLinerSize, liner_size_);
    return {
        name,
        system,
        type,
        size,
        outerDiameter: outer_diameter,
        linerSize: liner_size_,
        recommendedHeadSize: recommendedHeadSize(outer_diameter),
        ...convertLinks(apiCup, cupLinkMap),
    };
}

function recommendedHeadSize(outerDiameter: CupSize): HeadSize {
    if (outerDiameter < 50) {
        return 32;
    } else {
        return 36;
    }
}
