
import type { ApiLinks } from '@/api/links';
import type { Url } from '@/formus/types';
import { type AxiosResponse, HttpStatusCode } from 'axios';
import { client } from '@/api/http';
import { errorDetail } from '@/planner/api/errorDetail';
import type {
    CupAnteversionMode,
    CupFitMethod, StemSelector,
} from '@/formus/specification';
import { type ConvertedRepresentation, convertRepresentation } from '@/planner/api/convertRepresentation';
import { alignmentMode, type ApiAlignmentMode } from '@/planner/api/alignmentMode';
import type { AlignmentMode } from '@/formus/anatomy/pelvis/alignment';
import type { PreferredSystem } from '@/planner/api/specification';

export type PreferencesProperties = {
    created: string;
    updated: string;
    stem: {
        selector: StemSelector[];
    };
    cup: {
        fit_method: CupFitMethod;
        abduction_angle: number;
        anteversion_mode: CupAnteversionMode;
        anteversion_angle: number;
    };
    align_mode: AlignmentMode | null;
    preferred_system: PreferredSystem;
};

const preferencesLinkMap = {
    self: 'self',
    user: 'up',
    canonical: 'canonical',
    product: 'product',
    createdUser: { rel: 'user', title: 'created' },
    updaterUser: { rel: 'user', title: 'updater' },
} as const;

/** Identifies surgical-preferences on the API */
export type ApiPreferencesId = { user: number; product: number; version: number };

export type ApiPreferences = ConvertedRepresentation<
    PreferencesProperties,
    typeof preferencesLinkMap
> & {
    id: ApiPreferencesId;
};

type ApiPreferencesResponse = PreferencesProperties & ApiLinks & { align_mode: ApiAlignmentMode | null};

export async function getPreferences(prefsId: ApiPreferencesId | Url): Promise<ApiPreferences> {
    let id, url;
    if (typeof prefsId === 'object') {
        id = prefsId;
        url = `user/${id.user}/preferences/product/${id.product}/v/${id.version}`;
    } else {
        url = prefsId;
        id = preferencesIdFromUrl(url);
    }

    const { status, data }: AxiosResponse<ApiPreferencesResponse> = await client.get(url);
    if (status === HttpStatusCode.Ok) {
        return {
            id,
            ...convertRepresentation(data, preferencesLinkMap),
            alignMode: data.align_mode ? alignmentMode(data.align_mode) : null,
        };
    }

    throw Error(`Failed to load study from ${url}` + errorDetail(data));
}

export function preferencesIdFromUrl(url: Url): ApiPreferencesId {
    const match = url.match(/user\/([0-9]*)\/preferences\/product\/([0-9]*)\/v\/([0-9]*)/);
    if (match) {
        return {
            user: Number(match[1]),
            product: Number(match[2]),
            version: Number(match[3]),
        };
    }
    throw Error(`Failed to extract preferences-id from url '${url}'`);
}
