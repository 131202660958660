import { BufferGeometry, type Matrix4, Mesh } from 'three';
import { taggedLogger } from '@/util';
import type { CollisionCoverageResults } from '@/planner/cupCoverage/types';
import { cupCoverageStrategy } from '@/planner/cupCoverage/ComponentCoverageUtil';
import { defineStore } from 'pinia';
import { useCupOverlayStore } from '@/planner/cupOverlayStore';
import type { PlannerSceneState } from '@/planner/scene/sceneState';
import { verify } from '@/lib/verify';
import { CupCoverageController } from '@/planner/cupCoverage/cupCoverageController';
import { setLocalTransform } from '@/planner/3d/object3d';
import { ScheduledCancelledError } from '@/planner/cupCoverage/AsyncDebounce';
import { AbortCalculationError } from '@/planner/cupCoverage/abortCalculationError';
import { makeCoverageMesh } from '@/planner/cupCoverage/updateCupCoverage';

const log = taggedLogger('cup-coverage-store');

/** Store that adapts the state of the cup for the overlay user-interface */
export type CupCoverageStore = {
    calculate(aborter: AbortController, sceneState: PlannerSceneState): void;
};

export const useCupCoverageStore: () => CupCoverageStore = defineStore('cup-coverage-store', () => {
    const _state: CollisionCoverageResults = {
        area: NaN,
        coverage: NaN,
        totalArea: NaN,
        isCalculating: false,
        intersectionMesh: new Mesh(),
        faceAreaMetrics: [],
    };

    /**
     * Helper method that is called when cup coverage needs to be updated
     *
     * This method uses the component coverage controller to initiate cup coverage update
     */
    async function calculate(aborter: AbortController, sceneState: PlannerSceneState) {
        log.debug('schedule update cup coverage 3d');

        const cupOverlayStore = useCupOverlayStore();

        try {
            _state.isCalculating = true;

            cupOverlayStore.coverage = 'calculating';

            const cupCollision = makeCollisionMask(sceneState);
            const coverage = await new CupCoverageController(aborter).updateCoverage(
                cupCollision,
                makeHemiPelvis(sceneState),
            );

            if (coverage) {
                Object.assign(_state, coverage);

                cupOverlayStore.coverage = _state.coverage;

                const coverageMesh = makeCoverageMesh(coverage);
                coverageMesh.renderOrder = cupCoverageStrategy().coverageMeshRenderOrder;

                // TODO: Jamie to assign coverage geometry to sceneState.cupCoverage.geometry with correct transform
                // TODO: Jamie to assign coverage geometry to sceneState.cupCoverage.geometry with correct transform
                // TODO: Jamie to assign coverage geometry to sceneState.cupCoverage.geometry with correct transform
                // TODO: Jamie to assign coverage geometry to sceneState.cupCoverage.geometry with correct transform
                // TODO: Jamie to assign coverage geometry to sceneState.cupCoverage.geometry with correct transform
                // TODO: Jamie to assign coverage geometry to sceneState.cupCoverage.geometry with correct transform
                // TODO: Jamie to assign coverage geometry to sceneState.cupCoverage.geometry with correct transform
                // TODO: Jamie to assign coverage geometry to sceneState.cupCoverage.geometry with correct transform
                // sceneState.cupCoverage.geometry = applyMatrix4ToGeometry(
                //     verify(coverageMesh.geometry, 'no cup coverage geometry'),
                //     cupCollision.matrixWorld,
                // );
                sceneState.cupCoverage.geometry = verify(
                    coverageMesh.geometry,
                    'no cup coverage geometry',
                );
            } else {
                // coverage cancelled, nothing to do
            }
        } catch (error: unknown) {
            if (error instanceof AbortCalculationError) {
                // It is likely that if it was cancelled it will be re-run, so nothing to do here.
                log.debug(
                    'Coverage cancelled by caller while in progress. Nothing to do: %s',
                    error.message,
                );
            } else if (error instanceof ScheduledCancelledError) {
                log.debug(
                    'Coverage cancelled by caller before even starting. Nothing to do: %s',
                    error.message,
                );
            } else {
                _state.isCalculating = false;
                cupOverlayStore.coverage = null;

                if (error instanceof Error) {
                    log.error('Unexpected coverage calculation %s', error.message);
                } else {
                    log.error(error);
                }

                throw error;
            }
        }
    }

    return {
        calculate,
    };
});

function makeMesh(geometry: BufferGeometry, transform: Matrix4): Mesh {
    const result = new Mesh(geometry);
    result.matrixAutoUpdate = false;
    setLocalTransform(result, transform);
    return result;
}

function makeCollisionMask(sceneState: PlannerSceneState): Mesh {
    const geometry = verify(
        sceneState.cupCollisionSurface.geometry,
        'No collision surface geometry',
    );
    const transform = sceneState.cupGroup.transform
        .clone()
        .multiply(sceneState.cupCoverage.transform);
    return makeMesh(geometry, transform);
}

function makeHemiPelvis(sceneState: PlannerSceneState): Mesh {
    const geometry = verify(sceneState.operativeHemipelvis.geometry, 'No hemi pelvis geometry');
    const transform = sceneState.operativeHemipelvis.transform.clone();
    return makeMesh(geometry, transform);
}
