import { Group, Mesh, type Scene } from 'three';
import { addObject } from '@/planner/3d/object3d';

export type CupAssembly = {
    /**
     * Group object attached to the acetabular-group that is the parent of the cup and liner.
     *
     * It is not translated inside the acetabular-group, so its position implicitly corresponds
     * to the centre-of-rotation of the cup. It is rotated to apply the cup-rotation to the cup and liner.
     */
    cupGroup: Group;

    /** The cup mesh, attached to the cup-group. */
    cup: Mesh;

    /** The liner mesh, attached to the cup-group. */
    liner: Mesh;

    /** The cup coverage-mesh, attached to the cup-group. */
    cupCoverage: Mesh;

    /** The cup collision-surface, attached to the cup-group. */
    cupCollisionSurface: Mesh;
};

export function cupAssembly(scene: Scene): CupAssembly {
    const cupGroup = addObject(scene, new Group());

    return {
        cupGroup,
        cup: addObject(cupGroup, new Mesh()),
        liner: addObject(cupGroup, new Mesh()),
        cupCoverage: addObject(cupGroup, new Mesh()),
        cupCollisionSurface: addObject(cupGroup, new Mesh()),
    };
}
