import { type Raw } from 'vue';
import { watchImmediate } from '@vueuse/core';
import { type Mesh, BufferGeometry } from 'three';
import type { Url } from '@/formus/types';
import { stopAll, type StopHandle, taggedLogger } from '@/util';
import { type ObjectNode, objectNode, updateObject } from '@/planner/3d/object';
import { transformNode, type TransformNode, updateTransform } from '@/planner/3d/transform';

const log = taggedLogger('scene');

/** The default render-order */
const DEFAULT_RENDER_ORDER = 0 as const;

/** The common state representing a mesh in the scene */
export type BaseMeshNode<T extends string = string> = ObjectNode<T> &
    TransformNode & {
        geometrySource: Url | null;
        geometry: Raw<BufferGeometry> | null;
        renderOrder: number;
    };

export function baseMeshNode<T extends string = string>(
    type: T,
    properties?: Partial<BaseMeshNode>,
): BaseMeshNode<T> {
    return {
        ...objectNode(type, properties),
        ...transformNode(properties),
        geometrySource: properties?.geometrySource ?? null,
        geometry: properties?.geometry ?? null,
        renderOrder: properties?.renderOrder ?? DEFAULT_RENDER_ORDER,
    };
}

export function updateBaseMesh(node: BaseMeshNode, mesh: Mesh): StopHandle {
    return stopAll(
        updateObject(node, mesh),
        updateTransform(node, mesh),
        updateRenderOrder(node, mesh),
        updateMeshGeometry(node, mesh),
    );
}

export function updateRenderOrder(node: BaseMeshNode, mesh: Mesh): StopHandle {
    return watchImmediate(
        () => node.renderOrder,
        (order) => {
            log.debug('Setting render order for %s to %d', node.name ?? '?', order);
            mesh.renderOrder = order;
        },
    );
}

export function updateMeshGeometry(node: BaseMeshNode, mesh: Mesh): StopHandle {
    return watchImmediate(
        () => node.geometry,
        (geometry) => setGeometry(mesh, geometry),
    );
}

let EMPTY_GEOMETRY: BufferGeometry | null = null;

export function setGeometry(mesh: Mesh, geometry: BufferGeometry | null) {
    if (geometry !== null) {
        mesh.geometry = geometry;
    } else {
        if (EMPTY_GEOMETRY === null) {
            EMPTY_GEOMETRY = new BufferGeometry();
        }
        mesh.geometry = EMPTY_GEOMETRY;
    }
}
