import { Group, Mesh, type Scene } from 'three';
import { addObject } from '@/planner/3d/object3d';

export type StemAssembly = {
    /**
     * Group that is the parent of the stem, head and related features, with an origin that
     * corresponds to the centre-of-rotation of the head.
     *
     * Its attachment and transform depends on whether the assembly is in the retracted or native layout:
     *
     * - In **native** layout it is attached to the femoral-group and transformed relative to the femur
     * - In **retracted** layout it is attached to the acetabular-assembly, and translated so the head is
     *   socketed in the cup. It's rotation in this layout preserves its relative transformation to the femur
     *   while allowing the femur to retain its native orientation.
     */
    stemGroup: Group;

    /** The head object, attached to the stem-group. */
    head: Mesh;

    /** The stem object, attached to the stem-group. */
    stem: Mesh;

    /** The dual mobility bearing, attached to the stem-group */
    bearing: Mesh;
};

export function stemAssembly(scene: Scene): StemAssembly {
    const stemGroup = addObject(scene, new Group());

    return {
        stemGroup,
        stem: addObject(stemGroup, new Mesh()),
        head: addObject(stemGroup, new Mesh()),
        bearing: addObject(stemGroup, new Mesh()),
    };
}
