import { stop as stopHandle, stopAll, type StopHandle, taggedLogger } from '@/util';
import { defineStore } from 'pinia';
import { useAppErrorStore } from '@/stores/appErrorStore';
import type { PlannerState } from '@/planner/plannerState';
import { verify } from '@/lib/verify';
import { fetchCatStacks } from '@/api/cat-stacks/request';
import type { Url } from '@/formus/types';
import { caseIdFromUrl } from '@/planner/api/case';
import { makeCatstackRegionObject, makeSliders, setupCatstackAxes } from '@/planner/cat-stack/util';

const log = taggedLogger('planner-background-data-store');

export const useBackgroundDataStore = defineStore('backgroudn-data', () => {
    let syncing: StopHandle | null = null;
    const appError = useAppErrorStore();

    async function _run(state: PlannerState, caseId: Url, signal: AbortSignal): Promise<void> {
        try {
            const id = caseIdFromUrl(caseId);
            log.info(`Loading case ${caseId} background data...`);
            const catStacks = verify(await fetchCatStacks(id, { signal }), 'cat-stack');
            const cuboidPoints = makeCatstackRegionObject(catStacks.axial, catStacks.bounding_box);
            const axes = setupCatstackAxes(cuboidPoints);
            const sliders = makeSliders(catStacks);
            state.scans = {
                visible: false,
                axial: {
                    catStack: catStacks.axial,
                    axis: axes.axial,
                    slider: sliders.axial,
                },
                coronal: {
                    catStack: catStacks.coronal,
                    axis: axes.coronal,
                    slider: sliders.coronal,
                },
            };

            state.scene.axialCatStack.src = catStacks.axial.image_url;
            state.scene.coronalCatStack.src = catStacks.coronal.image_url;
        } catch (error) {
            if (signal.aborted) {
                return;
            } else {
                throw error;
            }
        }
    }

    const load = (state: PlannerState, caseId: Url): void => {
        stopHandle(syncing);
        const aborter = new AbortController();
        const signal = aborter.signal;

        syncing = stopAll(
            // TODO: what for scene being already loaded?
            // When we stop also signal the abort
            () => aborter.abort(),
        );

        appError.catchErrors(() => _run(state, caseId, signal));
    };

    return {
        /**
         * True if there is an update that is queued or currently being saved
         */
        load,
        stop: () => stopHandle(syncing),
    };
});

// currentSlice: catStack.count - 1,

