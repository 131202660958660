import { Euler, Matrix4, Vector2, Vector3, Vector4 } from 'three';
import { positionalPart } from './matrix';
import { getBasis, isXyzVectors, type XyzVectors } from './basis';
import { radToDeg } from '@/util';
import type { LpsVectors } from '@/formus/anatomy/LPS';

const DEFAULT_PRECISION = 5;
type FloatFormatOptions = { precision?: number }

export function formatFloat(value: number, options: FloatFormatOptions = {}): string {
    const factor = 10 ** (options.precision ?? DEFAULT_PRECISION);
    return `${Math.round(value * factor) / factor}`;
}

export function formatDegrees(value: number, options: FloatFormatOptions = {}): string {
    return `${formatFloat(value, options)}\u00b0`;
}

export function formatRadiansAsDegrees(value: number, options: FloatFormatOptions = {}): string {
    return `${formatFloat(radToDeg(value), options)}\u00b0`;
}

export function formatRadians(value: number, options: FloatFormatOptions = {}): string {
    return formatFloat(value, options);
}

const DEFAULT_DEGREES = false;
type AngleFormatOptions = FloatFormatOptions & { degrees?: boolean }

export function formatAngle(value: number, options: AngleFormatOptions = {}): string {
    return (options.degrees ?? DEFAULT_DEGREES )
        ? formatRadiansAsDegrees(value, options)
        : formatRadians(value, options);
}

const DEFAULT_VALUE_SEPARATOR = ', ';
type ArrayFormatOptions = FloatFormatOptions & { valueSeparator?: string }

export function formatFloatArray(value: number[], options: ArrayFormatOptions = {}): string {
    return `[${value.map(
        (item: number) => formatFloat(item, options)).join(options.valueSeparator ?? DEFAULT_VALUE_SEPARATOR,
    )}]`;
}

export function formatVector(value: Vector2 | Vector3 | Vector4, options: ArrayFormatOptions = {}): string {
    return formatFloatArray(value.toArray(), options);
}

export function formatEuler(angles: Euler, options: ArrayFormatOptions & AngleFormatOptions = {}): string {
    return `[${(angles.toArray() as number[])
        .slice(0, -1)
        .map((angle: number) => formatAngle(angle, options))
        .join(options.valueSeparator ?? DEFAULT_VALUE_SEPARATOR)
    }]`;
}

const DEFAULT_LINE_SEPARATOR = '\n';
const DEFAULT_INDENT = 0;

type MultilineFormatOptions = ArrayFormatOptions & {
    lineSeparator?: string
    indent?: number
}

export function formatMatrixEuler(matrix: Matrix4, options: MultilineFormatOptions & AngleFormatOptions = {}): string {
    return indent(options.indent, [
        `position: ${formatVector(positionalPart(matrix), options)}`,
        `rotation: ${formatEuler(new Euler().setFromRotationMatrix(matrix), options)}`,
    ].join(options.lineSeparator ?? DEFAULT_LINE_SEPARATOR));
}

export function formatMatrixBasis(value: Matrix4, options: MultilineFormatOptions = {}): string {
    const position = positionalPart(value);
    const { x, y, z } = getBasis(value);
    return indent(options.indent, [
        `position: ${formatVector(position, options)}`,
        `x: ${formatVector(x, options)}`,
        `y: ${formatVector(y, options)}`,
        `z: ${formatVector(z, options)}`,
    ].join(options.lineSeparator ?? DEFAULT_LINE_SEPARATOR));
}

export function formatBasis(basis: XyzVectors | LpsVectors, options: MultilineFormatOptions = {}): string {
    if (isXyzVectors(basis)) {
        const { x, y, z } = basis;
        return indent(options.indent, [
            `x: ${formatVector(x, options)}`,
            `y: ${formatVector(y, options)}`,
            `z: ${formatVector(z, options)}`,
        ].join(options.lineSeparator ?? DEFAULT_LINE_SEPARATOR));
    } else {
        const { left, posterior, superior } = basis;
        return indent(options.indent, [
            `left: ${formatVector(left, options)}`,
            `posterior: ${formatVector(posterior, options)}`,
            `superior: ${formatVector(superior, options)}`,
        ].join(options.lineSeparator ?? DEFAULT_LINE_SEPARATOR));
    }
}

export function indent(level: number | undefined, value: string): string {
    level = level ?? DEFAULT_INDENT;
    return level > 0 ? value.replace(/^/gm, ' '.repeat(level)) : value;
}

