import type { Url } from '@/formus/types';
import type { AxiosResponse } from 'axios';
import { AxiosError, HttpStatusCode } from 'axios';
import { client } from '@/api/http';
import { getIdFromUrl } from '@/lib/getIdFromUrl';

export interface SurgicalTemplateCreateData {
    /**
     * The URI of an existing surgical template.
     *
     * The surgical template must be in the same project
     * and at this stage is limited to the active 'acid' surgical template.
     */
    surgical_template: Url;

    /**
     * The type of template being created: 'automated' or 'manual'.
     *
     * - When 'automated': A surgical template (based in the 'surgical_template' passed in the data) will be created,
     *  the automated workflow will be run, and the template created will be set as the current acid surgical template
     *  for the project.
     *
     * - When 'manual': A surgical template (based in the 'surgical_template' passed in the data) will be created,
     *  and will be set as the current user surgical template.
     */
    type: 'manual' | 'automated';
}

export async function createTemplate(
    caseId: number,
    body: SurgicalTemplateCreateData,
): Promise<string> {
    const { status, data, headers }: AxiosResponse = await client.post(
        `/project/${caseId}/surgical/template`,
        body,
    );
    if (status === HttpStatusCode.Created) {
        return data?.id ?? getIdFromUrl(headers.location);
    }

    throw new AxiosError(`Failed to create template: ${data?.detail}`);
}
