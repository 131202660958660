import { client } from '@/api/http';
import { AxiosError, type AxiosResponse, HttpStatusCode } from 'axios';
import type { ApiObject } from '@/api/spinopelvic/types';

export async function put(caseId: number | string, body: ApiObject): Promise<void> {
    const { status, data }: AxiosResponse = await client.put(_makeUrl(caseId), body);
    if (status === HttpStatusCode.NoContent) {
        return;
    }

    throw new AxiosError(`Failed to save spinopelvic: ${data?.detail}`);
}

const _makeUrl = (caseId: string | number) => `/cases/${caseId}/spinopelvic`;

export async function get(caseId: string | number): Promise<ApiObject | null> {
    const { status, data }: AxiosResponse = await client.get(_makeUrl(caseId));
    if (status === HttpStatusCode.Ok) {
        return data;
    } else if (status === HttpStatusCode.NotFound) {
        return null;
    }

    throw new AxiosError(`Failed to load spinopelvic: ${data?.detail}`);
}
