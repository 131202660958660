export interface DebounceStats {
    /** number of calls to the wrapped function */
    submitted: number;
    /** number of calls to the wrapped function that were 'debounced' (discarded) */
    debounced: number;
    /** number of calls that were made to the user code */
    executed: number;
}

/**
 * The return value from the {@link AsyncDebounce.debounce} function. Instead
 * of returning a function with a 'cancel' function, this result is simple typed
 * data structure with two functions.
 */
export interface DebounceResult<T, A extends Array<unknown>> {
    /**
     * The wrapper over the user debounce function, without the cancellation token.
     */
    function: (...args: A) => Promise<T>;

    /**
     * The method to call to cancel the call.
     */
    cancel: () => void;

    /**
     * Get debug stats.
     */
    stats: () => DebounceStats;
}

/**
 * Options to modify the behaviour of the debounce.
 */
export interface DebounceOptions {
    /**
     * An error object to return when the debounce function is cancelled. If it
     * is not provided then a generic error (with generic error message) is returned.
     */
    cancelError?: Error;

    /**
     * An optional
     */
    aborter?: AbortController;
}

/**
 * Internal state of a call to {@link AsyncDebounce.debounce}.
 */
interface DebounceCall {
    /** */
    rejector: (reason?: any) => void;
    /** whether the call has been chancel - this is a signal to the code inside the timer */
    cancelled: boolean;
    /** whether the code inside the timer is running the user debounce function */
    inCall: boolean;
    aborter: AbortController;
    /** the timer handler - this will be undefined for a short time due to a two phase construct */
    timer?: number;
}

/**
 * Reason used in Error when function is cancelled while in call
 */
const CANCELLED_WHILE_IN_CALL_REASON = 'Cancelled by caller';

/**
 * A utility class to support async debounce.
 */
export class AsyncDebounce {
    /**
     * A debounce() style function, where:
     * <ul>
     *     <li>the function called is async</li>
     *     <li>only one concurrent call to function is in progress</li>
     *     <li>the call to the function can be cancelled from the outside</li>
     *     <li>the called function has a cancellable so that it can halt it operation upon request</li>
     *     <li></li>
     * </ul>
     *
     * @param userFunction the async function to call once the timeout has expired
     * @param timeout the debounce timeout in milli-seconds
     * @param options an optional set of configuration options
     */
    public static debounce<T, A extends Array<unknown>>(
        userFunction: (abortController: AbortController, ...args: A) => Promise<T>,
        timeout: number,
        options?: DebounceOptions,
    ): DebounceResult<T, A> {
        let currentCall: DebounceCall | undefined;
        let submittedCount = 0;
        let debouncedCount = 0;
        let executedCount = 0;

        /**
         * All the caller to cancel (optional) the outstanding debounced call. The call may be:
         * <ul>
         *     <li>No call outstanding</li>
         *     <li>In a timer holdown</li>
         *     <li>executing the async function</li>
         *     </ul>
         */
        function doCancel(): void {
            // In a semi-atomic way (as close as required by javascript) get the current call.
            const aCall = currentCall;
            currentCall = undefined;

            AsyncDebounce.cancelCall(aCall, options);
        }

        function doDebounce(...args: A): Promise<T> {
            ++submittedCount;
            const abortController = options?.aborter ?? new AbortController();

            return new Promise<T>((resolve, reject) => {
                const thisCall: DebounceCall = {
                    cancelled: false,
                    inCall: false,
                    rejector: reject,
                    aborter: abortController,
                };

                thisCall.timer = window.setTimeout(async () => {
                    try {
                        if (!thisCall.cancelled) {
                            thisCall.inCall = true;
                            ++executedCount;
                            resolve(await userFunction(abortController, ...args));
                        }
                    } catch (e) {
                        reject(e);
                    }
                }, timeout);

                const lastCall = currentCall;
                currentCall = thisCall;
                if (lastCall) {
                    ++debouncedCount;
                    AsyncDebounce.cancelCall(lastCall, options);
                }
            });
        }

        /**
         * Provide some basis diagnostic statistics.
         */
        function stats(): DebounceStats {
            return {
                debounced: debouncedCount,
                submitted: submittedCount,
                executed: executedCount,
            };
        }

        return {
            function: doDebounce,
            cancel: doCancel,
            stats,
        };
    }

    private static cancelCall(aCall?: DebounceCall, options?: DebounceOptions): void {
        if (aCall) {
            // stop the timer so the call isn't run
            if (aCall.timer) {
                clearTimeout(aCall.timer);
            }
            // Set the lock so the method isn't called
            aCall.cancelled = true;
            // check if the async function is running
            if (aCall.inCall) {
                // The async function is running - ask it to exit. It will set the promise result.
                aCall.aborter.abort(CANCELLED_WHILE_IN_CALL_REASON);
            } else {
                // the function is not running, the call is canceled, so set the error response to the promise
                aCall.rejector(options?.cancelError || 'Cancelled due to new request');
            }
        }
    }
}

/**
 * An error thrown if the scheduled function was cancelled before it was running.
 */
export class ScheduledCancelledError extends Error {
    constructor(m: string) {
        super(m);
        // Set the prototype explicitly.
        Object.setPrototypeOf(this, ScheduledCancelledError.prototype);
    }
}

const debounce = AsyncDebounce.debounce;
export default debounce;
