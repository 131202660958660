import { defineStore } from 'pinia';
import { isPopulated, validators } from '@/stores/spinopelvic/validation';
import { initialState } from '@/stores/spinopelvic/initialState';
import {
    DataState,
    setInError,
    withStateLoading,
    withStateSaving,
} from '@/stores/shared/dataState';
import assert from 'assert';
import { spinopelvicFeature } from '@/lib/headMetaTags';
import { cloneDeep, isEqual } from 'lodash';
import type { SpinopelvicState } from '@/stores/spinopelvic/types';
import { loadSpinopelvic, saveSpinopelvic } from '@/api/spinopelvic/services';
import type { SpinopelvicMeasurements } from '@/components/spinopelvic/types';
import { computed, reactive, ref } from 'vue';
import { isValid as validate } from '@/stores/validation';
import { taggedLogger } from '@/util';

const log = taggedLogger('spinopelvic-store');

function spinopelvicState(): SpinopelvicState {
    return {
        ...cloneDeep(initialState),
    };
}

export const useSpinopelvic = defineStore('spinopelvic-store', () => {
    const _state = reactive<SpinopelvicState>(spinopelvicState());

    const isEnabled = computed(() => spinopelvicFeature());
    const isValid = computed(() => validate(validators, _state.current));
    const isSaving = computed(() => {
        return _state.state === DataState.Saving;
    });
    const isDirty = computed(() => !isEqual(_state.lastSaved, _state.current));

    const hasData = computed(() => {
        const lastSaved = _state.lastSaved;
        return (
            isPopulated(lastSaved.standingPelvicTilt) &&
            isPopulated(lastSaved.pelvicFemoralAngle) &&
            isPopulated(lastSaved.lumbarLordosis) &&
            isPopulated(lastSaved.sacralSlope) &&
            isPopulated(lastSaved.pelvicTilt)
        );
    });

    const measurements = computed<SpinopelvicMeasurements>(() => {
        const lastSaved = _state.lastSaved;
        return {
            standingPelvicTilt: Number(lastSaved.standingPelvicTilt),
            sacralSlope: Number(lastSaved.sacralSlope),
            lumbarLordosis: Number(lastSaved.lumbarLordosis),
            pelvicFemoralAngle: Number(lastSaved.pelvicFemoralAngle),
            pelvicTilt: Number(lastSaved.pelvicTilt),
        };
    });

    const hasFieldError = (field: string): boolean => {
        const validator = validators[field];
        assert.ok(!!validator, `no validator for ${field}`);
        return !validator(_state.current);
    };

    const caseId = ref<number | null>(null);

    const _clearState = () => {
        log.info(`Clearing plan state for case ${caseId.value}...`);
        Object.assign(_state, spinopelvicState());
    };

    const load = async (id: number): Promise<void> => {
        caseId.value = id;
        _clearState();

        if (isEnabled.value) {
            await withStateLoading(_state, async () => {
                const data = await loadSpinopelvic(id);

                if (data) {
                    Object.assign(_state.current, data);
                    Object.assign(_state.lastSaved, cloneDeep(_state.current));
                }
            });
        }
    };

    const save = async (caseId: number) => {
        if (isEnabled.value) {
            await withStateSaving(_state, async () => {
                if (!isValid.value) {
                    setInError(_state, {
                        title: 'Please review your input',
                        message:
                            'Your changes were not saved. Please review the errors marked in red below and try again.',
                    });
                    return;
                }

                await saveSpinopelvic(caseId, _state.current);

                Object.assign(_state.lastSaved, cloneDeep(_state.current));
            });
        }
    };

    return {
        state: _state,
        isEnabled,
        isValid,
        isDirty,
        isSaving,
        hasData,
        measurements,

        $reset: _clearState,
        hasFieldError,
        load,
        save,
    };
});
