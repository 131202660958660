import type { SpinopelvicInputs } from '@/stores/spinopelvic/types';
import { mapToApiObject, mapToState } from '@/api/spinopelvic/mapping';
import { get, put } from '@/api/spinopelvic/request';

export async function loadSpinopelvic(caseId: number): Promise<SpinopelvicInputs | null> {
    const data = await get(caseId);

    if (data) {
        return mapToState(data);
    }

    return null;
}

export async function saveSpinopelvic(caseId: number, data: SpinopelvicInputs) {
    await put(caseId, mapToApiObject(data));
}
