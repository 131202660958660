import { defineStore } from 'pinia';
import { type ComponentPublicInstance, ref } from 'vue';
import { logger } from '@/util';
import Bugsnag from '@bugsnag/js';

const log = logger('app-error');

/**
 * A store for top-level error handling
 */
export const useAppErrorStore = defineStore('app-error', () => {
    const hasError = ref(false);

    function handleError(error: unknown): void {
        if (error instanceof Error) {
            log.error('App error:\n%s', error.stack ?? error);
            Bugsnag.notify(error);
        } else {
            log.error("App error '%s':", error);
            Bugsnag.notify(new Error(String(error)));
        }
        hasError.value = true;
    }

    return {
        /** The error flag: true if an unexpected error has occurred */
        hasError,
        handleError,
        /** Handle an error that originates from Vue, setting the error flag */
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        handleVueError: (error: unknown, _instance: ComponentPublicInstance | null, _info: string): void => {
            // TODO: check if we want to log more vue-specific info here?
            handleError(error)
        },
        /**
         * Catch errors that are thown by a sync or async function, setting the error state
         */
        catchErrors: (action: () => void | Promise<void>): void => {
            try {
                const result = action();
                if (result instanceof Promise) {
                    result.catch(handleError);
                }
            } catch (error) {
                handleError(error);
            }
        },
    };
});
