import {
    objectNode,
    type ObjectNode,
    updateObject,
} from '@/planner/3d/object';
import { type Group } from 'three';
import { stopAll, type StopHandle } from '@/util';
import { transformNode, type TransformNode, updateTransform } from '@/planner/3d/transform';

export type GroupNode = ObjectNode<'group'> & TransformNode;

export function groupNode(properties?: Partial<GroupNode>): GroupNode {
    return {
        ...objectNode('group', properties),
        ...transformNode(properties)
    }
}

export function updateGroup(node: GroupNode, group: Group): StopHandle {
    return stopAll(
        updateObject(node, group),
        updateTransform(node, group),
    )
}
