<template>
    <div class="d-flex flex-column">
        <app-button @click="settings.showDeveloperTools = false">
            Hide tools
        </app-button>
        <v-select v-model="settings.validationLogLevel"
                  label="Validation logging level"
                  :items="logLevels.filter(level => level !== 'warn')"
                  color="var(--input-blue)"
                  variant="underlined"
                  density="comfortable"
        />
        <v-checkbox v-model="settings.show3dFeatures"
                    label="Show 3d features"
                    color="var(--input-blue)"
                    density="comfortable"
        />
        <app-button @click="testError" color="var(--planner-red)">
            Test Error Handling
        </app-button>
        <v-checkbox v-model="settings.animateCameras"
                    label="Animate camera"
                    color="var(--input-blue)"
                    density="comfortable"
        />
    </div>
</template>

<script setup lang="ts">
    import { useDeveloperSettings } from '@/planner/developerSettings';
    import { logLevels } from '@/util';

    const settings = useDeveloperSettings();

    function testError() {
        throw new Error('Test error');
    }
</script>
