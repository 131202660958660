<template>
    <app-form-group label="Surgeon" input-id="case-surgeon-choices">
        <!-- The error in the surgeon is displayed even if the save button was not clicked yet        -->
        <v-select
            variant="outlined"
            density="compact"
            label="Select a surgeon"
            input-id="case-surgeon-choices"
            :model-value="store.specifications.surgeon.selected"
            @update:model-value="onUpdateSurgeon"
            :items="
                store.specifications.surgeon.options.map(({ name, id }) => ({
                    title: name,
                    value: id,
                }))
            "
            :disabled="store.hasStudy || store.isCaseReadonly"
            :error-messages="surgeonErrorMessage"
        >
        </v-select>
    </app-form-group>

    <app-form-section-title size="small">Stem</app-form-section-title>
    <app-form-subtitle>
        Only one stem system can be selected.
    </app-form-subtitle>
    <app-form-group label="Preferred System">
        <app-radio-group
                inline
                v-model="store.specifications.stems.preferredSystem"
                :error-messages="
                            showError('preferredSystem') ? 'Please select an option' : ''
                        "
        >
            <v-radio
                    v-for="item in stemSystems"
                    :value="item.key"
                    :key="item.key"
                    :label="item.label"
            />
        </app-radio-group>
    </app-form-group>

    <app-form-group label="Types">
        <app-checkbox-group>
            <v-checkbox
                v-model="store.specifications.stems.selected"
                :value="item.id"
                :key="item.id"
                v-for="(item, index) in availableStems"
                :label="`${item.type} - ${item.offset}`"
                density="compact"
                :disabled="areSurgeonSpecsDisabled"
                :hide-details="index !== store.specifications.stems.options.length - 1"
                :error-messages="showError('stems') ? 'Please select at least one stem' : ''"
            />
        </app-checkbox-group>
    </app-form-group>

    <app-form-group label="Leg Length Change" input-id="target-leg-length-input">
        <app-text-input
            suffix="mm"
            v-model="store.specifications.targets.legLength"
            input-id="target-leg-length-input"
            :disabled="areSurgeonSpecsDisabled"
            :error-messages="
                showError('targetLegLengthChange')
                    ? 'Please provide a number between -20 and 20'
                    : ''
            "
        />
    </app-form-group>

    <app-form-group label="Offset Change" input-id="target-offset-input">
        <app-text-input
            suffix="mm"
            v-model="store.specifications.targets.offset"
            input-id="target-offset-input"
            :disabled="areSurgeonSpecsDisabled"
            :error-messages="
                showError('targetOffsetChange') ? 'Please provide a number between -20 and 20' : ''
            "
        />
    </app-form-group>

    <app-form-section-title size="small">Cup</app-form-section-title>

    <app-form-group label="Inclination Angle" input-id="cup-inclination-angle">
        <app-text-input
            suffix="deg"
            v-model="store.specifications.cup.inclinationAngle"
            input-id="cup-inclination-angle"
            :disabled="areSurgeonSpecsDisabled"
            :error-messages="
                showError('cupInclinationAngle') ? 'Please provide a number between 0 and 60' : ''
            "
        />
    </app-form-group>

    <app-form-group label="Fitting mode">
        <app-radio-group
            inline
            v-model="store.specifications.cup.fitMethod"
            :disabled="areSurgeonSpecsDisabled"
            :error-messages="showError('cupFittingMode') ? 'Please provide an option' : ''"
        >
            <v-radio label="Auto" value="acid"></v-radio>
            <v-radio label="Fossa" value="fossa"></v-radio>
        </app-radio-group>
    </app-form-group>

    <app-form-group label="Anteversion mode">
        <app-radio-group
            inline
            v-model="store.specifications.cup.anteversion"
            :disabled="areSurgeonSpecsDisabled"
            :error-messages="showError('cupAnteversionMode') ? 'Please provide an option' : ''"
        >
            <v-radio label="Combined" value="auto"></v-radio>
            <v-radio label="Manual" value="manual"></v-radio>
        </app-radio-group>
    </app-form-group>

    <app-form-group
        label="Anteversion Angle"
        input-id="cup-anteversion-angle"
        class="mt-12"
        v-if="store.specifications.cup.anteversion === 'manual'"
    >
        <app-text-input
            suffix="deg"
            v-model="store.specifications.cup.anteversionAngle"
            input-id="cup-anteversion-angle"
            :disabled="areSurgeonSpecsDisabled"
            :error-messages="
                showError('cupAnteversionAngle') ? 'Please provide a number between -5 and 45' : ''
            "
        />
    </app-form-group>

    <app-form-group label="Alignment mode">
        <app-radio-group
            inline
            v-model="store.specifications.cup.alignment"
            :disabled="areSurgeonSpecsDisabled"
            :error-messages="showError('alignmentMode') ? 'Please select an alignment mode' : ''"
        >
            <v-radio label="Anterior Pelvic Plane" value="APP"></v-radio>
            <v-radio label="CT Scanner Coordinates" value="none"></v-radio>
        </app-radio-group>
    </app-form-group>
</template>

<script setup lang="ts">
    import { useCaseSettings } from '@/stores/caseSettings/store';
    import { computed, ref } from 'vue';
    import { stemSystems } from '@/types/stemSystems';

    const store = useCaseSettings();

    const isLoading = ref(false);
    const displaySurgeonError = ref(false);

    /**
     * The intention is that the surgeon can be changed even if the surgeon does not have preferences.
     * An error will be shown when if the new surgeon selected does not have preferences
     */
    const onUpdateSurgeon = async (surgeonId: number) => {
        displaySurgeonError.value = true;
        isLoading.value = true;
        await store.onUpdateSurgeon(surgeonId);
        isLoading.value = false;
    };

    const surgeonErrorMessage = computed((): string => {
        if (store.displayErrors) {
            if (store.hasFieldError('surgeon')) {
                if (store.case.specifications.surgeon.error) {
                    return SURGEON_ERROR_MESSAGE;
                }

                const selected = store.case.specifications.surgeon.selected;
                if (selected === null) {
                    return 'Please select a surgeon';
                }
            }
        }

        return '';
    });

    const SURGEON_ERROR_MESSAGE =
        'Selected surgeon does not have saved surgical preferences. Please select a different surgeon.';

    function showError(field: string): boolean {
        if (!store.displayErrors) return false;

        return store.hasFieldError(field);
    }

    const areSurgeonSpecsDisabled = computed(() => {
        return !store.isSpecificationsEnabled || store.hasStudy || store.isCaseReadonly;
    });

    const availableStems = computed(() => {
        return store.specifications.stems.options.filter((object: any) =>
            object.system === store.specifications.stems.preferredSystem);
    });
</script>
