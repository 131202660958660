<template>
    <v-expansion-panels v-model="openPanel" accordion flat>
        <v-expansion-panel>
            <v-expansion-panel-title class="spinopelvic-panel-header justify-self-start pa-0">
                <div>
                    Hip-Spine Assessment
                    <span v-if="warnings?.hasWarnings && spinopelvic.hasData" class="ml-2 mb-2">
                        <v-icon size="25" color="planner-yellow">mdi-alert</v-icon>
                        <v-tooltip activator="parent">
                            <span> There are spinopelvic calculation warnings </span>
                        </v-tooltip>
                    </span>
                </div>
            </v-expansion-panel-title>

            <v-expansion-panel-text class="px-4 spinopelvic-expand-panel">
                <SpinopelvicWarningsTable
                    v-if="warnings && calculations && spinopelvic.hasData"
                    :warnings="warnings"
                    :calculations="calculations"
                />

                <SpinopelvicDialog
                    v-if="warnings && calculations"
                    :calculations="calculations"
                    :warnings="warnings"
                    :cup-inclination="cupInclination"
                    :cup-anteversion="cupAnteversion"
                />

                <app-form-group
                    class="spinopelvic-form-control"
                    label="Spinopelvic Tilt"
                    size="fluid"
                    optional
                    optional-text="if known"
                >
                    <app-text-input
                        v-model="state.current.standingPelvicTilt"
                        cy-data="case-spinopelvic-standing-pelvic-tilt"
                        suffix="deg"
                        :disabled="spinopelvic.isSaving"
                        :error-messages="
                            showError('standingPelvicTilt') ? INPUT_FIELD_ERROR_MSG : ''
                        "
                    />
                </app-form-group>

                <app-form-group
                    class="spinopelvic-form-control"
                    label="Pelvic Femoral Angle"
                    size="fluid"
                    optional
                    optional-text="if known"
                >
                    <app-text-input
                        v-model="state.current.pelvicFemoralAngle"
                        cy-data="case-spinopelvic-pelvic-femoral-angle"
                        suffix="deg"
                        :disabled="spinopelvic.isSaving"
                        :error-messages="showError('pelvicFemoralAngle') ? INPUT_FIELD_ERROR_MSG : ''"
                    />
                </app-form-group>

                <app-form-group
                    class="spinopelvic-form-control"
                    label="Lumbar Lordosis"
                    size="fluid"
                    optional
                    optional-text="if known"
                >
                    <app-text-input
                        v-model="state.current.lumbarLordosis"
                        cy-data="case-spinopelvic-lumbar-lordosis"
                        suffix="deg"
                        :disabled="spinopelvic.isSaving"
                        :error-messages="showError('lumbarLordosis') ? INPUT_FIELD_ERROR_MSG : ''"
                    />
                </app-form-group>

                <app-form-group
                    class="spinopelvic-form-control"
                    label="Sacral Slope"
                    size="fluid"
                    optional
                    optional-text="if known"
                >
                    <app-text-input
                        v-model="state.current.sacralSlope"
                        cy-data="case-spinopelvic-sacral-slope"
                        suffix="deg"
                        :disabled="spinopelvic.isSaving"
                        :error-messages="showError('sacralSlope') ? INPUT_FIELD_ERROR_MSG : ''"
                    />
                </app-form-group>

                <app-form-group
                    class="spinopelvic-form-control"
                    label="Pelvic Tilt"
                    size="fluid"
                    optional
                    optional-text="if known"
                >
                    <app-text-input
                        v-model="state.current.pelvicTilt"
                        cy-data="case-spinopelvic-pelvic-tilt"
                        suffix="deg"
                        :disabled="spinopelvic.isSaving"
                        :error-messages="showError('pelvicTilt') ? INPUT_FIELD_ERROR_MSG : ''"
                    />
                </app-form-group>

                <v-container class="d-flex justify-end" style="padding-right: 0">
                    <app-button
                        :disabled="spinopelvic.isSaving || !spinopelvic.isDirty"
                        colour="blue-button"
                        @click="onSave()"
                    >
                        Update Spinopelvic
                    </app-button>
                    <v-tooltip right activator="parent">
                        <span> Update spinopelvic data and recalculate analysis </span>
                    </v-tooltip>
                </v-container>
            </v-expansion-panel-text>
        </v-expansion-panel>
    </v-expansion-panels>
</template>
<script lang="ts" setup>
    import { computed, onMounted, ref, watch } from 'vue';
    import assert from 'assert';
    import SpinopelvicWarningsTable from '@/components/spinopelvic/SpinopelvicWarningsTable.vue';
    import {
        type SpinopelvicCalculations,
        type SpinopelvicWarnings,
    } from '@/components/spinopelvic/types';
    import { makeSpinoPelvicCalculations } from '@/components/spinopelvic/calculations';
    import { makeSpinopelvicWarnings } from '@/components/spinopelvic/warnings';
    import { useSpinopelvic } from '@/stores/spinopelvic/store';
    import { usePlannerStore } from '@/planner/plannerStore';
    import { useCupOverlayStore } from '@/planner/cupOverlayStore';
    import SpinopelvicDialog from '@/planner/components/spinopelvic/SpinopelvicDialog.vue';
    import { useRoute } from 'vue-router';
    import { verify } from '@/lib/verify';
    import { storeToRefs } from 'pinia';
    // import { trackEvent } from '@/analytics/amplitude';
    // import { caseEventProperties } from '@/analytics/event';

    const INPUT_FIELD_ERROR_MSG = 'Please provide a number (2dp). All values must be entered';

    const route = useRoute();
    const plannerStore = usePlannerStore();
    const spinopelvic = useSpinopelvic();

    const { state } = storeToRefs(spinopelvic);

    // TODO: The UX is a bit weird. These variables are initialized as null and then set on mounted
    //       Ideally they should be computed properties and that would make the code cleaner.
    //       The issue is that the current design is that  calculations and warnings are only updated
    //       after an explicit save to the api.
    const calculations = ref<SpinopelvicCalculations | null>(null);
    const warnings = ref<SpinopelvicWarnings | null>(null);
    const openPanel = ref<number | null>(null);

    const cupInclination = computed(() => {
        return verify(useCupOverlayStore().inclination, 'cup inclination not set.');
    });

    const cupAnteversion = computed(() => {
        return verify(useCupOverlayStore().anteversion, 'cup anteversion not set.');
    });

    const calculate = () => {
        assert(spinopelvic.isEnabled, 'Spinopelvic feature must be enabled');
        const alignmentMode = plannerStore.alignmentMode;
        const landmarks = plannerStore.hipSpineLandmarks;
        const inclination = cupInclination.value;
        const anteversion = cupAnteversion.value;

        assert(!!landmarks, 'Landmarks not set.');
        assert(!!alignmentMode, 'Alignment mode not set.');
        assert(inclination !== null, 'Cup Inclination not set.');
        assert(anteversion !== null, 'Cup Anteversion not set.');

        calculations.value = makeSpinoPelvicCalculations(
            landmarks,
            spinopelvic.measurements,
            alignmentMode,
        );

        warnings.value = makeSpinopelvicWarnings(
            inclination,
            anteversion,
            spinopelvic.measurements,
            calculations.value,
        );
    };

    const onSave = async () => {
        const caseIdParam = verify(route.params.id, 'case id is required');
        const caseId = Number(caseIdParam);
        await spinopelvic.save(caseId);
        // if (spinopelvicStore.isSubmitted) {
        //     trackEvent('spinopelvic_measurements_saved', {
        //         ...caseEventProperties(hipTemplateStore.project),
        //     });
        // }
    };

    function showError(field: string): boolean {
        return spinopelvic.hasFieldError(field);
    }

    onMounted(calculate);

    watch([() => spinopelvic.measurements, () => plannerStore.template?.cupRotation], calculate);
</script>

<style scoped lang="scss">
    .spinopelvic-expand-panel {
        :deep(label) {
            width: 100px;
            font-size: 12px;
        }

        :deep(span.error-msg) {
            font-size: 12px;
        }
    }

    :deep(.form-group-content) {
        min-width: unset !important;
    }
</style>
