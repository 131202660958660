<template>
    <div class="adjustments">
        <div class="title">Adjustments</div>
        <div class="group" v-for="(text, label) in adjustments" :key="`${label}-${text}`">
            <div class="group-value">{{ text }}</div>
            <div class="group-label">{{ label }}</div>
        </div>
    </div>
    <div class="measurements">
        <div class="title">Native Measurements</div>
        <div class="group" v-for="(text, label) in nativeMeasurements" :key="`${label}-${text}`">
            <div class="group-value">{{ text }}</div>
            <div class="group-label">{{ label }}</div>
        </div>
    </div>
</template>

<script setup lang="ts">
    import { formatPercentageNumber } from '@/lib/format/formatPercentageNumber';
    import { computed } from 'vue';
    import { formatAngle } from '@/lib/format/formatAngle';
    import { usePlannerStore } from '@/planner/plannerStore';
    import { useCupOverlayStore } from '@/planner/cupOverlayStore';
    import { useStemOverlayStore } from '@/planner/stemOverlayStore';
    import { formatLengthSign } from '@/lib/format/formatLengthSign';

    const plannerStore = usePlannerStore();
    const cupStore = useCupOverlayStore()
    const stemStore = useStemOverlayStore();

    function formattedCoverage(): string {
        const coverage = cupStore.coverage;
        return coverage === null || coverage === 'calculating'
            ? '--'
            : formatPercentageNumber(coverage);
    }

    const adjustments = computed(() => ({
        'Stem atv.': formatAngle(stemStore.stemAnteversion),
        'Leg length': formatLengthSign(plannerStore.adjustments?.legLength),
        'Leg offset': formatLengthSign(plannerStore.adjustments?.offset),
        'Cup Atv.': formatAngle(cupStore.anteversion),
        'Cup Inc.': formatAngle(cupStore.inclination),
        'Cup coverage': formattedCoverage(),
    }));

    const nativeMeasurements = computed(() => ({
        'Hip Length Diff.': formatLengthSign(plannerStore.nativeMeasurements.hipLengthDifference),
        'Femur Atv.': formatAngle(plannerStore.nativeMeasurements.femoralAnteversion),
        'Acetabular Atv.': formatAngle(plannerStore.nativeMeasurements.acetabularAnteversion),
        'Acetabular Inc.': formatAngle(plannerStore.nativeMeasurements.acetabularInclination),
    }));
</script>

<style scoped>
    .title {
        font-size: 0.75rem;
        font-weight: 500;
        letter-spacing: 0.1666666667em;
        line-height: 2rem;
        text-transform: uppercase;
        color: var(--planner-white);
        width: 100%;
    }

    .adjustments,
    .measurements {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 40px;
    }

    .measurements {
        .group {
            flex-basis: 50%;
        }
    }

    .group {
        display: flex;
        flex-direction: column;
        flex-basis: 33%;
        margin-bottom: 10px;
    }

    .group-value {
        font-weight: 700;
        font-size: 1rem;
        letter-spacing: 0.009375em;
        line-height: 1.75rem;
        color: var(--planner-white);
    }

    .group-label {
        font-size: 0.75rem;
        letter-spacing: 0.0333333333em;
        line-height: 1.25rem;
        color: var(--planner-grey-inverted);
    }
</style>
