import { vector3 } from '@/geometry/vector3';
import { angleInPlanarProjection } from '@/geometry/angle';
import { logCalculation } from '@/planner/logValidation';
import type { PlannerStore } from '@/planner/plannerStore';
import LPS, { type LpsBasis } from '@/formus/anatomy/LPS';
import type { Adjustments } from '@/planner/plannerState';
import type { BodySide } from '@/formus/anatomy/side';
import type { Vector3 } from 'three';

export function calculateStemAnteversion(planner: PlannerStore): number | null {
    if (!planner.femoralFeatures || planner.isLoading) {
        return null;
    } else {
        const posterior = planner.femoralFeatures.shaftBasis.posterior;
        const condylarAxis = vector3(planner.femoralFeatures.anteversionCondylar);
        const { stemGroup, stemNeckAxis } = planner.scene;
        const stemAxis = stemNeckAxis.direction.clone().transformDirection(stemGroup.transform);
        const result = angleInPlanarProjection(stemAxis, condylarAxis, posterior.clone().negate());

        logCalculation('Stem anteversion:', [
            ['stem-neck-axis', stemAxis],
            ['posterior-direction', posterior],
            ['condylar-axis', condylarAxis],
            ['anteversion', result],
        ]);

        return result;
    }
}

/**
 * Calculate the combined change to leg-length and offset relative to the preoperative state of the
 * femur.
 *
 * A positive leg-length-change implies the post-operative femur will be inferior relative
 * to its pre-operative position.
 *
 * A positive offset-change implies the post-operative femur will be lateral relative to its
 * pre-operative position.
 *
 * @param operativeSide The operative side for which the calculation is being made
 * @param cupBasis The basis used to place the cup CT/APP aligned
 * @param headPosition The templated position (HJC) implied by the femoral components in native configuration
 *   (when the femur is in native position and the stem is fitted to the femur)
 * @param cupPosition The templated position (HJC) of the cup
 */
export function calculateLegLengthAndOffset(
    operativeSide: BodySide,
    cupBasis: LpsBasis,
    cupPosition: Vector3,
    headPosition: Vector3,
): Adjustments {
    const nativeHJC = cupBasis.position;

    // The vector from the native hip-joint-centre to the stem head, which is the centre-of-rotation
    // of the templated femoral component
    const hjcToStemHead = headPosition.clone().sub(nativeHJC);

    // The vector from the native hip-joint-centre to the cup : the centre-of-rotation of the templated cup
    const hjcToCup = cupPosition.clone().sub(nativeHJC);

    // Calculate leg-length and offset changes due to the femoral component (stem and head)
    // LL and offset changes due to the femoral component are always calculated in CT coordinates

    // Leg-length increases as the templated-hjc of the stem moves in a superior direction
    const femoralLegLengthChange = hjcToStemHead.dot(LPS.Superior);

    // Offset increases as the templated-hjc of the stem moves in a medial direction
    const femoralOffsetChange = hjcToStemHead.dot(LPS.medial(operativeSide));

    // Calculate leg-length and offset changes due to the acetabular component (cup and liner)
    // LL and offset changes due to the acetabular component are calculated in coordinates that correspond
    // to the current alignment-mode
    const alignedInferior = cupBasis.superior.clone().negate();
    const alignedLateral = operativeSide === 'left' ? cupBasis.left.clone() : cupBasis.left.clone().negate();

    // Leg-length increases as the templated-hjc of the cup moves in an inferior direction
    const acetabularLegLengthChange = hjcToCup.dot(alignedInferior);

    // Offset increases as the templated-hjc of the stem moves in a lateral direction
    const acetabularOffsetChange = hjcToCup.dot(alignedLateral);

    const result = {
        legLength: femoralLegLengthChange + acetabularLegLengthChange,
        offset: femoralOffsetChange + acetabularOffsetChange,
    };

    logCalculation('Femoral leg-length and offset:', [
        ['stem-head', headPosition],
        ['native-hjc', nativeHJC],
        ['native-hjc to stem-head', hjcToStemHead],
        ['femoral leg-length change', femoralLegLengthChange],
        ['femoral offset change', femoralOffsetChange],
    ]);
    logCalculation('Acetabular leg-length and offset:', [
        ['cup', cupPosition],
        ['native hjc', nativeHJC],
        ['native hjc to cup', hjcToCup],
        ['aligned inferior', alignedInferior],
        ['aligned lateral', alignedLateral],
        ['acetabular leg-length change', acetabularLegLengthChange],
        ['acetabular offset change', acetabularOffsetChange],
    ]);
    logCalculation('Combined leg-length and offset:', [
        ['leg-length change', result.legLength],
        ['offset-change', result.offset],
    ]);

    return result;
}
