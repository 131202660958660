import {
    type ColorRepresentation,
    PerspectiveCamera,
    type Renderer,
    type Scene,
    WebGLRenderer,
} from 'three';
import { stopAll, type StopHandle } from '@/util';
import {
    type PerspectiveCameraNode,
    updatePerspectiveCamera,
} from '@/planner/3d/perspectiveCamera';
import { updateViewport } from '@/planner/3d/updateViewport';

const DEFAULT_CLEAR_COLOR = '#262625' as const;

type BeforeRenderCallback = () => void;

export type RenderingContext = {
    container: HTMLElement;
    canvas: HTMLCanvasElement;
    renderer: Renderer;
    scene: Scene;
    camera: PerspectiveCamera;
    cameraNode: PerspectiveCameraNode;
    beforeRender: (callback: BeforeRenderCallback) => void;
};

/**
 * Create a three-js renderer for the planner
 */
export function updateRendering(
    container: HTMLElement,
    canvas: HTMLCanvasElement,
    scene: Scene,
    cameraState: PerspectiveCameraNode,
    options?: { clearColor?: ColorRepresentation },
): StopHandle {
    const renderer = new WebGLRenderer({
        alpha: true,
        stencil: false,
        antialias: true,
        canvas: canvas,
    });

    const camera = new PerspectiveCamera();

    // Allow cross-section planes by enabling local clipping
    // https://threejs.org/docs/#api/en/renderers/WebGLRenderer.localClippingEnabled
    renderer.localClippingEnabled = true;
    renderer.setClearColor(options?.clearColor ?? DEFAULT_CLEAR_COLOR);
    const callbacks: BeforeRenderCallback[] = [];

    renderer.setAnimationLoop(() => {
        callbacks.forEach(c => c());
        renderer.render(scene, camera);
    });

    const context: RenderingContext = {
        container,
        canvas,
        renderer,
        scene,
        camera,
        cameraNode: cameraState,
        beforeRender: (callback) => callbacks.push(callback),
    }

    return stopAll(
        updateViewport(context),
        updatePerspectiveCamera(context),
        () => cleanupRenderer(renderer),
    );
}

/**
 *  Stop rendering and cleanup resources
 *
 *  See post from one of the main three.js contributors, on 13th August 2020:
 *   - https://discourse.threejs.org/t/how-to-completely-clean-up-a-three-js-scene-from-a-web-app-once-the-scene-is-no-longer-needed/1549/15
 *
 *  Also in the main three.js docs:
 *    - https://threejs.org/docs/?q=dispos#manual/en/introduction/How-to-dispose-of-objects
 */
function cleanupRenderer(renderer: WebGLRenderer) {
    renderer.dispose();
    renderer.forceContextLoss();
}
