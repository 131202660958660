import { type BufferGeometry, LoaderUtils, LoadingManager } from 'three';
import type { AxiosResponse } from 'axios';
import { PLYLoader } from 'three/examples/jsm/loaders/PLYLoader.js';
import type { Url } from '@/formus/types';
import { client } from '@/api/http';

/**
 * Get data assuming it is a 3d-model in PLY format
 *
 * @see https://en.wikipedia.org/wiki/PLY_(file_format)
 */
export async function getPlyModel(url: Url, abort: AbortSignal): Promise<BufferGeometry> {
    // TODO: look into whether we do actually want to do all the cache mutation stuff
    //   (See AxiosBrowserCacheUrlMutation)
    const response: AxiosResponse<ArrayBuffer | string> = await client.get(url, {
        headers: {
            accept: 'model/*,*/*;q=0.5',
        },
        responseType: 'arraybuffer',
        signal: abort,
    });
    if (response.status === 200) {
        verifyPlyResponse(response);
        const geometry: BufferGeometry = transformPlyData(response.data);
        geometry.computeVertexNormals();
        geometry.computeBoundingSphere();
        geometry.computeBoundingBox();
        return geometry;
    } else {
        throw new Error(`Failed to load ply model from ${url} (${response})`);
    }
}

/**
 * Mime types that indicate data in PLY format
 *
 * @see https://en.wikipedia.org/wiki/PLY_(file_format)
 */
const plyMimeTypes = [
    'model/ply',
    'model/ply+ascii',
    'model/ply+binary',

    /**
     * There is no IANA defined mime/content type for PLY files. This defines a user defined
     * type with an 'x' prefix.
     *
     * Note: Although it would be best practice to use the 'X' prefix, the Formus code base
     * is going to use the raw form of the content type without the 'x' prefix.
     */
    'model/x.ply',
    'model/x.ply+ascii',
    'model/x.ply+binary',
];

function verifyPlyResponse(response: AxiosResponse): void {
    if (!('content-type' in response.headers)) {
        throw Error('Ply-model response has no content-type');
    }

    const contentType = response.headers['content-type'];
    if (!plyMimeTypes.includes(contentType)) {
        throw Error(
            `Ply-model response has invalid content-type ${contentType}. ` +
                `Expected one of: ${plyMimeTypes.join(', ')}`,
        );
    }
}

/**
 * Load PLY data from a string or ArrayBuffer into a BufferGeometry
 */
function transformPlyData(data: unknown): BufferGeometry {
    if (typeof data === 'string') {
        checkHeader(data);
        const loader = new PLYLoader(new LoadingManager());
        return loader.parse(data);
    } else if (data instanceof ArrayBuffer) {
        checkHeader(LoaderUtils.decodeText(new Uint8Array(data)));
        const loader = new PLYLoader(new LoadingManager());
        return loader.parse(data);
    } else {
        throw Error(
            `Ply-model data is of type '${typeof data}' - expected a string or ArrayBuffer`,
        );
    }
}

/**
 * The PLY data appears to being corrupted when we load files in parallel. The most obvious
 * symptom of the corruption seems to be that the header-lines are arriving out of order,
 * notably including the PLY header (first line starts with 'ply')
 */
function checkHeader(data: string) {
    // The PLY file **must** start with a 'ply' (lower case) prefix
    if (!data.startsWith('ply')) {
        throw Error(`Ply data has a bad header:\n ${data.substring(0, 300)}`);
    }
}
