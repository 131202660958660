import type { PlannerOperationId } from '@/planner/plannerState';
import { taggedLogger } from '@/util';

const log = taggedLogger('execute-operation');

export type PlannerOpState = {
    currentOperation: PlannerOperationId | null;
}

/** Set the current-operation value if not currently aborted or in error */
function setCurrentOperation(state: PlannerOpState, operation: PlannerOperationId | null) {
    if (state.currentOperation === 'error' || state.currentOperation === 'aborted') {
        return;
    } else {
        state.currentOperation = operation;
    }
}

/**
 * Catch errors that happen during execution (see execute and executeOperation) and show the error screen.
 *
 * This flag should be true in deployed environments. Setting it to false can allow more detailed
 * error information which helps debugging.
 */
const CATCH_ERRORS = true;

/** An 'error' that is raised when planner operations and actions are aborted. */
export class PlannerAbortError extends Error {
    constructor(message?: string) {
        super(message);
        this.name = 'PlannerAbortError';
        Object.setPrototypeOf(this, PlannerAbortError.prototype);
    }
}

/**
 * Execute some blocking 'operation'. This should be invoked directly from a UI interaction, and
 * will block other actions until the operation is complete.
 *
 * - Only one operation can be in execution at once
 * - PlannerAbortErrors that are thrown by the operation will set the 'aborted' operation state
 * - Other Errors thrown by the operation will set the 'error' operation state
 *
 * @param state the planner-state
 * @param operationId an identifier for the operation
 * @param operation the action to execute
 */
export async function executeOperation(
    state: PlannerOpState,
    operationId: PlannerOperationId,
    operation: () => void | Promise<void>,
): Promise<void> {
    const currentOp = state.currentOperation;
    // Check that we are not already in an operation
    if (currentOp !== null) {
        if (currentOp === 'error') {
            log.error('Ignoring operation \'%s\' owing to error', operationId);
        } else if (currentOp === 'aborted') {
            log.warn('Ignoring operation \'%s\': aborted', operationId, currentOp);
        } else {
            log.error(
                'Cannot execute operation \'%s\': already executing \'%s\'',
                operationId,
                currentOp,
            );
        }
        return;
    }

    const execute = async () => {
        setCurrentOperation(state, operationId);
        await operation();
        setCurrentOperation(state, null);
    };

    if (CATCH_ERRORS) {
        // Execute and catch any errors.
        // This should be the default unless explicitly disabled.
        try {
            await execute();
        } catch (e) {
            if (e instanceof PlannerAbortError) {
                log.info('Aborted operation \'%s\': %s', operationId, e.message);
                setCurrentOperation(state, 'aborted');
            } else if (e instanceof Error) {
                log.error('%s in operation \'%s\': %s', e.name, operationId, e.message);
                setCurrentOperation(state, 'error');
            } else {
                log.error('Error in operation \'%s\': %s', operationId, e);
                setCurrentOperation(state, 'error');
            }
        }
    } else {
        // Execute without catching errors.
        // This can make errors easier to trace when running with a debugger attached.
        await execute();
    }
}
