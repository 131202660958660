import { Color, type ColorRepresentation, Material, ShaderMaterial, Vector3 } from 'three';
export type ColoredMaterial = Material & { color: Color };

export type HexColor = number | string;

export function isHexColor(value: unknown): value is HexColor {
    return typeof value === 'number' || typeof value === 'string';
}

/** Coerces a value into a hex-color */
export function toHexColor(value: unknown): HexColor {
    if (isHexColor(value)) {
        return value;
    } else if (value instanceof Color) {
        return value.getHexString();
    } else {
        return toColor(value).getHexString();
    }
}

/** Returns a random color as a hex-code */
export function randomColor(): number {
    return Math.random() * 0xffffff;
}

/** Coerces a value into a {@link Color} */
export function toColor(value: unknown): Color {
    if (value instanceof Color) {
        return value.clone();
    } else if (value instanceof Vector3) {
        return new Color(value.x, value.y, value.z);
    } else if (typeof value === 'number' || typeof value === 'string') {
        return new Color(value);
    } else {
        throw new Error(`Cannot convert value of type ${typeof value} to color`);
    }
}

/** Get the 'main' (e.g. diffuse) color of a {@link Material} */
export function getColor(material: Material): Color {
    if (material instanceof ShaderMaterial) {
        if (material.uniforms?.uColor?.value) {
            return toColor(material.uniforms.uColor?.value);
        } else if (material.uniforms.diffuse?.value) {
            return toColor(material.uniforms.diffuse.value);
        } else {
            throw new Error('Trying to get color of shader material without color uniform');
        }
    } else if (hasColor(material)) {
        return material.color;
    } else {
        throw new Error('Trying to get color of material without color property');
    }
}

/**
 * Set the 'main' (e.g. diffuse) color of a {@link Material}
 *
 * Currently supported Threejs material types:
 *  - Material, MeshBasicMaterial, MeshPhongMaterial, ShaderMaterial
 */
export function setColor(material: Material | Material[], color: ColorRepresentation): void {
    color = new Color(color);
    if (!Array.isArray(material)) {
        // Changing material color can be found in different places,
        // so check where the property is located and change it accordingly
        if (material instanceof ShaderMaterial) {
            // uColor value is a Vector3, not Color
            material.uniforms?.uColor?.value?.set(color.r, color.g, color.b);
            material.uniforms?.diffuse?.value?.setRGB(color.r, color.g, color.b);
            // let the renderer know that the material uniforms have been updated
            material.uniformsNeedUpdate = true;
        } else if (hasColor(material)) {
            material.color = color;
        } else {
            throw new Error('Trying to set color of material without color property');
        }
        // let the renderer know that the material has been updated
        material.needsUpdate = true;
    } else {
        throw new Error('Cannot update material color. Materials array currently not supported');
    }
}

function hasColor(material: Material): material is ColoredMaterial {
    return 'color' in material;
}
