import { assert, stopAll, type StopHandle, taggedLogger } from '@/util';
import { objectNode, type ObjectNode } from '@/planner/3d/object';
import {
    CanvasTexture,
    DoubleSide,
    LinearFilter,
    LinearMipMapLinearFilter,
    MathUtils,
    Mesh,
    MeshBasicMaterial,
    Object3D,
    PlaneGeometry,
    RepeatWrapping,
    Vector3,
} from 'three';
import { zero3 } from '@/geometry/vector3';
import type { PlannerState, ScanState } from '@/planner/plannerState';
import { watch } from 'vue';
import { verify } from '@/lib/verify';
import type { ApiCaseCatStack } from '@/api/cat-stacks/types';
import { loadImageAsUrl } from '@/api/cat-stacks/loadImage';
import type { Canvas2dOffset } from '@/planner/cat-stack/types';

const log = taggedLogger('cat-stack-image');

type CatStackName = 'axial' | 'coronal';

export type CatStackImageNode = ObjectNode<'cat-stack-image'> & {
    name: CatStackName;
    position: Vector3;
    distance: number;
    src: string | null;
    image: HTMLImageElement | null;
    canvas: HTMLCanvasElement | null;
};

export function catStackImageNode(properties?: Partial<CatStackImageNode>): CatStackImageNode {
    assert(properties?.name, 'cat stack image name');
    return {
        ...objectNode('cat-stack-image', properties),
        name: properties.name,
        canvas: null,
        image: null,
        src: '',
        position: properties?.position ?? zero3(),
        distance: 0,
    };
}

export function updateCatStackImage(
    state: PlannerState,
    node: CatStackImageNode,
    plane: Object3D,
): StopHandle {
    return stopAll(
        updateSrc(node),
        updateImage(state, node, plane),
        updateSlice(state, node, plane),
        updateVisibility(state, node, plane),
    );
}

const loadImage = async (
    src: string,
    node: CatStackImageNode,
    onLoad: (image: HTMLImageElement) => any,
) => {
    const dataUrl = await loadImageAsUrl(src);
    log.debug('Loading image for %s to %s', node.name ?? '?', src);
    const image = new Image();
    image.src = verify(dataUrl, 'no data url');
    // Add an event listener to execute when the catstack image is loaded
    image.addEventListener('load', onLoad(image), false);
    return image;
};

export function updateSrc(node: CatStackImageNode): StopHandle {
    return watch(
        () => node.src,
        async (src) => {
            assert(src, 'cat stack image src');
            await loadImage(src, node, (image) => {
                log.debug('Canvas image %s loaded');
                // image.src = file.dataUrl;
                node.image = image;
            });
        },
    );
}

export function updateImage(
    state: PlannerState,
    node: CatStackImageNode,
    plane: Object3D,
): StopHandle {
    return watch(
        () => node.image,
        () => {
            const scan = verify(state.scans![node.name], 'no cat stack data');
            log.debug('Canvas image %s loaded', node.name ?? '?');
            _configure(scan, node, plane);
        },
    );
}

export function updateSlice(
    state: PlannerState,
    node: CatStackImageNode,
    plane: Object3D,
): StopHandle {
    return watch(
        () => {
            if (state.scans) {
                const scanState = verify(state.scans![node.name], `no cat stack ${node.name}`);
                return scanState.slider.value;
            }
            return undefined;
        },
        (sliderValue) => {
            if (node.image && state.visibility) {
                assert(sliderValue !== undefined);
                log.debug('Slider updated %s loaded', node.name ?? '?');

                const scanState = verify(state.scans![node.name], `no cat stack ${node.name}`);
                _moveToSlice(scanState, node, plane, sliderValue);
            } else {
                log.debug('Skipping slice update for %s', node.name ?? '?');
            }
        },
    );
}

export function updateVisibility(
    state: PlannerState,
    node: CatStackImageNode,
    plane: Object3D,
): StopHandle {
    return watch(
        () => {
            return state.scans?.visible;
        },
        (visibility) => {
            plane.visible = visibility ?? false;
            if (visibility) {
                // when rendering the plane, update the slice. This will address the first render position.
                const scanState = verify(state.scans![node.name], `no cat stack ${node.name}`);
                _moveToSlice(scanState, node, plane, scanState.slider.value);
            } else {
                log.debug('Skipping visibility for %s', node.name ?? '?');
            }
        },
    );
}

/**
 * Get the canvas offset from a given slice value
 *
 * We use the returned offset to move the catstack image (the full sprite image that contains
 * all the individual CT images) of the canvas, so only the desired CT slice is visible in the canvas
 */
export function _getCanvasOffsetFromSlicePosition(
    catStack: ApiCaseCatStack,
    currentSlice: number,
): Canvas2dOffset {
    const slice = catStack.slice;
    const image = catStack.image;

    const total = catStack.count;
    const sliceWidth = slice.width;
    const sliceHeight = slice.height;
    const rows = image.height / slice.height;

    const currentColumn = Math.ceil((total - currentSlice + 1) / rows);
    const currentRow = total - currentSlice + 1 - rows * (currentColumn - 1);

    const slicePositionX = -sliceWidth * (currentColumn - 1);
    const slicePositionY = -sliceHeight * (currentRow - 1);

    log.debug(
        `getCanvasOffsetFromSlicePosition catstack for \n` +
        `currentSlice: ${currentSlice} \n` +
        `sliceData.rows: ${rows} \n` +
        `sliceData.currentColumn: ${currentColumn} \n` +
        `sliceData.currentRow: ${currentRow} \n` +
        `sliceData.slicePositionX: ${slicePositionX} \n` +
        `sliceData.slicePositionY: ${slicePositionY} \n` +
        `x: ${slicePositionX / -1} \n` +
        `y: ${slicePositionY / -1} \n`,
    );

    return {
        x: slicePositionX / -1,
        y: slicePositionY / -1,
    };
}

/**
 * Make material for the catstack plane mesh
 */
function _makePlaneMeshMaterial(canvas: HTMLCanvasElement): MeshBasicMaterial {
    const canvasTexture = new CanvasTexture(canvas);
    canvasTexture.needsUpdate = true;

    const canvasMaterial = new MeshBasicMaterial({
        map: canvasTexture,
        side: DoubleSide,
    });

    // Material map is always there, but it's optional so we must check if it's set
    if (canvasMaterial.map) {
        // canvasMaterial.map.anisotropy = renderer.capabilities.getMaxAnisotropy();

        // Filters
        canvasMaterial.map.magFilter = LinearFilter;
        canvasMaterial.map.minFilter = LinearMipMapLinearFilter;

        // Flip the image horizontally
        canvasMaterial.map.wrapS = canvasMaterial.map.wrapT = RepeatWrapping;
        // canvasMaterial.map.repeat.x = - 1;
        canvasMaterial.map.needsUpdate = true;
    }
    return canvasMaterial;
}

/**
 * Make a canvas element that will be attached to the catstack plane
 */
export function _makeCanvasElement(catStackItem: ApiCaseCatStack): HTMLCanvasElement {
    const canvasElement = document.createElement('canvas');

    // canvas width and height require sizes to be power of two
    canvasElement.width = MathUtils.ceilPowerOfTwo(catStackItem.world.width);
    canvasElement.height = MathUtils.ceilPowerOfTwo(catStackItem.world.height);

    return canvasElement;
}

function _canvasRedraw(
    state: ScanState,
    node: CatStackImageNode,
    plane: Object3D,
    currentSliceIndex: number,
): void {
    assert(node.image, 'node image not loaded');
    assert(node.canvas, 'no canvas element not define');

    const canvasOffset = _getCanvasOffsetFromSlicePosition(state.catStack, currentSliceIndex);

    const canvas = node.canvas;
    const ctx = canvas.getContext('2d') as CanvasRenderingContext2D;

    log.debug(
        `re-drawing catstack canvas for ${node.name} plane. Parameters: \n` +
            `offsetX: ${canvasOffset.x} \n` +
            `offsetY: ${canvasOffset.y} \n` +
            `sliceWidth: ${state.catStack.slice.width} \n` +
            `sliceHeight: ${state.catStack.slice.height} \n` +
            `dx: 0 \n` +
            `dy: 0 \n` +
            `imgWidth: ${canvas.width} \n` +
            `imgHeight: ${canvas.height} \n`,
    );

    ctx.drawImage(
        node.image,
        canvasOffset.x,
        canvasOffset.y,
        state.catStack.slice.width,
        state.catStack.slice.height,
        0,
        0,
        canvas.width, // canvas size
        canvas.height,
    );

    const mesh = verify(plane.children[0] as Mesh, 'no plane mesh');
    const material = mesh.material as MeshBasicMaterial;
    if (material.map) {
        material.map.needsUpdate = true;
    } // else, nothing to do
}

function _configure(state: ScanState, node: CatStackImageNode, plane: Object3D): void {
    const { axis, catStack } = state;

    const canvasElement = _makeCanvasElement(catStack);
    node.canvas = canvasElement;

    const mesh = _makePlaneMesh(node.name, canvasElement, catStack);

    // TODO: Catstack Jamie to replace with proper pattern. It seems the boundary node vs mesh is being crossed here
    plane.matrixAutoUpdate = true;
    plane.add(mesh);
    plane.position.copy(axis.p0);
    plane.visible = false;
}

function _makePlaneMesh(
    name: CatStackName,
    canvas: HTMLCanvasElement,
    catStackItem: ApiCaseCatStack,
): Mesh {
    // TODO: Catstack Jamie to replace with proper pattern. It seems the boundary node vs mesh is being crossed here
    const planeMesh = new Mesh(
        new PlaneGeometry(catStackItem.world.width, catStackItem.world.height),
        _makePlaneMeshMaterial(canvas),
    );
    planeMesh.matrixAutoUpdate = true;
    planeMesh.name = `cat-stack-plane-${name}`;

    // Setup plane rotation
    switch (name) {
        case 'axial':
            planeMesh.rotation.set(MathUtils.degToRad(180), 0, 0);
            break;

        case 'coronal':
            planeMesh.rotation.set(MathUtils.degToRad(90), 0, 0);
            break;
        default:
            throw new Error('unexpected name');
    }

    return planeMesh;
}

function _moveToSlice(
    state: ScanState,
    node: CatStackImageNode,
    plane: Object3D,
    value: number,
): void {
    const totalSlices = state.catStack.count;
    const currentSliceIndex = totalSlices - 1 - value;

    log.debug(
        `Moving catstack plane to slice. \n` +
            `currentSliceIndex: ${currentSliceIndex}. \n` +
            `slider value: ${value}. \n` +
            `totalSlices: ${totalSlices}. \n`,
    );
    const { axis } = state;

    if (value >= 0 && value < totalSlices) {
        const sliceSpacing = axis.distance / (totalSlices - 1);
        _moveAlongVector(plane, axis.direction, -value * sliceSpacing, axis.p0.clone());
        _canvasRedraw(state, node, plane, currentSliceIndex);
    } else {
        log.warn(
            'Slice value %d not in range (0-%d inclusive) and/or not changed',
            value,
            totalSlices - 1,
        );
    }
}

function _moveAlongVector(
    obj: Object3D,
    direction: Vector3,
    distance: number,
    initialPosition?: Vector3,
): void {
    // TODO: Catstack Jamie to replace with existing array/vector utilities ?
    const startPosition = initialPosition !== undefined ? initialPosition : obj.position.clone();
    const targetPosition = _getPositionAlongDirection(startPosition, direction, distance);
    obj.position.copy(targetPosition.clone());
}

function _getPositionAlongDirection(
    initialPosition: Vector3,
    direction: Vector3,
    distance: number,
): Vector3 {
    const targetPosition = new Vector3();

    // TODO: Catstack Jamie to replace with existing array/vector utilities ?
    targetPosition.addVectors(initialPosition, direction.clone().multiplyScalar(distance));

    return targetPosition;
}
