import { useDebounceFn } from '@vueuse/core';
import { type RenderingContext } from './updateRendering'
import { type StopHandle, taggedLogger } from '@/util';

const log = taggedLogger('viewport');

/**
 * Observe the size of a 'container' HTML element, and update an HTML-canvas, a three-js renderer and
 * a three-js camera when the size of the element changes.
 */
export function updateViewport(context: RenderingContext): StopHandle {
    function updateSize(entry: ResizeObserverEntry) {
        const { contentRect } = entry;
        const [width, height] = [Math.floor(contentRect.width), Math.floor(contentRect.height)];
        if (width != context.canvas.width || height != context.canvas.height) {
            log.debug(
                'Viewport resized from %dx%d to %dx%d. Updating camera and renderer',
                context.canvas.width,
                context.canvas.height,
                width,
                height,
            );
        }

        context.renderer.setSize(width, height, false);
        context.camera.aspect = width / height;
        context.camera.updateProjectionMatrix();

        // TODO: enable when supporting orthographic camera
        // if (isPerspectiveCamera(context.camera)) {
        //     context.camera.aspect = width / height;
        //     context.camera.updateProjectionMatrix();
        // } else if (isOrthographicCamera(context.camera)) {
        //     context.camera.left = -width / 2;
        //     context.camera.right = width / 2;
        //     context.camera.top = -height / 2;
        //     context.camera.bottom = height / 2;
        // } else {
        //     throw Error(`Unsupported camera type: '${(context.camera as any).type}'`);
        // }
    }

    // Debounce update because we don't actully need to do it every time the observed
    const debounceUpdateSize = useDebounceFn((entries: ResizeObserverEntry[]) => {
        updateSize(entries[0]);
    }, 10);

    let doInitialResize = true;

    const resizeObserver = new ResizeObserver((entries: ResizeObserverEntry[]) => {
        if (doInitialResize) {
            updateSize(entries[0]);
            doInitialResize = false;
        } else {
            debounceUpdateSize(entries).catch((e) => log.error('Error resizing viewport: %s', e));
        }
    });

    resizeObserver.observe(context.container);

    return () => resizeObserver.disconnect();
}

// function isPerspectiveCamera(camera: Camera): camera is PerspectiveCamera {
//     return camera.type === 'PerspectiveCamera';
// }
//
// function isOrthographicCamera(camera: Camera): camera is OrthographicCamera {
//     return camera.type === 'OrthographicCamera';
// }
