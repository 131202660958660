import {
    objectNode,
    type ObjectNode,
    updateObject,
} from '@/planner/3d/object';
import { type AxesHelper } from 'three';
import { stopAll, type StopHandle } from '@/util';
import { transformNode, type TransformNode, updateTransform } from '@/planner/3d/transform';

export type AxesNode = ObjectNode<'axes'> & TransformNode;

export function axesNode(properties?: Partial<AxesNode>): AxesNode {
    return {
        ...objectNode('axes', properties),
        ...transformNode(properties),
    }
}

export function updateAxes(node: AxesNode, axes: AxesHelper): StopHandle {
    return stopAll(
        updateObject(node, axes),
        updateTransform(node, axes),
    )
}
