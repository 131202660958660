<script setup lang="ts">
    import { onMounted, onUnmounted, ref } from 'vue';
    import { stop, stopAll, type StopHandle } from '@/util';
    import { usePlannerStore } from '@/planner/plannerStore';
    import { plannerScene } from '@/planner/scene/plannerScene';
    import { updatePlannerScene } from '@/planner/scene/updateScene';
    import { updateCup } from '@/planner/updateCup';
    import { updateStem } from '@/planner/updateStem';
    import { updateRendering } from '@/planner/3d/updateRendering';

    const planner = usePlannerStore();

    // Reference to the container div
    const containerRef = ref<HTMLDivElement>();

    // Reference to the canvas
    const canvasRef = ref<HTMLCanvasElement>();

    let updates: StopHandle | null = null;

    onMounted(() => {
        const [canvas, container] = [canvasRef.value, containerRef.value];
        if (!canvas || !container) {
            throw Error('DOM elements are not defined');
        }

        const plannerScene_ = plannerScene();

        updates = stopAll(
            updateCup(planner),
            updateStem(planner),
            updatePlannerScene(planner, plannerScene_),
            updateRendering(container, canvas, plannerScene_.scene, planner.scene.camera),
        );
    });

    onUnmounted(() => {
        stop(updates);
    });
</script>

<template>
    <div class="viewport-container" ref="containerRef">
        <canvas class="viewport-canvas" ref="canvasRef" />
    </div>
</template>

<style scoped>
    .viewport-container {
        position: absolute;
        width: 100%;
        height: 100%;
    }

    .viewport-canvas {
        position: absolute;
    }
</style>
