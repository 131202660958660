
import { type AxiosResponse, HttpStatusCode } from 'axios';
import { client } from '@/api/http';
import { type ApiLinks } from '@/api/links';
import type { Url } from '@/formus/types';
import { errorDetail } from '@/planner/api/errorDetail';
import { type ConvertedRepresentation, convertRepresentation } from '@/planner/api/convertRepresentation';

/**
 * The state of a study resource
 *
 * - **new**: The resource is new
 * - **uploading:** The study is in a state that DICOM files (and messages) can be posted to the
 *    study, and they will be accepted.
 * - **processing:** The study is in an immutable state (from the API perspective), but is fully mutable from the
 *    server backend processing perspective.
 * - **catstack:** The study is in an immutable state (from the API perspective), and a valid cat stack has
 *    been produced (and is available to show the user).
 *    When in this state the 'segmentation' and other model creation steps will be performed.
 * - **segmentation-processing:**: The catstack has been produced and the GPU has started to perform
 * - **segmented:** The study has been segmented and the 3d models may be available.
 * - **post-processing:**: The study has been segmented and the (non-GPU) CPU based processing has commenced.
 * - **completed**
 * - **error**
 */
export type StudyState =
    | 'new'
    | 'uploading'
    | 'processing'
    | 'catstack'
    | 'seg-processing'
    | 'segmented'
    | 'post-processing'
    | 'completed'
    | 'error';

export type StudyStateUri = `https://schema.formuslabs.com/state/study/${StudyState}`;

/** An information message on a study **/
export type SeriesMessageRepresentation = {
    /** the level message as a simple string (e.g. 'info', 'warn', 'debug') */
    level: string;

    /** the text message with parameters replaced */
    message: string;
};

export type DicomFileMessages = {
    /** The original filename */
    name: string;

    /** an optional set of messages */
    messages?: SeriesMessageRepresentation[];
};

export type StudyProperties = {
    name: string;

    /**
     * When the study was created.
     *
     * This is an ISO-9660 datetime string with better than second precision.
     */
    created: string;

    /**
     * When the study was last modified.
     *
     * This is an ISO-9660 datetime string with better than second precision.
     */
    updated: string;

    /**
     * A URI representing the state of the study.
     */
    state: StudyStateUri;

    /** A unique DICOM identifier for the series */
    series: string;

    /** The expected number of files to be uploaded as part of the DICOM study */
    file_count: number;

    /**
     * An optional set of messages that were generated during the client parsing and organising
     * of the DICOM files.
     *
     * IMPORTANT: the messages related to the individual DICOM files are stored with the DICOM file resources.
     */
    messages: SeriesMessageRepresentation[] | null;

    /**
     * The messages for file that were excluded from upload because they were deemed to not be required
     * to perform the pre-surgical planning. It is expected that additional files will come from the PACS
     * system.
     */
    excluded_files: DicomFileMessages[];
};

const studyLinkMap = {
    self: 'self',
    case: 'up',
    templates: 'surgical-templates',
    measurements: 'measurements',
    allMeasurements: 'measurementss',
    landmarks: 'landmarks',
    fitting: 'fitting',
    files: 'files',
    catStacks: 'cat-stacks',
    models: { rel: 'models', title: null },
    hemipelvisModels: { rel: 'models', title: 'hemipelvis' },
    boneModels: { rel: 'models', title: 'bones' },
    metalModels: { rel: 'models', title: 'metal' },
    creatorUser: { rel: 'user', title: 'creator' },
    updaterUser: { rel: 'user', title: 'updater' },
} as const;

/** Identifies a study on the API */
export type ApiStudyId = { case: number; study: number };

/**
 * A study is a resource that is created in the context of a case. It is the primary resource
 * that represents a collection of DICOM files.
 */
export type ApiStudy = ConvertedRepresentation<StudyProperties, typeof studyLinkMap> & {
    id: ApiStudyId;
};

export async function getStudy(studyId: ApiStudyId | Url): Promise<ApiStudy> {
    let id, url;
    if (typeof studyId === 'object') {
        id = studyId;
        url = `project/${id.case}/study/${id.study}`;
    } else {
        url = studyId;
        id = studyIdFromUrl(url);
    }

    const { status, data }: AxiosResponse<StudyProperties & ApiLinks> = await client.get(url);
    if (status === HttpStatusCode.Ok) {
        return {
            id,
            ...convertRepresentation(data, studyLinkMap),
        };
    }

    throw Error(`Failed to load study from ${url}` + errorDetail(data));
}

export function studyIdFromUrl(url: Url): ApiStudyId {
    const match = url.match(/project\/([0-9]*)\/study\/([0-9]*)/);
    if (match) {
        return {
            case: Number(match[1]),
            study: Number(match[2]),
        };
    }
    throw Error(`Failed to extract study-id from url '${url}'`);
}
