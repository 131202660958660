import type { BodySide } from '@/formus/anatomy/side';
import { type LinkMap } from '@/api/convertLinks';
import { client } from '@/api/http';
import { type AxiosRequestConfig, type AxiosResponse, HttpStatusCode } from 'axios';
import { errorDetail } from '@/planner/api/errorDetail';
import { type ApiLinks } from '@/api/links';
import type { Url } from '@/formus/types';
import {
    type ConvertedRepresentation,
    convertRepresentation,
} from '@/planner/api/convertRepresentation';
import { type CaseStatus } from '@/api/caseSearch';

export type Procedure = 'hip';

type CaseProperties = {
    /** The name of the case, as assigned by whoever created it */
    name: string;

    /** A description of the case as given by whoever created it */
    description: string;

    /** The planned date for the surgery */
    surgery_date: string;

    status: CaseStatus;

    /** The planned procedure: currently only hip */
    procedure: Procedure;

    /** The side of the body for the planned procedure */
    side: BodySide;

    /** When the case (project) was created. This is an ISO-9660 datetime string. */
    created: string;

    /** When the case (project) was last modified. This is an ISO-9660 datetime string. */
    updated: string;

    /** The complete version stored server side */
    version: string;
};

const caseLinkMap: LinkMap = {
    self: 'self',
    product: 'product',
    patient: 'patient',
    spinopelvic: 'spinopelvic',
    messages: 'messages',
    surgeon: 'surgeon',
    owner: 'owner',
    creatorUser: { rel: 'user', title: 'creator' },
    createdUser: { rel: 'user', title: 'created' },
    study: { rel: 'study', optional: true },
    studies: 'studies',
    plans: 'plans',
    specification: 'surgical-specification',
    manualTemplate: { rel: 'surgical-template', title: 'manual', optional: true },
    automatedTemplate: { rel: 'surgical-template', title: 'automated', optional: true },
    templates: 'surgical-templates',
} as const;

export type ApiCase = ConvertedRepresentation<CaseProperties, typeof caseLinkMap> & {
    /** The case-id */
    id: number;
};

export async function getCase(caseId: number | Url, config?: AxiosRequestConfig): Promise<ApiCase> {
    let id, url;
    if (typeof caseId === 'number') {
        id = caseId;
        url = `project/${id}`;
    } else {
        url = caseId;
        id = caseIdFromUrl(url);
    }

    const { status, data }: AxiosResponse<CaseProperties & ApiLinks> = await client.get(
        url,
        config,
    );
    if (status === HttpStatusCode.Ok) {
        return {
            id,
            ...convertRepresentation(data, caseLinkMap),
        } as unknown as ApiCase;
    }

    throw Error('Failed to load case' + errorDetail(data));
}

export function caseIdFromUrl(url: Url): number {
    const match = url.match(/project\/([0-9]*)/);
    if (match) {
        return Number(match[1]);
    }
    throw Error(`Failed to extract case-id from url '${url}'`);
}
