import { AxesHelper, GridHelper, Scene } from 'three';
import { addObject } from '@/planner/3d/object3d';

export type DebugHelpers = {
    ctOrigin: AxesHelper;
    sceneCentre: AxesHelper;
    sceneGrid: GridHelper;
};

const AXES_HELPER_SIZE = 20;

export function debugHelpers(scene: Scene): DebugHelpers {
    return {
        ctOrigin: addObject(scene, new AxesHelper(AXES_HELPER_SIZE)),
        sceneCentre: addObject(scene, new AxesHelper(AXES_HELPER_SIZE)),
        sceneGrid: _addGridHelper(scene),
    };
}

/** Create a grid that will be aligned to the transverse plane */
function _addGridHelper(scene: Scene): GridHelper {
    return addObject(scene, new GridHelper(2000, 20, '#808080', '#4a4a4a'));
}
