<template>
    <v-card class="no-shadow pa-0 ma-0 border-none qr-code-container" variant="flat">
        <v-card-title class="text-subtitle-2 px-0 d-inline-flex">
            <span>Intellijoint Hip Plan QR Code:</span>
            <span v-if="isReady" class="ml-2 download-button planner-blue" @click="onPrint">
                Print
            </span>
            <!--
                This is the QR code that the user will print
                This will include data as the case name, surgeon, patient + the qr code
            -->
            <teleport to="#print-area" v-if="showPrint">
                <PrintableQRCode ref="print" :project="project" :plan="plan" />
            </teleport>
        </v-card-title>
        <v-card-text class="text-center qr-code px-0">
            <QRCode :size="260" :plan="plan" />
        </v-card-text>
    </v-card>
</template>

<script setup lang="ts">
    import { computed, ref } from 'vue';
    import QRCode from '@/components/case-plan/qr-code/QRCode.vue';
    import PrintableQRCode from '@/components/case-plan/qr-code/PrintableQRCode.vue';
    import type { ApiPlan, ApiPlansCase } from '@/api/plan/types';

    export interface Props {
        project: ApiPlansCase;
        plan: ApiPlan;
    }

    const props = defineProps<Props>();

    const showPrint = ref(false);

    async function onPrint(): Promise<void> {
        showPrint.value = true;

        setTimeout(() => {
            try {
                window.print();
            } finally {
                showPrint.value = false;
            }
        }, 500);
    }

    /**
     * The QR codes requires the report-measurements to be calculated.
     * It will be present before the plan is completed, but it takes around 1 minute to reach this state.
     */
    const isReady = computed((): boolean => {
        return props.plan.measurements !== null;
    });
</script>

<style scoped lang="scss">
    .qr-code-container {
        color: var(--planner-grey);
    }

    .download-button {
        color: var(--planner-blue);
        text-transform: none;

        &:hover {
            cursor: pointer;
        }
    }
</style>
