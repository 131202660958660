import type { CollisionCoverageResults } from '@/planner/cupCoverage/types';
import type { DebounceResult } from '@/planner/cupCoverage/AsyncDebounce';
import debounce, { ScheduledCancelledError } from '@/planner/cupCoverage/AsyncDebounce';
import { checkComponentCoverage } from '@/planner/cupCoverage/ComponentCoverageUtil';
import type { Mesh } from 'three';
import { taggedLogger } from '@/util';

const log = taggedLogger('cup-coverage-controller');

/**
 * Anclass that allows to perform component coverage calculations.
 * It is intended to be used for setting up and updating the cup collision coverage
 * by another covering mesh (eg. hemi-pelvis)
 *
 * 1. It uses the async-debounce approach.
 * 1. It does exception handling (eating cancelled coverage calculations, and bubbling up other exceptions).
 * 1. It provides a clear way to be extended, allowing the subclasses to not worry about the implementation details.
 *
 *
 * Note:
 * 1. The component coverage should be updated whenever the component is adjusted/changed.
 * 2. If the covering mesh (hemi-pelvis) is for some reason moved/changed,
 * the coverage won't be recalculated.
 */
export class CupCoverageController {
    /**
     * Function that updates the component collision coverage only once every
     * certain time (currently is 1500 milliseconds). The async-debounce is used, given
     * coverage calculation is computationally heavy and doesn't happen instantaneously.
     */
    protected _debouncedUpdateCoverage: DebounceResult<CollisionCoverageResults, [Mesh, Mesh]>;

    public constructor(aborter: AbortController) {
        this._debouncedUpdateCoverage = debounce(this.performUpdateCoverage.bind(this), 1500, {
            cancelError: new ScheduledCancelledError('coverage cancelled'),
            aborter: aborter,
        });
    }

    /**
     * This is the function that performs the coverage update within the context of `debounce`.
     *
     * @returns a {class @CollisionCoverageResults} when successfully calculated
     * @throws Throw any exception during the calculation process.
     */
    private async performUpdateCoverage(
        abortController: AbortController,
        cupCollision: Mesh,
        /**
         * The covering mesh
         * e.g.:
         *   a. operative hemi pelvis if cup.
         *   b. scapula if baseplate.
         *
         * Note: The covering mesh is exposed as a constructor parameter given it is assumed
         * to not be moved during the lifecycle of this controller. If the covering mesh is moved,
         * the coverage won't be updated.
         */
        operativeHemi: Mesh,
    ): Promise<CollisionCoverageResults> {
        return await checkComponentCoverage(abortController.signal, cupCollision, operativeHemi);
    }

    /**
     * The main internal method used for calculation the collision coverage.
     *
     * @returns a {class @CollisionCoverageInfo} when successfully calculated. Otherwise null as soon as the
     *          collision coverage calculation was cancelled/interrupted.
     * @throws any exception thrown during the calculation process **that is not a cancellation event**.
     *
     * @see {@error CancelledError} and {@method isCancelledByCallerError}
     */
    public async updateCoverage(
        cupCollision: Mesh,
        operativeHemi: Mesh,
    ): Promise<CollisionCoverageResults | null> {
        log.debug('Coverage calculation scheduled');
        return await this._debouncedUpdateCoverage.function(cupCollision, operativeHemi);
    }
}
