<template>
    <div class="expansion-panel">

        <div class="input-group" v-if="store.dualMobilityVisible(planner.caseVersion)">
            <label>Dual Mobility </label>
            <input id="dual-mobility" v-model="store.dualMobility" type="checkbox"
            :disabled="store.selectedCupSize ? store.selectedCupSize <= DUAL_MOBILITY_CUP_SIZE_THRESHOLD : false">
        </div>

        <div class="input-group">
            <div style="display: flex; justify-content: space-between; align-items: center;">
                <label>Size</label>
                <span>{{ store.selectedCupSize }}</span>
            </div>
            <tick-slider v-model="store.selectedCupIndex" :tick-count="store.availableCups.length" />
        </div>

        <div class="input-group">
            <label>Anteversion (degrees) </label>
            <planner-slider v-model="store.anteversion" :min=-5 :max=45 />
        </div>

        <div class="input-group">
            <label>Inclination (degrees) </label>
            <planner-slider v-model="store.inclination" :min=0 :max=60 />
        </div>

        <v-expansion-panels>
            <v-expansion-panel class="expansion-panel">
                <v-expansion-panel-title>
                    <div>Position</div>
                </v-expansion-panel-title>
                <v-expansion-panel-text>
                    <div class="input-group">
                        <label>Depth (mm)</label>
                        <planner-slider v-model="store.depthOffset" :min=-20 :max=20 :step="positionSliderStep" />
                    </div>

                    <div class="input-group">
                        <label>Anterior - Posterior (mm) </label>
                        <planner-slider v-model="store.apOffset" :min=-20 :max=20 :step="positionSliderStep" />
                    </div>

                    <div class="input-group">
                        <label>Superior - Inferior (mm) </label>
                        <planner-slider v-model="store.siOffset" :min=-20 :max=20 :step="positionSliderStep"/>
                    </div>
                </v-expansion-panel-text>
            </v-expansion-panel>
        </v-expansion-panels>

        <div class="actions">
            <app-button colour="blue-button" @click="planner.resetCup">Reset</app-button>
        </div>

        <SpinopelvicPanel v-if="hipSpine.isEnabled" />
    </div>
</template>

<!--suppress CssUnusedSymbol -->
<style scoped>
    .expansion-panel :deep(.v-expansion-panel-title),
    .expansion-panel :deep(.v-expansion-panel-text__wrapper) {
        padding: 0;
    }

    .input-group {
        margin-bottom: 15px;
    }

    label, span {
        font-size: small;
    }

    .actions {
        display: flex;
        justify-content: end;
        margin-top: 20px;
    }
</style>

<script setup lang="ts">
    import TickSlider from '@/planner/components/TickSlider.vue';
    import PlannerSlider from '@/planner/components/PlannerSlider.vue';
    import { useCupOverlayStore, DUAL_MOBILITY_CUP_SIZE_THRESHOLD } from '@/planner/cupOverlayStore';
    import { usePlannerStore } from '@/planner/plannerStore';
    import SpinopelvicPanel from '@/planner/components/spinopelvic/SpinopelvicPanel.vue';
    import { useSpinopelvic } from '@/stores/spinopelvic/store';

    const planner = usePlannerStore();
    const store = useCupOverlayStore();
    const hipSpine = useSpinopelvic();

    const positionSliderStep = 0.5 as const;
</script>
