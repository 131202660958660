<template>
    <div>
        <optimisation-targets />
        <app-divider />
        <stem-selection />
        <app-divider />
        <stem-transform />
        <div class="actions">
            <app-button colour="blue-button" @click="planner.resetStem()">Reset</app-button>
        </div>
    </div>
</template>

<script setup lang="ts">
    import StemSelection from '@/planner/components/StemSelection.vue';
    import StemTransform from '@/planner/components/StemTransform.vue';
    import OptimisationTargets from '@/planner/components/OptimisationTargets.vue';
    import { usePlannerStore } from '@/planner/plannerStore';

    const planner = usePlannerStore();
</script>

<style scoped>
    .actions {
        display: flex;
        width: 100%;
        justify-content: end;
        margin-top: 20px;
    }
</style>
