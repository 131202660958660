import { watch, watchEffect } from 'vue';
import { watchImmediate } from '@vueuse/core';
import { stopAll, type StopHandle } from '@/util';
import { positionalPart } from '@/geometry/matrix';
import { type FittedStemAxis } from '@/planner/fittedStem';
import type { AxisNode } from '@/planner/3d/axis';
import type { PlannerStore } from '@/planner/plannerStore';
import type { AxisMeasurement } from '@/formus/anatomy/measurements';
import { vector3 } from '@/geometry/vector3';
import type { NumberArray3 } from '@/geometry/apiVector';
import { useDeveloperSettings } from '@/planner/developerSettings';
import { logValidation } from '@/planner/logValidation';
import { formatMatrixBasis, formatMatrixEuler } from '@/geometry/formatMath';
import { computeBearingUrl, computeHeadUrl } from '@/planner/componentUrls';

export function updateStem(store: PlannerStore): StopHandle {
    return stopAll(
        watchImmediate(
            () => store.template?.stemUrl ?? null,
            (url) => (store.scene.stem.geometrySource = url),
        ),
        watchImmediate(
            () => store.template && store.catalog ? computeHeadUrl(store) : null,
            (url) => (store.scene.head.geometrySource = url),
        ),
        watchImmediate(
            () => store.template && store.catalog ? computeBearingUrl(store) : null,
            (url) => (store.scene.bearing.geometrySource = url),
        ),
        watchImmediate(
            () => store.fittedStem,
            (fittedStem) => {
                if (fittedStem) {
                    store.scene.stem.transform.copy(fittedStem.stemLocal);
                    store.scene.head.transform.copy(fittedStem.headLocal);
                    if (fittedStem.bearingLocal) {
                        store.scene.bearing.transform.copy(fittedStem.bearingLocal);
                    }
                }
            },
        ),
        watchEffect(() => updateFemoralObjectTransforms(store)),
        watchEffect(() => updateGroupTransforms(store)),
        watchEffect(() => updateStemAxes(store)),
        watchEffect(() => updateFemoralAxes(store)),
        _logStemChanges(store),
    );
}

/**
 * Update the transform of the operative-femur and inner-cortical-surface relative to the femoral-group
 */
function _logStemChanges(store: PlannerStore): StopHandle {
    return stopAll(
        watch(
            () => (store.isLoading || !store.template ? null : store.template.stemTransform),
            (localTransform) => {
                if (localTransform != null) {
                    logValidation(
                        'Stem manual-transform:\n' +
                        formatMatrixEuler(localTransform, { indent: 2 }),
                    );
                }
            },
        ),
        watch(
            () =>
                store.isLoading
                    ? null
                    : {
                          stemGroup: store.scene.stemGroup.transform,
                          stem: store.scene.stem.transform,
                          head: store.scene.head.transform,
                      },
            (transforms) => {
                if (transforms) {
                    const { stemGroup, stem, head } = transforms;
                    const headWorld = stemGroup.clone().multiply(head);
                    const stemWorld = stemGroup.clone().multiply(stem);
                    logValidation(
                        [
                            `Stem-group transforms (${store.plannerMode !== 'stem' ? 'retracted' : 'native'}}):`,
                            '  stem-group:',
                            formatMatrixBasis(stemGroup, { indent: 4 }),
                            '  head (world):',
                            formatMatrixBasis(headWorld, { indent: 4 }),
                            '  stem (world):',
                            formatMatrixBasis(stemWorld, { indent: 4 }),
                        ].join('\n'),
                    );
                }
            },
            { deep: true },
        ),
    );
}

/**
 * Update the transform of the operative-femur and inner-cortical-surface relative to the femoral-group
 */
function updateFemoralObjectTransforms(store: PlannerStore): void {
    if (store.fittedStem) {
        // The femoral-group will be at the fitted-head-transform when the
        // femur is in its native (pre-operative) position, so the relative placement
        // of the femoral objects is the inverse of this transform.
        const femurInFemoralGroup = store.fittedStem.transform.clone().invert();
        store.scene.operativeFemur.transform.copy(femurInFemoralGroup);
        store.scene.operativeFemurInternal.transform.copy(femurInFemoralGroup);
    } else {
        // If the fitted-head-transform is not available the femoral-group will
        // have an identity transform, so the femoral objects have the same.
        store.scene.operativeFemur.transform.identity();
        store.scene.operativeFemurInternal.transform.identity();
    }
}

function updateGroupTransforms(store: PlannerStore): void {
    if (store.template && store.fittedStem) {
        const stemGroupTransform = store.fittedStem.transform
            .clone()
            .multiply(store.template.stemTransform);
        store.scene.stemGroup.transform.copy(stemGroupTransform);
        store.scene.femoralGroup.transform.copy(store.fittedStem.transform);

        if (store.plannerMode !== 'stem') {
            // If we are not in stem-mode we want to translate the stem-group so that its
            // position matches the cup-group (the head is 'inside' the cup)
            const hjc = positionalPart(store.scene.cupGroup.transform);
            store.scene.stemGroup.transform.setPosition(hjc);

            // Translate the femoral-group by the same amount, preserving the transform between
            // the femur and the stem (the stem-transform).
            const shiftedFemoralGroupPosition = positionalPart(store.fittedStem.transform)
                .add(hjc)
                .sub(positionalPart(stemGroupTransform));
            store.scene.femoralGroup.transform.setPosition(shiftedFemoralGroupPosition);
        }

        store.scene.stemGroup.showAxes = useDeveloperSettings().show3dFeatures || false;
    } else {
        store.scene.stemGroup.showAxes = false;
    }
}

function updateStemAxis(state: FittedStemAxis | undefined, node: AxisNode) {
    if (state) {
        node.visible = useDeveloperSettings().show3dFeatures || false;
        node.position.copy(state.position);
        node.direction.copy(state.direction);
    } else {
        node.visible = false;
    }
}

function updateStemAxes(store: PlannerStore) {
    updateStemAxis(store.fittedStem?.axes?.neckAxis, store.scene.stemNeckAxis);
    updateStemAxis(store.fittedStem?.axes?.shaftAxis, store.scene.stemShaftAxis);
    updateStemAxis(store.fittedStem?.axes?.paAxis, store.scene.stemPaAxis);
}

function updateFemoralAxes({ femoralFeatures, fittedStem, scene }: PlannerStore) {
    if (!femoralFeatures || !fittedStem) {
        scene.femoralShaftAxis.visible = false;
        scene.femoralProximalShaftAxis.visible = false;
        scene.femoralNeckAxis.visible = false;
        scene.femoralAnteversionNeckAxis.visible = false;
        scene.femoralAnteversionCondylarAxis.visible = false;
        return;
    }

    const femurInFemoralGroup = fittedStem.transform.clone().invert();
    const { shaftAxis, proximalShaftAxis, neckAxis, anteversionNeck, anteversionCondylar } =
        femoralFeatures;

    const updateAxis = (node: AxisNode, direction: NumberArray3, position: NumberArray3) => {
        node.direction = vector3(direction).transformDirection(femurInFemoralGroup);
        node.position = vector3(position).applyMatrix4(femurInFemoralGroup);
        node.visible = useDeveloperSettings().show3dFeatures || false;
    };

    const updateAxisFromMeasurement = (node: AxisNode, measurement: AxisMeasurement) =>
        updateAxis(node, measurement.value, measurement.position);

    updateAxisFromMeasurement(scene.femoralShaftAxis, shaftAxis);
    updateAxisFromMeasurement(scene.femoralProximalShaftAxis, proximalShaftAxis);
    updateAxisFromMeasurement(scene.femoralNeckAxis, neckAxis);

    updateAxis(scene.femoralAnteversionCondylarAxis, anteversionCondylar, neckAxis.position);
    updateAxis(scene.femoralAnteversionNeckAxis, anteversionNeck, neckAxis.position);
}
