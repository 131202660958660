/**
 * Implements software requirements: H1SR-11, H1SR-86
 *
 * @link https://formuslabs.youtrack.cloud/issue/H1SR-11/Default-values-for-cases-can-be-defined-in-the-hip-preferences
 * @link https://formuslabs.youtrack.cloud/issue/H1SR-86/Surgeon-users-are-able-to-define-from-a-selection-of-stem-types-which-stems-to-be-used-in-the-fitting-process
 */

import { type AxiosResponse, HttpStatusCode } from 'axios';
import { client } from '@/api/http';
import { HipRoleNames } from '@/stores/user/types';

export interface ApiUserObject {
    id: number;
    roles: { name: HipRoleNames }[];
}

export async function getCurrentUser(): Promise<ApiUserObject> {
    return client.get(`/users/me`).then(({ status, data }: AxiosResponse<ApiUserObject>) => {
        if (status === HttpStatusCode.Ok) {
            return data;
        }
        throw Error(`Failed to load current user`);
    });
}
